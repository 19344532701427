import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import StaffWrapper from '../pages/Staff/StaffWrapper'
import EventsWrapper from '../pages/Events/EventsWrapper'
import ReportWrapper from '../pages/Report/ReportWrapper'
import SettingsWrapper from '../pages/Settings/SettingsWrapper'
import NotificationWrapper from '../pages/Notification/NotificationWrapper'
import EventView from '../pages/Events/EventView'
import EventReports from '../pages/Report/Events/EventReports'
import AddEvent from '../pages/Events/AddEvent'
import AddStaff from '../pages/Staff/AddStaff'
import EditStaff from '../pages/Staff/EditStaff'
import EditEvent from '../pages/Events/EditEvent'
import SendNewNotification from '../pages/Notification/SendNewNotification'
import ManageCategory from '../pages/Settings/Manage-Category'
import DiscountCode from '../pages/Settings/Discount-Code'
import AboutUs from '../pages/Settings/About-Us'
import PrivacyPolicy from '../pages/Settings/Privacy-Policy'
import TermsConditions from '../pages/Settings/Terms-&-Conditions'
import FAQs from '../pages/Settings/FAQs'
import EditAboutus from '../pages/Settings/Edit/EditAboutus'
import EditPrivacyPolicy from '../pages/Settings/Edit/EditPrivacyPolicy'
import EditTermsNCondition from '../pages/Settings/Edit/EditTermsNConditions'
import AddCode from '../pages/Settings/Discount/Add-Code'
import EditCode from '../pages/Settings/Discount/Edit-Code'
import CodeDetails from '../pages/Settings/Discount/Code-Details'
import UsersWrapper from '../pages/Users/UsersWrapper'
import UserDetailView from '../pages/Users/Details/UserDetails'
import Support from '../pages/Settings/Support'
import AddFAQs from '../pages/Settings/FAQs/Add-FAQs'
import EditFAQs from '../pages/Settings/FAQs/Edit-FAQs'
import PaymentHistoryWrapper from '../pages/payment-history/PaymentHistoryWrapper'
import ManageTicketCategory from '../pages/Settings/Manage-Ticket-Category'
import UserRequestDetails from '../pages/User-request/UserRequestDetails'
import RequestDetails from '../pages/User-request/RequestDetails'
import SupportDetails from '../pages/Settings/SupportDetails'
import CompletedSupport from '../pages/Settings/Completed-Support'
import CompleteSupportDetails from '../pages/Settings/Complete-Support-Details'
import RegistrationRequest from '../pages/registration-request/RegistrationRequest'
import RegistrationRequestDetails from '../pages/registration-request/RegistrationRequestDetails'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='events' element={<EventsWrapper />} />
        <Route
          path='events/event-view/*'
          element={
            <SuspensedView>
              <EventView />
            </SuspensedView>
          }
        />
        <Route path='events/add-event' element={<AddEvent />} />
        <Route path='events/edit-event' element={<EditEvent />} />
        <Route path='staff' element={<StaffWrapper />} />
        <Route path='staff/add-staff' element={<AddStaff />} />
        <Route path='staff/staff-edit' element={<EditStaff />} />
        <Route path='users' element={<UsersWrapper />} />
        <Route path='user-request' element={<UserRequestDetails />} />
        <Route path='registration-request' element={<RegistrationRequest/>}/>

        <Route
          path='/user-request/request-details/*'
          element={
            <SuspensedView>
              <RequestDetails />
            </SuspensedView>
          }
        />
               <Route
          path='/registration-request/details/*'
          element={
            <SuspensedView>
              <RegistrationRequestDetails/>
            </SuspensedView>
          }
        />

        <Route
          path='users/user-details/*'
          element={
            <SuspensedView>
              <UserDetailView />
            </SuspensedView>
          }
        />
        <Route
          path='/user-request/request-details/user-details/*'
          element={
            <SuspensedView>
              <UserDetailView />
            </SuspensedView>
          }
        />
        <Route
          path='report/*'
          element={
            <SuspensedView>
              <ReportWrapper />
            </SuspensedView>
          }
        />
        <Route path='report/event/event-reports' element={<EventReports />} />
        <Route path='settings' element={<SettingsWrapper />} />
        <Route path='settings/manage-category' element={<ManageCategory />} />
        <Route path='settings/manage-ticket-category' element={<ManageTicketCategory />} />
        <Route path='settings/discount-code/*' element={<DiscountCode />} />
        <Route path='settings/discount-code/addcode' element={<AddCode />} />
        <Route path='settings/discount-code/editcode' element={<EditCode />} />
        <Route path='settings/discount-code/codeDetails' element={<CodeDetails />} />
        <Route path='settings/aboutUs' element={<AboutUs />} />
        <Route path='settings/support' element={<Support />} />
        <Route path='settings/support/details' element={<SupportDetails />} />
        <Route path='settings/support/completed-logs/details' element={<CompleteSupportDetails />} />
        <Route path='settings/support/completed-logs' element={<CompletedSupport />} />
        <Route path='settings/aboutUs/edit' element={<EditAboutus />} />
        <Route path='settings/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='settings/privacy-policy/edit' element={<EditPrivacyPolicy />} />
        <Route path='settings/terms-and-conditions' element={<TermsConditions />} />
        <Route path='settings/terms-and-conditions/edit' element={<EditTermsNCondition />} />
        <Route path='settings/fAQs' element={<FAQs />} />
        <Route path='settings/fAQs/add' element={<AddFAQs />} />
        <Route path='settings/fAQs/edit' element={<EditFAQs />} />
        <Route path='notification/*' element={<NotificationWrapper />} />
        <Route path='notification/send-new-notification' element={<SendNewNotification />} />
        <Route path='payment-history' element={<PaymentHistoryWrapper />} />

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
