import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { Formik, FormikErrors, useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { ImageHandler } from '../../../image-handler'
import { getAuthorizationToken } from '../../../authorization-token'
import Select from 'react-select';

const eventSchema = Yup.object().shape({
  // event_datetime: Yup.array().of(
  //     Yup.string()
  //         .required('Date Time is required')
  //         .test('is-valid-datetime', 'Date Time is required', (value) => !!value)
  // ),
  event_datetime: Yup.array()
    .of(
      Yup.object().shape({
        event_start_datetime: Yup.string().required('Event Start Date time  is required'),
        event_end_datetime: Yup.string().required('Event End Date time  is required'),
      })
    )
    .min(1, 'At least one Event Date Time is required')
    .test('is-valid-datetime-array', 'At least one Event Date Time is required', (value) => {
      // Custom validation function to check if the array has at least one item
      return Array.isArray(value) && value.some((datetime) => !!datetime.event_start_datetime && !!datetime.event_end_datetime);
    }),

  // event_end_datetime: Yup.array()
  //     .of(
  //         Yup.string()
  //             .required('Event end Time is required')
  //             .test('is-valid-datetime', 'Event end Time is required', (value) => !!value)
  //     )
  //     .min(1, 'At least one Event end Time is required')
  //     .test('is-valid-datetime-array', 'At least one Event end Time is required', (value) => {
  //         // Custom validation function to check if the array has at least one item
  //         return Array.isArray(value) && value.some((datetime) => !!datetime);
  //     }),
  name: Yup.string().required('Name is required'),
  // subtitle: Yup.string().required('SubTitle  is required'),
  location: Yup.string().required('Location is required'),
  approvalRequired: Yup.string().required('Select Yes or NO'),
  gender: Yup.string().required('Select Gender'),
  event_category: Yup.string().required('Select Category'),
  ticketType: Yup.string().required('Select Ticket Type'),
  complimentaryDrink: Yup.string().required('Select Yes or NO'),
  description: Yup.string().required('Description is required'),
  general_info: Yup.string().required('General Information  is required'),
  city: Yup.string().required('Select City'),
  no_of_tables: Yup.number()
    .required('No of Tables is required'),
    max_ticket: Yup.number()
    .required('No of Max ticket is required'),
    
  // .test('Is positive?', 'The number must be greater than 0!', (value) => value >= 0),
  rulesAndRegulations: Yup.string().required('Please enter Rules And Regulations'),
  isFeatured: Yup.number()
    .oneOf([1, 0], 'Please select whether it is featured or not')
    .required('Please select whether it is featured or not'),
   site_map_enable: Yup.number()
    .oneOf([1, 0], 'Please select whether it is enalbe or not')
    .required('Please select whether its enable or not'),
  assign_staff: Yup.array()
    .min(1, 'At least Assign One Staff')
    .test('is-required', 'At least Assign One Staff', (value) => {
      return Array.isArray(value) && value.length > 0;
    }),
  gallery: Yup.array()
    .min(1, 'At least one gallery image is required')
    .test('is-required', 'At least one gallery image is required', (value) => {
      return Array.isArray(value) && value.length > 0;
    }),
    sitemap: Yup.string().test(
      'sitemap-test',
      'Sitemap image is required',
      function (value) {
        const { site_map_enable } = this.parent; 
        if (site_map_enable === 1) {
          return value !== undefined && value !== null && value.trim() !== ''; 
        }
        return true; 
      }
    ),
      pastEventGallery: Yup.array()
    .min(1, 'At least one past event image is required')
    .test('is-required', 'At least one past event image is required', (value) => {
      return Array.isArray(value) && value.length > 0;
    }),
  ticketDetails: Yup.array()
    .min(1, 'Ticket Details are required')
    // .test('is-required', 'Ticket Details are required', (value) => {
    //     return Array.isArray(value) && value.length > 0;
    // })
    .of(
      Yup.object().shape({
        ticketCategory: Yup.string().required('ticketCategory is required'),
        noOfTickets: Yup.string().required('No of Tickets is required'),
        price: Yup.number().moreThan(0, 'Price should be more than 0'),
        entry: Yup.string().required('Entry is required'),
      })
    ),
})

interface TicketCategory {
  ticketCategory: string;
  noOfTickets: string;
  price: string;
  entry: string;
}

interface EventDatetime {
  event_start_datetime: string;
  event_end_datetime: string;
}

interface EventValues {
  name: string
  event_datetime: EventDatetime[]
  gender: string
  event_category: string
  location: string
  ticketType: string
  approvalRequired: string
  complimentaryDrink: string
  description: string
  general_info: string
  rulesAndRegulations: string
  assign_staff: []
  gallery: []
  sitemap: any
  pastEventGallery: []
  ticketDetails: TicketCategory[]
  city: string
  isFeatured: number
  no_of_tables: number,
  max_ticket:number,
  site_map_enable:number
}

const AddEvent: FC = () => {

  interface Category {
    id: number;
    event_category: string;
    status: string;
  }
  const [eventCategory, setEventCategory] = useState<Category[]>([])

  interface Staff {
    id: number;
    name: string;
  }
  const [staffData, setStaffData] = useState([])

  interface Ticketcategory {
    id: number
    name: string
  }
  const [categoryData, setCategoryData] = useState<Ticketcategory[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get ALL Event-Category List
  const fetchEventCategory = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`,
      {
        headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
      }).then((result) => {
        result.json().then((resp) => {
          setEventCategory(resp);
        })
      })
      .catch((error) => {
        console.error('Error fetching event data:', error);
      });
  }

  //Get All Staff List 
  const fetchStaffData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allStaff`,
      {
        headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
      }).then((result) => {
        result.json().then((resp) => {
          setStaffData(resp.map((staff: Staff) => ({ label: staff.name, value: staff.id.toString() })));
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error);
      });
  }

  // Get all Event-Category List
  const fetchTicketCategoryData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allTicketCategory`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setCategoryData(resp.data)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // For Get ALL Event-Category List When page is load
  useEffect(() => {
    fetchEventCategory();
    fetchStaffData()
    fetchTicketCategoryData()
  }, [])

  const [datetimeInputs, setDatetimeInputs] = useState(['']); // Initial input

  const handleAddDatetime = () => {
    setDatetimeInputs([...datetimeInputs, '']);
  };

  const handleRemoveDatetime = (index) => {
    const updatedInputs = [...datetimeInputs];
    updatedInputs.splice(index, 1);
    setDatetimeInputs(updatedInputs);
    // Update the formik values by removing the corresponding value
    const updatedEventDatetime = [...formik.values.event_datetime];
    updatedEventDatetime.splice(index, 1);
    formik.setFieldValue('event_datetime', updatedEventDatetime);
    // Update the local storage
    const updatedFormValues = {
      ...formik.values,
      event_datetime: updatedEventDatetime,
    };
    localStorage.setItem('eventValues', JSON.stringify(updatedFormValues));
  };

  const [ticketDetails, setTicketDetails] = useState<any[]>([
    {
      ticketCategory: '',
      noOfTickets: '',
      price: '',
      entry: '',
    },
  ]);

  const handleAddCategory = () => {
    if (ticketDetails.length !== categoryData.length) {
      setTicketDetails((prevCategories) => [
        ...prevCategories,
        {
          ticketCategory: '',
          noOfTickets: '',
          price: '',
          entry: '',
        },
      ]);
    }
  };

  // const handleCategoryChange = (index: number, field: string, value: string) => {
  //     setTicketDetails((prevCategories) => {
  //         const newCategories = [...prevCategories];
  //         newCategories[index][field] = value;
  //         return newCategories;
  //     });
  // };

  const handleRemoveCategory = (index: number) => {
    setTicketDetails((prevCategories) => {
      const newCategories = [...prevCategories];
      newCategories.splice(index, 1);
      return newCategories;
    });
    formik.values.ticketDetails.splice(index, 1);
    formik.setFieldValue('ticketDetails', formik.values.ticketDetails);

    // Serialize the updated ticketDetails and store it in local storage
    const updatedEventValues = {
      ...eventValues,
      ticketDetails: formik.values.ticketDetails,
    };
    localStorage.setItem('eventValues', JSON.stringify(updatedEventValues));
  };

  const [selectedGalleryImages, setSelectedGalleryImages] = useState<{ data: string, isVideo: boolean }[]>([]);
  const GalleryInputRef = React.useRef<HTMLInputElement>(null);

  // const handleGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];

  //   if (file) {
  //     formik.setFieldValue('gallery', [...formik.values.gallery, file]);
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setSelectedGalleryImages((prevImages) => [...prevImages, reader.result as string]);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(extension => file.type === extension);
      formik.setFieldValue('gallery', [...formik.values.gallery, file]);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedGalleryImages((prevImages) => [...prevImages, { data: reader.result as string, isVideo }])
      };
      reader.readAsDataURL(file);
    }
  };

  const removeGalleryImage = (index: number) => {
    setSelectedGalleryImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      formik.setFieldValue('gallery', newImages);

      // Clear the file input field's value
      if (GalleryInputRef.current) {
        GalleryInputRef.current.value = '';
      }

      return newImages;
    });
  };

  const [selectedSitemap, setSelectedSitemap] = useState<{ data: string, isVideo: boolean }[]>([]);
  const SitemapInputRef = React.useRef<HTMLInputElement>(null);


  // const handleSitemap = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files![0];

  //   if (file) {
  //     formik.setFieldValue('sitemap', file); // Update formik values
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const dataURL = reader.result as string;
  //       setSelectedSitemap(prevSitemaps => [...prevSitemaps, dataURL]);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleSitemap = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(extension => file.type === extension);
      formik.setFieldValue('sitemap', file); // Update formik values
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        setSelectedSitemap((prevImages) => [...prevImages, { data: reader.result as string, isVideo }])
      };
      reader.readAsDataURL(file);
    }
  };

  const removeSitemap = (index: number) => {
    setSelectedSitemap((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      formik.setFieldValue('sitemap', ''); // Update formik values

      // Clear the file input field's value
      if (SitemapInputRef.current) {
        SitemapInputRef.current.value = '';
      }

      return newImages;
    });
  };

  const [selectedPastEventGallery, setSelectedPastEventGallery] = useState<{ data: string, isVideo: boolean }[]>([]);
  const PastEventGalleryInputRef = React.useRef<HTMLInputElement>(null);


  // const handlePastEventGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];

  //   if (file) {
  //     formik.setFieldValue('pastEventGallery', [...formik.values.pastEventGallery, file]);
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setSelectedPastEventGallery((prevImages) => [...prevImages, reader.result as string]);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handlePastEventGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(extension => file.type === extension);
      formik.setFieldValue('pastEventGallery', [...formik.values.pastEventGallery, file]);
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedPastEventGallery((prevImages) => [...prevImages, { data: reader.result as string, isVideo }])
      }
      reader.readAsDataURL(file)
    }
  }

  const removePastEventGallery = (index: number) => {
    setSelectedPastEventGallery((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      formik.setFieldValue('pastEventGallery', newImages);

      // Clear the file input field's value
      if (PastEventGalleryInputRef.current) {
        PastEventGalleryInputRef.current.value = '';
      }

      return newImages;
    });
  };

  const [eventValues, setEventValues] = useState<EventValues>({
    name: '',
    event_datetime: [] as EventDatetime[],
    gender: '',
    event_category: '',
    location: '',
    ticketType: '',
    approvalRequired: '',
    complimentaryDrink: '',
    description: '',
    general_info: '',
    rulesAndRegulations: '',
    assign_staff: [],
    gallery: [],
    sitemap: '',
    pastEventGallery: [],
    ticketDetails: [] as TicketCategory[],
    city: '',
    isFeatured: 0,
    no_of_tables: 0,
    max_ticket:0,
    site_map_enable:0,
  })
  useEffect(() => {
    const storedFormValues = localStorage.getItem('eventValues');
    if (storedFormValues) {
      setEventValues(JSON.parse(storedFormValues));
    }
  }, []);

  const [error, setError] = useState(false)

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  // For warning message about video upload time
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [dateErrorIndex, setDateErrorIndex] = useState(null as any)
  const [maxTicketDisabled, setMaxTicketDisabled] = useState(false);


  const formik = useFormik({
    initialValues: (eventValues as any) as EventValues || {
      name: (eventValues as any).name || '',
      // subtitle: '',
      event_datetime: [] as EventDatetime[],
      // event_end_datetime: [],
      gender: '',
      event_category: '',
      location: '',
      ticketType: '',
      approvalRequired: '',
      complimentaryDrink: '',
      description: '',
      general_info: '',
      rulesAndRegulations: '',
      assign_staff: [],
      gallery: [],
      sitemap: '' as any,
      pastEventGallery: [],
      ticketDetails: [] as TicketCategory[],
      city: '',
      isFeatured: 0,
      no_of_tables: 0,
      max_ticket:0,
      site_map_enable:0,
    },
    validationSchema: eventSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true)
      // values.gallery = selectedGalleryImages;
      // values.pastEventGallery = selectedPastEventGallery;
      // values.gallery = ['testingGallery1.png', 'testingGallery2.png'];
      // values.pastEventGallery = ['testingPast1.png', 'testingPast2.png'];
      // values.sitemap = 'testingsitmap.png'

      const utcDatetimeArray: { event_start_datetime: string; event_end_datetime: string }[] = []

      for (const [index, date] of values.event_datetime.entries()) {
        const startDatetime = new Date(date.event_start_datetime) // Assuming 'date' is in local time
        const endDatetime = new Date(date.event_end_datetime) // Assuming 'date' is in local time

        if (endDatetime <= startDatetime) {
          setLoading(false)
          setDateErrorIndex(index)
          document.getElementById('startingPoint')?.scrollIntoView()
          return
        } else {
          setDateErrorIndex(null)
        }

        // Create new objects with ISO formatted strings
        const isoDate = {
          event_start_datetime: startDatetime.toISOString(),
          event_end_datetime: endDatetime.toISOString(),
        }

        utcDatetimeArray.push(isoDate)
      }

      const s3Image = new ImageHandler()
      const gallery: string[] = []

      const isAnyFileAVideo = [
        ...values.gallery,
        values.sitemap,
        ...values.pastEventGallery,
      ].some(file => file instanceof File && ['.mp4', '.webm', '.ogg'].some(extension => file.name.endsWith(extension)));

      if (isAnyFileAVideo) {
        setIsVideoUploading(true);
        // Show a warning message about video upload time
      }

      for (const image of values.gallery) {
        const gallery_image = await s3Image.upload(image, 'event/gallery/')
        if (gallery_image instanceof Error) {
          console.error(gallery_image)
          setLoading(false)
          return
        }
        gallery.push(gallery_image)
      }

      // const sitemap = await s3Image.upload(values.sitemap, 'event/sitemap/')

      const pastEventGallery: string[] = []
      for (const image of values.pastEventGallery) {
        const pastEventGallery_image = await s3Image.upload(image, 'event/pastEventGallery/')
        if (pastEventGallery_image instanceof Error) {
          console.error(pastEventGallery_image)
          setLoading(false)
          return
        }
        pastEventGallery.push(pastEventGallery_image)
      }

      const formData = {
        name: values.name,
        // subtitle: values.subtitle,
        event_datetime: utcDatetimeArray,
        // event_end_datetime: utcEndDatetimeArray,
        gender: values.gender,
        event_category: values.event_category,
        location: values.location,
        ticketType: values.ticketType,
        approvalRequired: values.approvalRequired,
        complimentaryDrink: values.complimentaryDrink,
        description: values.description,
        general_info: values.general_info,
        rulesAndRegulations: values.rulesAndRegulations,
        event_staff: values.assign_staff.map((item: any) => Number(item.value)),
        gallery: gallery,
        sitemap: values.site_map_enable === 1 ? await s3Image.upload(values.sitemap, 'event/sitemap/') : null, // Conditionally upload sitemap
        pastEventGallery: pastEventGallery,
        ticketDetails: values.ticketDetails,
        city: values.city,
        isFeatured: values.isFeatured === 1 ? true : false,
        site_map_enable: values.site_map_enable === 1 ? true : false,
        no_of_tables: values.no_of_tables,
        max_ticket: values.max_ticket,
      }
      console.log('Form Data:', formData)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/addEvent`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify(formData),
      }).then((result) => {
        if (result.status === 200) {
          // Navigate to the /staff page
          setLoading(false)
          setIsVideoUploading(false);
          delete localStorage['eventValues']
          navigate('/events')
        } else if (result.status === 400) {
          setLoading(false)
          setError(true)
          alert('Some error occurred while adding event. Please try again later.');
        } else {
          setLoading(false)
          alert('Some error occurred while adding event. Please try again later.');
          console.log('Error', result)
        }
      }).catch  ((error) => {
        setLoading(false)
        console.error('Error adding event:', error)
      })
    },
  })
  useEffect(() => {
    if (formik.values.ticketType === '') {
      formik.setFieldValue('max_ticket', 0);
      setMaxTicketDisabled(true);
    } else if (formik.values.ticketType === 'Single') {
      formik.setFieldValue('max_ticket', 1);
      setMaxTicketDisabled(true);
    } else if (formik.values.ticketType === 'Multiple') {
      setMaxTicketDisabled(false);
    }
  }, [formik.values.ticketType]);

  useEffect(() => {
    if (eventValues) {
      formik.setValues((eventValues as any) as EventValues)
      if (eventValues.event_datetime.length > 0) {
        if (
          eventValues.event_datetime.length === 1 &&
          (
            (eventValues.event_datetime[0].event_start_datetime === '' || eventValues.event_datetime[0].event_start_datetime === null) ||
            (eventValues.event_datetime[0].event_end_datetime === '' || eventValues.event_datetime[0].event_end_datetime === null)
          )
        ) {
          formik.setFieldValue('event_datetime', []); // Set event_datetime to an empty array
          setDatetimeInputs([...datetimeInputs, '']);
        }

        setDatetimeInputs(Array.from({ length: eventValues.event_datetime.length }));
      }
      if (eventValues.ticketDetails.length > 0) {
        setTicketDetails(Array.from({ length: eventValues.ticketDetails.length }));
      }
    }
  }, [eventValues]);


  // Save form values to local storage on change
  useEffect(() => {
    const saveObj = { ...formik.values }

    saveObj.gallery = []
    saveObj.pastEventGallery = []
    saveObj.sitemap = undefined // removes this field from object

    // console.log(saveObj.assign_staff)

    localStorage.setItem('eventValues', JSON.stringify(saveObj));
  }, [formik.values]);

  const currentDate = new Date()
  const currentDateTimeISOString = currentDate.toISOString().slice(0, 16)

  const [selectedValues, setSelectedValues] = useState([])

  const handleSelectChange = (selectedOptions) => {
    // const selectedOption = selectedOptions.map((option) => option)
    // const values = selectedOption.map(Number)
    setSelectedValues(selectedOptions)
  }

  useEffect(() => {
    formik.setFieldValue('assign_staff', selectedValues)
  }, [selectedValues])

  return (
    <div>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0 d-flex align-items-center'>
          <Link to='/events' className='btn btn-sm btn-icon btn-active-color-primary text-end'>
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <h3 className='fw-bolder m-0' id='startingPoint'>
            Add Event
          </h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        {/* <div className='fv-plugins-message-container text-center'>
                    <div className='fs-1 fv-help-block'>Please Add Images</div>
                </div> */}
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Event Date</label>
              <div className='col-lg-8'>
                {datetimeInputs.map((input, index) => (
                  <div className='row mb-3' key={index}>
                    <div className='col-lg-5 fv-row'>
                      <label className='mb-3 mb-lg-0' id='startDatetime'>
                        Start Date Time
                      </label>
                      <input
                        type='datetime-local'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        {...formik.getFieldProps(`event_datetime[${index}].event_start_datetime`)}
                        min={currentDateTimeISOString}
                      />

                      {formik.errors.event_datetime &&
                        (formik.errors.event_datetime[index] as FormikErrors<EventDatetime>)
                          ?.event_start_datetime && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {
                                (
                                  formik.errors.event_datetime[
                                  index
                                  ] as FormikErrors<EventDatetime>
                                ).event_start_datetime
                              }
                            </div>
                          </div>
                        )}
                    </div>
                    <div className='col-lg-5 fv-row'>
                      <label className='mb-3 mb-lg-0'>End Date Time</label>
                      <input
                        type='datetime-local'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        {...formik.getFieldProps(`event_datetime[${index}].event_end_datetime`)}
                        min={currentDateTimeISOString}
                      />
                      {dateErrorIndex === index ? (
                        <span className='text-danger' id='dateError'>
                          End date and time cannot be smaller than start date and time!
                        </span>
                      ) : null}
                      {formik.errors.event_datetime &&
                        (formik.errors.event_datetime[index] as FormikErrors<EventDatetime>)
                          ?.event_end_datetime && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {
                                (
                                  formik.errors.event_datetime[
                                  index
                                  ] as FormikErrors<EventDatetime>
                                ).event_end_datetime
                              }
                            </div>
                          </div>
                        )}
                    </div>
                    {index === 0 && (
                      <div className='col-lg-1 fv-row text-center'>
                        <button
                          type='button'
                          className='mb-3 mb-lg-0 btn btn'
                          onClick={handleAddDatetime}
                        >
                          +Add
                        </button>
                      </div>
                    )}
                    {index !== 0 && (
                      <div className='col-lg-2 fv-row text-center'>
                        <button
                          type='button'
                          className='mb-3 mb-lg-0 btn btn'
                          onClick={() => handleRemoveDatetime(index)}
                        >
                          -Remove
                        </button>
                      </div>
                    )}
                  </div>
                ))}
               {formik.touched.event_datetime && formik.errors.event_datetime && (
                 <div className='fv-plugins-message-container'>
                   <div className='fv-help-block'>Event Start Date Time and End Date time is required</div>
                 </div>
               )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Event Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Event-name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Is Featured</label>
              <div className='col-lg-8 fv-row'>
                <div className='row' >
                  <div className='col-lg-3 fv-row'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6' htmlFor="flexRadioDefault1" style={{ color: 'black' }}>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        id="flexRadioDefault1"
                        {...formik.getFieldProps('isFeatured')}
                        checked={formik.values.isFeatured === 1}
                        onChange={() => formik.setFieldValue('isFeatured', 1)} />
                      Yes
                    </label>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6' htmlFor="flexRadioDefault2">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        id="flexRadioDefault2"
                        {...formik.getFieldProps('isFeatured')}
                        checked={formik.values.isFeatured === 0}
                        onChange={() => formik.setFieldValue('isFeatured', 0)}

                      />
                      No
                    </label>
                  </div>
                  {formik.errors.isFeatured && formik.touched.isFeatured && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{String(formik.errors.isFeatured)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Location</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='location'
                  {...formik.getFieldProps('location')}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.location}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('city')}
                >
                  <option value=''>Select</option>
                  <option value='Doha'>Doha</option>
                  <option value='Lusail'>Lusail</option>
                  <option value='Rayyan'>Rayyan</option>

                </select>
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Admin Approval</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('approvalRequired')}
                >
                  <option value=''>Select</option>
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                {formik.touched.approvalRequired && formik.errors.approvalRequired && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.approvalRequired}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Gender</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('gender')}
                >
                  <option value=''>Select</option>
                  <option value='M'>Male</option>
                  <option value='F'>Female</option>
                  <option value='A'>Any</option>
                </select>
                {formik.touched.gender && formik.errors.gender && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gender}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Category</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('event_category')}
                >
                  <option value=''>Select</option>
                  {eventCategory.map(
                    (category) =>
                      category.status === 'Active' && (
                        <option key={category.id} value={category.id}>
                          {category.event_category}
                        </option>
                      )
                  )}
                </select>
                {formik.touched.event_category && formik.errors.event_category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.event_category}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Ticket Buy Type</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('ticketType')}
                >
                  <option value=''>Select</option>
                  <option value='Single'>Single</option>
                  <option value='Multiple'>Multiple</option>
                </select>
                {formik.touched.ticketType && formik.errors.ticketType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ticketType}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Max Ticket</label>
      <div className='col-lg-8 fv-row'>
        <input
          type='number'
          min={0}
          className='form-control form-control-lg form-control-solid'
          placeholder='max_ticket'
          {...formik.getFieldProps('max_ticket')}
          disabled={maxTicketDisabled}  // Disable field based on ticket type
        />
        {formik.touched.max_ticket && formik.errors.max_ticket && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.max_ticket}</div>
          </div>
        )}
      </div>
    </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Complimentary Drink</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('complimentaryDrink')}
                >
                  <option value=''>Select</option>
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                {formik.touched.complimentaryDrink && formik.errors.complimentaryDrink && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.complimentaryDrink}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>No of Tables</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  min={0}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='no of Tables'
                  {...formik.getFieldProps('no_of_tables')}
                />
                {formik.touched.no_of_tables && formik.errors.no_of_tables && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.no_of_tables}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>General Information</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('general_info')}
                />
                {formik.touched.general_info && formik.errors.general_info && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.general_info}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Rules and Regulations</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('rulesAndRegulations')}
                />
                {formik.touched.rulesAndRegulations && formik.errors.rulesAndRegulations && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.rulesAndRegulations}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Assign Staff</span>
              </label>

              <div className='col-lg-8'>
                {eventValues && (
                  <Select
                    defaultValue={localStorage.getItem('eventValues') && JSON.parse(localStorage.getItem('eventValues') as string).assign_staff}
                    isMulti
                    name='colors'
                    options={staffData}
                    className='basic-multi-select form-control form-control-lg form-control-solid'
                    classNamePrefix='select'
                    onChange={handleSelectChange}
                  />
                )}
                {formik.touched.assign_staff && formik.errors.assign_staff && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.assign_staff}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Gallery</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline d-flex'
                  data-kt-image-input='true'
                >
                  {/* {selectedGalleryImages.map((image, index) => (
                    <div
                      key={index}
                      className='image-input-wrapper w-125px h-125px mx-2'
                      style={{
                        backgroundImage: `url(${image || toAbsoluteUrl('/media/avatars/blank.png')
                          })`,
                      }}
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-danger position-absolute top-0 end-0'
                        onClick={() => removeGalleryImage(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))} */}
                  {selectedGalleryImages.map((image, index) => {
                    return (
                      <div>
                        {image.isVideo ? (
                          <video width="125" height="125" controls>
                            <source src={image.data} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            key={index}
                            className='image-input-wrapper w-125px h-125px mx-2'
                            style={{
                              backgroundImage: `url("${image.data || toAbsoluteUrl('/media/avatars/blank.png')}")`,
                            }}
                          >
                          </div>
                        )}
                        <button
                          type='button'
                          className='btn btn-sm btn-danger position-absolute top-0 end-0'
                          onClick={() => removeGalleryImage(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )
                  })}
                  {selectedGalleryImages.length < 1 && (
                    <div>
                      <div className='image-input-overlay'>
                        <i className='bi bi-plus fs-3'></i>
                        <input type="file" ref={GalleryInputRef} onChange={handleGallery} accept="image/*" />
                      </div>
                    </div>
                  )}
                </div>

                {formik.touched.gallery && formik.errors.gallery && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gallery}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Floor Plan Enable</label>
              <div className='col-lg-8 fv-row'>
                <div className='row' >
                  <div className='col-lg-3 fv-row'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6' htmlFor="flexRadioDefault1" style={{ color: 'black' }}>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        id="flexRadioDefault1"
                        {...formik.getFieldProps('site_map_enable')}
                        checked={formik.values.site_map_enable === 1}
                        onChange={() => formik.setFieldValue('site_map_enable', 1)} />
                      Yes
                    </label>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6' htmlFor="flexRadioDefault2">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        id="flexRadioDefault2"
                        {...formik.getFieldProps('site_map_enable')}
                        checked={formik.values.site_map_enable === 0}
                        onChange={() => {formik.setFieldValue('site_map_enable', 0)
                                  formik.setFieldValue('sitemap', ''); // Clear the sitemap field

                        }}

                      />
                      No
                    </label>
                  </div>
                  {formik.errors.site_map_enable && formik.touched.site_map_enable && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{String(formik.errors.site_map_enable)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {formik.values.site_map_enable === 1 && (
  <div className='row mb-6'>
    <label className='col-lg-4 col-form-label fw-bold fs-6'>Floor Plan</label>
    <div className='col-lg-8'>
      <div
        className='image-input image-input-outline d-flex'
        data-kt-image-input='true'
      >
        {/* Existing sitemap upload logic */}
        {selectedSitemap.map((image, index) => {
          return (
            
            <div>
                                      {image.isVideo ? (
                          <video width="125" height="125" controls>
                            <source src={image.data} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            key={index}
                            className='image-input-wrapper w-125px h-125px mx-2'
                            style={{
                              backgroundImage: `url("${image.data || toAbsoluteUrl('/media/avatars/blank.png')}")`,
                            }}
                          >
                          </div>
                        )}
              {/* Render image or video */}
              <button
                type='button'
                className='btn btn-sm btn-danger position-absolute top-0 end-0'
                onClick={() => removeSitemap(index)}
              >
                Remove
              </button>
            </div>
          );
        })}
        {selectedSitemap.length < 1 && (
          <div className='image-input-overlay'>
            <i className='bi bi-plus fs-3'></i>
            <input type="file" ref={SitemapInputRef} onChange={handleSitemap} accept="image/*" />
          </div>
        )}
      </div>
      {formik.touched.sitemap && formik.errors.sitemap && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            {formik.errors.sitemap as React.ReactNode}
          </div>
        </div>
      )}
    </div>
  </div>
)}


           

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Past Event Gallery</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline d-flex'
                  data-kt-image-input='true'
                >
                  {/* {selectedPastEventGallery.map((image, index) => (
                    <div
                      key={index}
                      className='image-input-wrapper w-125px h-125px mx-2'
                      style={{
                        backgroundImage: `url(${image || toAbsoluteUrl('/media/avatars/blank.png')
                          })`,
                      }}
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-danger position-absolute top-0 end-0'
                        onClick={() => removePastEventGallery(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))} */}
                  {selectedPastEventGallery.map((image, index) => {
                    return (
                      <div>
                        {image.isVideo ? (
                          <video width="125" height="125" controls>
                            <source src={image.data} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            key={index}
                            className='image-input-wrapper w-125px h-125px mx-2'
                            style={{
                              backgroundImage: `url("${image.data || toAbsoluteUrl('/media/avatars/blank.png')}")`,
                            }}
                          >
                          </div>
                        )}
                        <button
                          type='button'
                          className='btn btn-sm btn-danger position-absolute top-0 end-0'
                          onClick={() => removePastEventGallery(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )
                  })}
                  {selectedPastEventGallery.length < 5 && (
                    // <div className='image-input-wrapper w-125px h-125px mx-2'>
                    <div>
                      <div className='image-input-overlay'>
                        <i className='bi bi-plus fs-3'></i>
                        <input type='file' ref={PastEventGalleryInputRef} onChange={handlePastEventGallery} accept="image/*" />
                      </div>
                    </div>
                  )}
                </div>
                {formik.touched.pastEventGallery && formik.errors.pastEventGallery && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.pastEventGallery}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Ticket Details
              </label>

              <div className='col-lg-8'>
                {formik.values.approvalRequired === '1' ? (
                  <>
                    {ticketDetails.map((category, index) => (
                      <div className='row' key={index}>
                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Ticket Category</label>
                          <select
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            {...formik.getFieldProps(`ticketDetails[${index}].ticketCategory`)}
                          >
                            <option value=''>Select</option>
                            <option value='Free'>Guest List</option>
                          </select>
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.ticketCategory && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).ticketCategory
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>No of Tickets</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].noOfTickets`)}
                          />
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.noOfTickets && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).noOfTickets
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Price</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            readOnly
                            value='0'
                          />
                        </div>

                        <div className='col-lg-2 fv-row'>
                          <label className='mb-3 mb-lg-0'>Entry</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].entry`)}
                          />
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.entry && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).entry
                                  }
                                </div>
                              </div>
                            )}
                        </div>
                        {formik.touched.ticketDetails && formik.errors.ticketDetails && (
                           <div className='fv-plugins-message-container'>
                             <div className='fv-help-block'>Ticket Details are required</div>
                           </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {ticketDetails.map((category, index) => (
                      <div className='row' key={index}>
                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Ticket Category</label>
                          <select
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            {...formik.getFieldProps(`ticketDetails[${index}].ticketCategory`)}
                          >
                            <option value=''>Select</option>
                            {categoryData.map(
                              (category) =>
                                <option key={category.id} value={category.name}>
                                  {category.name}
                                </option>
                            )}
                            {/* <option value='Standard'>Standard</option>
                            <option value='Gold'>Gold</option> */}
                          </select>
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.ticketCategory && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).ticketCategory
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>No of Tickets</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].noOfTickets`)}
                          />
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.noOfTickets && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).noOfTickets
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Price</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].price`)}
                          />
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.price && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).price
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        <div className='col-lg-2 fv-row'>
                          <label className='mb-3 mb-lg-0'>Entry</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].entry`)}
                          />
                          {formik.touched.ticketDetails &&
                            formik.errors.ticketDetails &&
                            (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                              ?.entry && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {
                                    (
                                      formik.errors.ticketDetails[
                                      index
                                      ] as FormikErrors<TicketCategory>
                                    ).entry
                                  }
                                </div>
                              </div>
                            )}
                        </div>

                        {index === 0 && (
                          <div className='col-lg-1 fv-row text-center'>
                            <button
                              type='button'
                              className='mb-3 mb-lg-0 btn btn'
                              onClick={handleAddCategory}
                            >
                              +Add Category
                            </button>
                          </div>
                        )}

                        {index !== 0 && (
                          <div className='col-lg-1 fv-row text-center'>
                            <button
                              type='button'
                              className='mb-3 mb-lg-0 btn btn-danger'
                              onClick={() => handleRemoveCategory(index)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                        {formik.touched.ticketDetails && formik.errors.ticketDetails && (
                           <div className='fv-plugins-message-container'>
                             <div className='fv-help-block'>Ticket Details are required</div>
                           </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            {isVideoUploading && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  Uploading video files may take some time due to their large size. Please be patient and do not refresh or close the page.
                </div>
              </div>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/events' className='btn btn-light me-3' data-kt-users-modal-action='cancel' onClick={() => delete localStorage['eventValues']}>
              Cancel
            </Link>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default AddEvent
