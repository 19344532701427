import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {getAuthorizationToken} from '../../../../authorization-token'
import {formatDateWithTime} from '../../../../datetime.formate'
import PaginationComponent from '../../../../pagination'
import PlaceHolderComponent from '../../../../skeletol-view'
// import { useLocation } from 'react-router-dom';

interface UserIdState {
  userId: number // Adjust the type of userId if needed
}

const PaidEvent = () => {
  interface PaidEventList {
    event_id: number
    event_name: string
    event_date_id: number
    event_datetime: string
    location: string
    event_category: string
    ticket_type: string
    ticket_qty: string
    amount: string
    redeemed: string
  }

  const location = useLocation()
  const {userId} = location.state as UserIdState
  // console.log('paid Details userId', userId)
  // console.log("UserDetailView-Event Id :", userId)
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get('id')?.trim() || '';

  const [paidEventList, setPaidEventList] = useState<PaidEventList[]>([])
  const [loading, setLoading] = useState(true)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchPaidEventList = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getPaidEventList/${userId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp);
          setLoading(false)
          setPaidEventList(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching paid event data:', error)
      })
  }

  useEffect(() => {
    fetchPaidEventList()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10 // Number of items to display per page

  const indexOfLastData = currentPage * usersPerPage
  const indexOfFirstData = indexOfLastData - usersPerPage
  const currentData = paidEventList.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(paidEventList.length / usersPerPage)
  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1)

  return (
    <>
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Event Name</th>
                <th className='min-w-100px'>Address</th>
                <th className='min-w-100px'>Category</th>
                <th className='min-w-100px'>Event Date</th>
                <th className='min-w-100px'>Ticket Type</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-100px'>Amount</th>
                <th className='min-w-125px text-center rounded-end'>Redeemed</th>
              </tr>
            </thead>
            <tbody>
              {loading && <PlaceHolderComponent tableHeaders={8} />}
              {currentData.map((event) => (
                <tr key={event.event_id}>
                  <td>{event.event_name}</td>
                  <td>{event.location}</td>
                  <td>{event.event_category}</td>
                  <td>{formatDateWithTime(event.event_datetime)}</td>
                  <td>{event.ticket_type}</td>
                  <td>{event.ticket_qty}</td>
                  <td>{event.amount}</td>
                  <td className='text-center'>{event.redeemed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          // pageNumbers={pageNumbers}
          currentData={currentData}
          filteredData={paidEventList}
          indexOfFirstData={indexOfFirstData}
          indexOfLastData={indexOfLastData}
        />
      )}
    </>
  )
}

export {PaidEvent}
