export const formatDate = (utcDate) => {
  // Check if utcDate matches the desired format
  // if (!utcDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
  //   // Convert the UTC datetime string to the desired format
  //   utcDate = utcDate.replace(' ', 'T') + '.000Z';
  // }
  if (utcDate) {
    const localDate = new Date(utcDate)
    return localDate.toLocaleDateString('en-US', {
      // timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
  return ''
}
export const formatDateWithTime = (utcDate) => {
  if (utcDate) {
    // Check if utcDate matches the desired format
  if (!utcDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
    // Convert the UTC datetime string to the desired format
    utcDate = utcDate.replace(' ', 'T') + '.000Z';
  }

  // Create a Date object from the formatted UTC datetime
  const localDate = new Date(utcDate);

  // Format the local date as needed
  // return localDate.toLocaleDateString();
  return localDate.toLocaleDateString('en-US', {
    // timeZone: 'UTC',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  }
  
  return ''

}