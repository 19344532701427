import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {getAuthorizationToken} from '../../../authorization-token'
import {formatDate} from '../../../datetime.formate'
import PaginationComponent from '../../../pagination'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import PlaceHolderComponent from '../../../skeletol-view'

interface SUPPORT {
  subject: string
  message: string
}

const Support: React.FC = () => {
  // const support: SUPPORT[] = [
  //   {
  //     question: 'How can I contact support?',
  //     answer: 'You can contact our support team by emailing support@example.com.',
  //   },
  //   {
  //     question: 'What are the support hours?',
  //     answer: 'Our support team is available from 9:00 AM to 5:00 PM on weekdays.',
  //   },
  //   // Add more support questions as needed
  // ];

  interface Support {
    id: number
    user_id: number
    name: string
    email: string
    mobile_number: string
    subject: string
    message: string
    date_created: string
    date_updated: string
    status: string
  }

  const [userSupport, setUserSupport] = useState<Support[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchUserSupport = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserSupport`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          console.log('Result', resp)
          setLoading(false)
          setUserSupport(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching UserSupport data:', error)
      })
  }

  useEffect(() => {
    fetchUserSupport()
  }, [])

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleQuestion = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null)
    } else {
      setOpenIndex(index)
    }
  }

  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 10

  const indexOfLastData = currentPage * perPage
  const indexOfFirstData = indexOfLastData - perPage
  const currentData = userSupport.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(userSupport.length / perPage)

  // Array for page numbers
  const pageNumbers = Array.from({length: totalPages}, (_, index) => index + 1)

  return (
    <div className='faq-container'>
      <div className='faq-box'>
        <Row className='d-flex justify-content-between py-5' lg={2} md={20} xs={1}>
          <Col className='flex-end'>
            <h3 className='card-title align-items-start flex-column'>
              <Link
                to='/settings'
                className='btn btn-sm btn-icon btn-active-color-primary text-end '
              >
                <KTIcon iconName='arrow-left' className='fs-2' />
              </Link>
              <span className='card-label fw-bold fs-2 mb-1'>Support</span>
            </h3>
          </Col>
          <Col>
            <div className='text-end'>
              <Link to='completed-logs' className='btn btn-primary'>
                Completed Log
              </Link>
            </div>
          </Col>
        </Row>
        <div className='card-body py-3 my-6'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='min-w-50px'>ID</th>
                  <th className='min-w-100px'>Name</th>
                  <th className='min-w-100px'>Email</th>
                  <th className='min-w-100px'>Mobile Number</th>
                  <th className='min-w-125px rounded-end'>Subject</th>
                  <th className='min-w-125px rounded-end'>Messages</th>
                  <th className='min-w-100px'>Date</th>
                  <th className='min-w-100px'>Status</th>
                  {/* <th className='min-w-100px'>Status</th> */}
                  <th className='min-w-100px'>Action</th>
                  {/* <th className='min-w-50px'>View</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {loading && <PlaceHolderComponent tableHeaders={9} />}
                {currentData.map((support) => (
                  <tr key={support.id}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.id}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.name}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.email}
                      </a>
                      {/* <a
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        title={support.email.length > 15 ? support.email : ''}
                      >
                        {support.email.length > 15
                          ? support.email.slice(0, 15) + '...'
                          : support.email}
                      </a> */}
                      {/* {support.email.length < 15 ? (
                        <a className='text-dark fw-bold d-block mb-1 fs-6'>
                          {support.email}
                        </a>
                      ) : (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip>
                              {support.email}
                            </Tooltip>
                          }
                        >
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {support.email.slice(0, 15) + '...'}
                          </a>
                        </OverlayTrigger>
                      )} */}
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.mobile_number}
                      </a>
                    </td>
                    <td>
                      <a
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        title={support.subject.length > 15 ? support.subject : ''}
                      >
                        {support.subject.length > 15
                          ? support.subject.slice(0, 15) + '...'
                          : support.subject}
                      </a>
                    </td>
                    <td>
                      <a
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        title={support.message.length > 15 ? support.message : ''}
                      >
                        {support.message.length > 15
                          ? support.message.slice(0, 15) + '...'
                          : support.message}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.date_created ? formatDate(support.date_created) : '-'}
                      </a>
                    </td>
                    {/* <td>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.status}
                      </a> 
                    </td> */}
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.status}
                      </a>
                    </td>
                    <td>
                      <button
                        className='btn btn-secondary'
                        onClick={() => navigate('details', {state: support})}
                      >
                        View
                      </button>
                      {/* <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {support.status}
                      </a> */}
                      {/* <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        defaultValue={'pending'}
                      >
                        <option value='pending'>Pending</option>
                        <option value='inProcess'>In Process</option>
                        <option value='completed'>Completed</option>
                      </select>
                    </td>
                    <td className='text-center'>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        defaultValue={'1'}
                      >
                        <option></option>
                        <option value='1'>Select</option>
                        <option value='refunded'>Refunded</option>
                        <option value='complained'>Complained</option>
                      </select> */}
                      {/* <Link
                    to={`/settings/discount-code/codeDetails?id=${encodeURIComponent(promoCode.id)}`}
                    className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </Link>
                  <Link
                    to={`/settings/discount-code/editcode?id=${encodeURIComponent(promoCode.id)}`}
                    className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </Link>
                  <a
                    onClick={() => deleteCode(promoCode.id)}
                    className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                  >
                    Delete
                  </a> */}
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {totalPages > 1 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              // pageNumbers={pageNumbers}
              currentData={currentData}
              filteredData={userSupport}
              indexOfFirstData={indexOfFirstData}
              indexOfLastData={indexOfLastData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Support

{
  /* <div className='faq-container'>
  <div className='faq-box'>
    <h3 className='card-title align-items-start flex-column'>
      <Link to='/settings' className='btn btn-sm btn-icon btn-active-color-primary text-end '>
        <KTIcon iconName='arrow-left' className='fs-2' />
      </Link>
      <span className='card-label fw-bold fs-2 mb-1'>Support</span>
    </h3>
    {userSupport.map((faq, index) => (
      <div className='d-flex align-items-center position-relative my-1'>
        <div className='d-flex align-items-center position-relative my-1'>
          <div
            key={index}
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            style={{
              backgroundColor: openIndex === index ? '#ebebeb' : '#f0f0f0', // Light grey background color
              borderRadius: '8px', // Rounded edges
              padding: '20px', // Padding for content
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column', // Stack question and answer vertically
              width: '79vw', // Set the width to 96% of the viewport width
              maxWidth: 'none', // Remove the maximum width
              margin: '0 auto', // Center the container horizontally
            }}
          >
            <div
              className='d-flex align-items-center position-relative my-1'
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => toggleQuestion(index)}
            >
              {faq.subject}
              <span
                className='faq-toggle'
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px', // Fixed at the right end center
                  transform: 'translateY(-50%)', // Center the arrow vertically
                }}
              >
                {openIndex === index ? '▲' : '▼'}
              </span>
            </div>
            {openIndex === index && (
              <div className='faq-answer' style={{ marginTop: '10px' }}>
                {faq.message}
              </div>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
</div> */
}
