import React, { FC } from 'react'
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import EventDetails from './EventDetails'
import BookingDetails from './BookingDetails'
import { KTIcon } from '../../../_metronic/helpers'

const EventView: FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <ViewHeader />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='event-details'
                    element={
                        <>
                            <EventDetails />
                        </>
                    }
                />
                <Route
                    path='booking-details/*'
                    element={
                        <>
                            <BookingDetails />
                        </>
                    }
                />
                <Route index element={<Navigate to='/events/event-view/event-details' />} />
            </Route>
        </Routes>
    )
}

interface EventIdState {
    eventId: number; // Adjust the type of eventId if needed
}

const ViewHeader: FC = () => {
    const location = useLocation();
    const { eventId } = location.state as EventIdState;
    // console.log("EventView-Event Id :", eventId)

    return (
        <div className='d-flex align-items-center'>
            <Link
                to='/events'
                className='btn btn-sm btn-icon btn-active-color-primary text-end '
            >
                <KTIcon iconName='arrow-left' className='fs-2' />
            </Link>
            <div className='d-flex overflow-auto h-55px'>

                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === '/events/event-view/event-details' && 'active')
                            }
                            to='/events/event-view/event-details'
                            state = {{eventId : eventId}}
                        >
                            Event Details
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === '/events/event-view/booking-details' && 'active')
                            }
                            to='/events/event-view/booking-details'
                            state = {{eventId : eventId}}
                        >
                            Booking Details
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}


export default EventView
