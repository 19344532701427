import { Link, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import Promo from "./Discount/Promo"
import Vouchers from "./Discount/Vouchers"
import { FC } from "react"
import { KTIcon } from "../../../_metronic/helpers"

const DiscountCode: FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <DiscountCodeHeader />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='promo-code'
                    element={
                        <>
                            <Promo />
                        </>
                    }
                />
                <Route
                    path='voucher-code'
                    element={
                        <>
                            <Vouchers />
                        </>
                    }
                />
                <Route index element={<Navigate to='/settings/discount-code/promo-code' />} />
            </Route>
        </Routes>
    )
}

const DiscountCodeHeader: FC = () => {
    const location = useLocation()
    return (
        <>
            <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='card-title align-items-start flex-column'>
                    <Link
                        to='/settings'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end '
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <span className='card-label fw-bold fs-2 mb-1'>Discount Code</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                </h3>
                <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <Link to='/settings/discount-code/addcode' className='btn btn-primary btn-sm' data-bs-toggle='tooltip' title='Coming soon'>
                            Create Code
                        </Link>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center'>
                <div className='d-flex overflow-auto h-55px'>

                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/discount-code/promo-code' && 'active')
                                }
                                to='/settings/discount-code/promo-code'
                            >
                                Promo Code
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/discount-code/voucher-code' && 'active')
                                }
                                to='/settings/discount-code/voucher-code'
                            >
                                Voucher Code
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default DiscountCode