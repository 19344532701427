import { useEffect, useState } from "react";
import { getAuthorizationToken } from "../../../authorization-token";
import { KTIcon } from "../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { formatDateWithTime } from "../../../datetime.formate";
import * as XLSX from 'xlsx';
import PaginationComponent from "../../../pagination";
import PlaceHolderComponent from "../../../skeletol-view";

const PaymentHistoryWrapper = () => {
    interface PaymentHistory {
        booking_id: number
        user_name: string
        amount: string
        discount: string
        booking_datetime: string
        status: string
        ticket_qty: number
        event_name: string
        event_datetime: string
        promocode_name: string
    }
    const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>([])
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchDate, setSearchDate] = useState<string>('')

    //  const [newPassword, setNewPassword] = useState('');
    //  const [confirmPassword, setConfirmPassword] = useState('');

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()


    // Get ALL Staff List
    const fetchStaffData = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/paymentHistory`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setLoading(false)
                    setPaymentHistory(resp);
                })
            })
            .catch((error) => {
                console.error('Error fetching staff data:', error);
            });
    }

    // For Get ALL Staff List When page is load
    useEffect(() => {
        fetchStaffData();
    }, [])

    const [currentPage, setCurrentPage] = useState(1);

    // For filteration by search
    const filteredData = paymentHistory.filter((history) =>
        ((history.user_name ? history.user_name.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
            (history.event_name ? history.event_name.toLowerCase().includes(searchTerm.toLowerCase()) : false)) &&
        (!searchDate ||
            (new Date(history.booking_datetime).toISOString().substr(0, 10) === new Date(searchDate).toISOString().substr(0, 10)))
    )

    // Reset current page to 1 when data is filtered
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, searchDate]);

    const paymentHistoryPerPage = 10;

    const indexOfLastData = currentPage * paymentHistoryPerPage;
    const indexOfFirstData = indexOfLastData - paymentHistoryPerPage;
    const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

    // Total number of pages
    const totalPages = Math.ceil(filteredData.length / paymentHistoryPerPage);

    // Array for page numbers
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const exportToExcel = () => {
        // Define your data as an array of objects
        const data = currentData.map((paymentHistory) => ({
            'Name': paymentHistory.user_name, // Customize column names as needed
            'Amount': paymentHistory.amount ? `QR ${paymentHistory.amount}` : null,
            'Discount': paymentHistory.discount,
            'Booking Date': formatDateWithTime(paymentHistory.booking_datetime),
            'Tickets': paymentHistory.ticket_qty,
            'Event Name': paymentHistory.event_name,
            'Event Date': formatDateWithTime(paymentHistory.event_datetime),
            'Promo': paymentHistory.promocode_name,
        }));

        // Create a new workbook and add a worksheet
        const ws = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Payment History');

        // Define the file name and save the Excel file
        const fileName = 'payment_history.xlsx';
        XLSX.writeFile(wb, fileName);
    };

    return (
        <div className='mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-2 mb-1'>Payment History</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                </h3>
                <div className='card-toolbar '>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-350px ps-14'
                            placeholder='Search by User Name, Event Name '
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                            }}
                            autoComplete="off"
                            inputMode='search'
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className='card-toolbar'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <input
                            type='date'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-180px ps-14'
                            placeholder='Date'
                            value={searchDate}
                            onChange={(e) => {
                                setSearchDate(e.target.value)
                            }}
                        />
                        <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
                    </div>
                    {/* end::Search */}
                </div>
                <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
                            onClick={exportToExcel}
                        >
                            <KTIcon iconName='arrow-up' className='fs-2' />
                        </a>
                    </div>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3 my-6'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted bg-light'>
                                <th className='min-w-100px'>Name</th>
                                <th className='min-w-100px text-center'>Amount</th>
                                <th className='min-w-100px text-center'>Discount</th>
                                <th className='min-w-100px text-center'>Booking Date</th>
                                <th className='min-w-100px text-center'>Tikctes</th>
                                <th className='min-w-100px text-center'>Event Name</th>
                                <th className='min-w-100px text-center'>Event Date</th>
                                <th className='min-w-125px text-center rounded-end'>Promo</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {loading && (
                                <PlaceHolderComponent
                                    tableHeaders={8}
                                />
                            )}
                            {currentData.map((paymentHistory) => (
                                <tr key={paymentHistory.booking_id}>
                                    <td>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.user_name ? paymentHistory.user_name : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.amount ? 'QR ' + paymentHistory.amount : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.discount ? paymentHistory.discount : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.booking_datetime ? formatDateWithTime(paymentHistory.booking_datetime) : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.ticket_qty ? paymentHistory.ticket_qty : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.event_name ? paymentHistory.event_name : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.event_datetime ? formatDateWithTime(paymentHistory.event_datetime) : '-'}
                                        </a>
                                    </td>
                                    <td className='text-center'>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {paymentHistory.promocode_name ? paymentHistory.promocode_name : '-'}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
                {/* Pagination */}
                {totalPages > 1 && (
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        // pageNumbers={pageNumbers}
                        currentData={currentData}
                        filteredData={filteredData}
                        indexOfFirstData={indexOfFirstData}
                        indexOfLastData={indexOfLastData}
                    />
                )}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export default PaymentHistoryWrapper