import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { getAuthorizationToken } from '../../../../authorization-token'
import * as XLSX from 'xlsx';
import PaginationComponent from '../../../../pagination';
import PlaceHolderComponent from '../../../../skeletol-view';


interface UserEarnings {
  id: number
  user_name: string
  email: string
  mobile_number: string
  gender: string
  interest: string
  total_events: number
  booked_tickets: number
  total_earnings: number
  event_datetime: string[]
}

const Users = () => {
  const [userEarningsReport, setUserEarningsReport] = useState<UserEarnings[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get UserEarningsReport
  const fetchUserEarningsReport = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllUserEarnings`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("UserEarningsReport :", data);
        setLoading(false)
        setUserEarningsReport(data)
      })
      .catch((error) => {
        console.error('Error fetching UserEarningsReport :', error)
      })
  }

  // Fetch UserEarningsReport when the page loads
  useEffect(() => {
    fetchUserEarningsReport()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const userEarningsReportPerPage = 10

  // Get the earningsReport to display on the current page
  // For filteration by search
  const filteredData = userEarningsReport
    .filter((userEarnings) => {
      const nameMatch =
        userEarnings.user_name &&
        userEarnings.user_name.toLowerCase().includes(searchTerm.toLowerCase())
      const emailMatch =
        userEarnings.email && userEarnings.email.toLowerCase().includes(searchTerm.toLowerCase())
      const mobileNumberMatch =
        userEarnings.mobile_number &&
        userEarnings.mobile_number.toLowerCase().includes(searchTerm.toLowerCase())

      return (
        (nameMatch || emailMatch || mobileNumberMatch) &&
        (!selectedGenderFilter ||
          selectedGenderFilter === 'Any' || // Show any gender when "Any" is selected
          userEarnings.gender === selectedGenderFilter)
      )
    })
    .sort((a, b) => {
      if (selectedSortBY === 'earningHighest') {
        // Sort by highest booked tickets first
        return b.total_earnings - a.total_earnings;
      }
      if (selectedSortBY === 'earningLowest') {
        // Sort by lowest booked tickets first
        return a.total_earnings - b.total_earnings;
      }
      if (selectedSortBY === 'bookingHighest') {
        // Sort by highest booked tickets first
        return b.booked_tickets - a.booked_tickets;
      }
      if (selectedSortBY === 'bookingLowest') {
        // Sort by lowest booked tickets first
        return a.booked_tickets - b.booked_tickets;
      }

      // Default sorting (no sorting)
      return 0;
    });

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedGenderFilter]);

  const indexOfLastData = currentPage * userEarningsReportPerPage
  const indexOfFirstData = indexOfLastData - userEarningsReportPerPage
  const currentData = filteredData.slice(
    indexOfFirstData,
    indexOfLastData
  )

  const exportToExcel = () => {
    // Define your data as an array of objects
    const data = currentData.map((userEarnings) => ({
      'User Name': userEarnings.user_name, // Customize column names as needed
      'Email Address': userEarnings.email,
      'Mobile Number': userEarnings.mobile_number,
      'Events': userEarnings.total_events,
      'Booked Tickets': userEarnings.booked_tickets,
      Gender: userEarnings.gender === 'M' ? 'Male' : userEarnings.gender === 'F' ? 'Female' : 'Any',
      'Interest': userEarnings.interest,
      'Total Earnings': `QR ${userEarnings.total_earnings}`,
    }));

    // Create a new workbook and add a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users Report');

    // Define the file name and save the Excel file
    const fileName = 'users_report.xlsx';
    XLSX.writeFile(wb, fileName);
  };

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / userEarningsReportPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1)

  return (
    <>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div
          className='card-title m-0 d-flex align-items-center'
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className='card-toolbar' style={{ flex: '1 1 0%', marginRight: '10px' }}>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-362px ps-14'
                placeholder='Search by User Name, Email & Mobile Number'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar' style={{ marginRight: '10px' }}>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedGenderFilter || ''}
                onChange={(e) => {
                  setSelectedGenderFilter(e.target.value)
                }}
                style={{ width: '450px' }} // Adjust the width here
              >
                {/*      */}
                <option value=''>Gender</option>
                <option value='M'>Male</option>
                <option value='F'>Female</option>
                <option value='A'>Any</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedSortBY || ''}
                onChange={(e) => {
                  setSelectedSortBY(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Sort By</option>
                <option value='earningHighest'>Earning Highest First</option>
                <option value='earningLowest'>Earning Lowest First</option>
                <option value='bookingHighest'>Booking Highest First</option>
                <option value='bookingLowest'>Booking Lowest First</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <a
            // href='#'
            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
            onClick={exportToExcel}
          >
            <KTIcon iconName='arrow-up' className='fs-2' />
          </a>
        </div>
      </div>

      <div className='mb-5 mb-xl-8'>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='min-w-100px'>User Name</th>
                  <th className='min-w-100px'>Email Address</th>
                  <th className='min-w-100px'>Mobile Number</th>
                  <th className='min-w-100px text-center'>Gender</th>
                  <th className='min-w-100px text-center'>Interest</th>
                  <th className='min-w-100px text-center'>Events</th>
                  <th className='min-w-100px text-center'>Booked Tickets</th>
                  <th className='min-w-125px text-center rounded-end'>Total Earnings</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {loading && (
                  <PlaceHolderComponent
                    tableHeaders={8}
                  />
                )}
                {currentData.map((userEarnings) => (
                  <tr key={userEarnings.id}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.user_name}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.email}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.mobile_number ?? '-'}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.gender === 'M'
                          ? 'Male'
                          : userEarnings.gender === 'F'
                            ? 'Female'
                            : 'Any'}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.interest}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.total_events}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {userEarnings.booked_tickets}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        QR {userEarnings.total_earnings}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
    </>
  )
}

export default Users
