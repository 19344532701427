/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { getAuthorizationToken } from '../../../../authorization-token'
import { SidebarMenuItem } from '../../../layout/components/sidebar/sidebar-menu/SidebarMenuItem'
import profile from '../../../../logo/image.png'

interface AdminProfile {
  name: string
  email: string
}

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()
  const authData = getAuth();
  const id = authData?.adminId

  const [adminProfile, setAdminProfile] = useState<AdminProfile | null>(null);

  const fetchAdminProfile = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdminProfile/${id}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Admin Profile Details:", data);
        setAdminProfile(data);
      })
      .catch((error) => {
        console.error('Error fetching description:', error);
      });
  }

  useEffect(() => {
    fetchAdminProfile()
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl(profile)} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {/* {currentUser?.first_name} {currentUser?.first_name} */}
              {adminProfile && (
                <div className='menu-item px-5'>
                  <span className='px-5'>{adminProfile.name}</span>
                </div>
              )}
            </div>
            {/* <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a> */}
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>

      {adminProfile && (
        <div className='menu-item px-5'>
          <span className='px-5'>{adminProfile.email}</span>
        </div>
      )}
      <div className='menu-item px-5'>
        <SidebarMenuItem to='/settings' title='Settings' fontIcon='setting' />
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Log Out
        </a>
      </div>
      {/* <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
    </div>
  )
}

export { HeaderUserMenu }
