import React, { FC, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { formatDate, formatDateWithTime } from '../../../../datetime.formate'
import { getAuthorizationToken } from '../../../../authorization-token'
import * as XLSX from 'xlsx';
import PaginationComponent from '../../../../pagination'
import PlaceHolderComponent from '../../../../skeletol-view'

interface AllCategory {
  id: number
  event_category: string
  status: string
}

interface Events {
  id: number
  gender: string
  booked_tickets: number
  event_name: string
  event_datetime: string[]
  event_category: string
  location: string
  redeemed_tickets: number
  num_male_attendees: number
  num_female_attendees: number
}

const Events: FC = () => {
  const [eventsReport, setEventsReport] = useState<Events[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  const [allCategory, setAllCategory] = useState<AllCategory[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get EventReport
  const fetchEventsReport = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getEventsList`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("EventsReport :", data);
        setLoading(false)
        setEventsReport(data)
      })
      .catch((error) => {
        console.error('Error fetching EventsReport :', error)
      })
  }

  // Fetch EventReport when the page loads
  useEffect(() => {
    fetchEventsReport()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const eventsReportPerPage = 10

  // Get the eventReport to display on the current page
  // For filteration by search
  const filteredData = eventsReport.filter(
    (event) =>
      (event.event_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (!selectedGenderFilter ||
        selectedGenderFilter === 'Any' || // Show any gender when "Any" is selected
        event.gender === selectedGenderFilter) &&
      (!selectedCategory ||
        selectedCategory === 'A' ||
        event.event_category === selectedCategory) &&
      (!searchDate ||
        event.event_datetime.some((date) => {
          const eventDate = new Date(date)
          const selectedDate = new Date(searchDate)
          return eventDate.toDateString() === selectedDate.toDateString()
        }))
  )
    .sort((a, b) => {
      if (selectedSortBY === 'dateNewest') {
        // Sort by newest first based on event date
        return new Date(a.event_datetime[0]).getTime() - new Date(b.event_datetime[0]).getTime();
      }
      if (selectedSortBY === 'dateOldest') {
        // Sort by oldest first based on event date
        return new Date(b.event_datetime[0]).getTime() - new Date(a.event_datetime[0]).getTime();
      }
      if (selectedSortBY === 'redeemedHighest') {
        // Sort by highest booked tickets first
        return b.redeemed_tickets - a.redeemed_tickets;
      }
      if (selectedSortBY === 'redeemedLowest') {
        // Sort by lowest booked tickets first
        return a.redeemed_tickets - b.redeemed_tickets;
      }
      if (selectedSortBY === 'bookingHighest') {
        // Sort by highest booked tickets first
        return b.booked_tickets - a.booked_tickets;
      }
      if (selectedSortBY === 'bookingLowest') {
        // Sort by lowest booked tickets first
        return a.booked_tickets - b.booked_tickets;
      }

      // Default sorting (no sorting)
      return 0;
    });

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedGenderFilter, selectedCategory, searchDate]);

  const indexOfLastData = currentPage * eventsReportPerPage
  const indexOfFirstData = indexOfLastData - eventsReportPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  const exportToExcel = () => {
    // Define your data as an array of objects
    const data = currentData.map((event) => ({
      'Event Date': event.event_datetime.map(formatDateWithTime).join(', '),
      'Event Name': event.event_name,
      Location: event.location,
      Gender: event.gender === 'M' ? 'Male' : event.gender === 'F' ? 'Female' : 'Any',
      'Category': event.event_category,
      'Booked Tickets': event.booked_tickets,
      'Redeemed Tickets': event.redeemed_tickets,
      'No of Male': event.num_male_attendees,
      'No of Female': event.num_female_attendees,
    }));

    // Create a new workbook and add a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Event Report');

    // Define the file name and save the Excel file
    const fileName = 'event_report.xlsx';
    XLSX.writeFile(wb, fileName);
  };

  // Total number of pages
  const totalPages = Math.ceil(eventsReport.length / eventsReportPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1)

  return (
    <>
      {/* begin::Header */}
      <div
        className='card-title m-0 d-flex align-items-center'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className='card-toolbar '>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-350px ps-14'
              placeholder='Search by event name, location'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              type='date'
              className='form-control form-control-solid w-180px ps-14'
              placeholder='Date'
              value={searchDate}
              onChange={(e) => setSearchDate(e.target.value)}
            />

            <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedGenderFilter || ''}
              onChange={(e) => {
                setSelectedGenderFilter(e.target.value)
              }}
            >
              <option value='Any'>Gender</option>
              <option value='M'>Male</option>
              <option value='F'>Female</option>
              <option value='A'>Any</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedCategory || ''}
              onChange={(e) => {
                setSelectedCategory(e.target.value)
              }}
            >
              <option value=''>Category</option>
              {allCategory.map((event) => (
                <option key={event.id} value={event.event_category}>
                  {event.event_category}
                </option>
              ))}
            </select>
          </div>

          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedSortBY || ''}
              onChange={(e) => {
                setSelectedSortBY(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Sort By</option>
              <option value='redeemedHighest'>Redeemed Highest First</option>
              <option value='redeemedLowest'>Redeemed Lowest First</option>
              <option value='bookingHighest'>Booking Highest First</option>
              <option value='bookingLowest'>Booking Lowest First</option>
              <option value='dateNewest'>Event Date Newest First</option>
              <option value='dateOldest'>Event Date Oldest First</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative my-1'>
            <a
              href='#'
              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
              onClick={exportToExcel}
            >
              <KTIcon iconName='arrow-up' className='fs-2' />
            </a>
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Event Date</th>
                <th className='min-w-100px'>Event Name</th>
                <th className='min-w-100px'>Location</th>
                <th className='min-w-100px text-center'>Gender</th>
                <th className='min-w-100px text-center'>Category</th>
                <th className='min-w-100px text-center'>Booked Tickets</th>
                <th className='min-w-100px text-center'>Redeemed Tickets</th>
                <th className='min-w-100px text-center'>Male</th>
                <th className='min-w-100px text-center'>Female</th>
                <th className='min-w-100px text-center'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && (
                <PlaceHolderComponent
                  tableHeaders={10}
                />
              )}
              {currentData.map((event) => (
                <tr key={event.id}>
                  <td>
                    {event.event_datetime.length === 1 ? (
                      <a
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatDate(event.event_datetime[0])}
                      </a>
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            {event.event_datetime.map((datetime) => (
                              <div key={datetime}>{formatDateWithTime(datetime)}</div>
                            ))}
                          </Tooltip>
                        }
                      >
                        <a
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          Dates
                        </a>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.event_name}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.location}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.gender === 'M' ? 'Male' : event.gender === 'F' ? 'Female' : 'Any'}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.event_category}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.booked_tickets}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.redeemed_tickets}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.num_male_attendees}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {event.num_female_attendees}
                    </a>
                  </td>
                  <td className='text-center'>
                    <Link to={'/report/event/event-reports'}
                      state={{ eventId: event.id }}
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
    </>
  )
}

export default Events
