import { Link } from "react-router-dom"
import { KTIcon } from "../../../_metronic/helpers"
import { useEffect, useState } from "react";
import { getAuthorizationToken } from "../../../authorization-token";

const PrivacyPolicy: React.FC = () => {
    const [descriptionContent, setDescriptionContent] = useState('');

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getPrivacyPolicy`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    console.log("Result", resp)
                    setDescriptionContent(resp.description);
                })
            })
            .catch((error) => {
                console.error('Error fetching description:', error);
            });
    }, [])


    return (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <Link
                        to='/settings'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end '
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <h3 className='fw-bolder m-0'>Privacy Policy</h3>
                </div>

                <Link to='/settings/privacy-policy/edit' className='btn btn-primary align-self-center'>
                    Edit
                </Link>
            </div>

            <div className='card-body p-9'>

                <div className='row mb-7'>

                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        <span className=' fs-6'>
                            <div
                                dangerouslySetInnerHTML={{ __html: descriptionContent.replace('white', '').replace('font-size', '').replaceAll('rgb(255, 255, 255)', 'rgb(152, 153, 152)') }}
                            />
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PrivacyPolicy