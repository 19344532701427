import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import { getAuthorizationToken } from '../../../authorization-token'

interface FAQ {
  id: number
  question: string
  answer: string
}

const FAQs: React.FC = () => {

  const [faqs, setFAQs] = useState<FAQ[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchFAQs = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllFAQs`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setFAQs(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching FAQs data:', error)
      })
  }

  useEffect(() => {
    fetchFAQs()
  }, []);

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleQuestion = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null)
    } else {
      setOpenIndex(index)
    }
  }

  return (
    <div className='faq-container'>
      <div className='faq-box'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <Link to='/settings' className='btn btn-sm btn-icon btn-active-color-primary text-end '>
                <KTIcon iconName='arrow-left' className='fs-2' />
              </Link>
              <span className='card-label fw-bold fs-2 mb-1'>FAQs</span>
            </div>
            <div>
              <Link
                to='/settings/fAQs/add'
                className='btn btn-primary btn-sm'
                data-bs-toggle='tooltip'
                title='Coming soon'
              >
                {/* <KTIcon iconName='plus' className='fs-2' /> */}
                Add
              </Link>
            </div>
          </div>
        </h3>
        {faqs.map((faq, index) => (
          <div className='d-flex align-items-center position-relative my-1'>
            <div className='d-flex align-items-center position-relative my-1'>
              <div
                key={index}
                className={`faq-item ${openIndex === index ? 'open' : ''}`}
                style={{
                  backgroundColor: openIndex === index ? '#ebebeb' : '#f0f0f0', // Light grey background color
                  borderRadius: '8px', // Rounded edges
                  padding: '20px', // Padding for content
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column', // Stack question and answer vertically
                  width: '79vw', // Set the width to 96% of the viewport width
                  maxWidth: 'none', // Remove the maximum width
                  margin: '0 auto', // Center the container horizontally
                }}
              >
                <div
                  className='d-flex align-items-center position-relative my-1'
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => toggleQuestion(index)}
                >
                  {faq.question}
                  <span
                    className='faq-toggle'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '30px', // Fixed at the right end center
                      transform: 'translateY(-50%)', // Center the arrow vertically
                    }}
                  >
                    {openIndex === index ? '▲' : '▼'}
                  </span>
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '-10px', // Fixed at the right end center
                      transform: 'translateY(-50%)', // Center the arrow vertically
                    }}
                  >
                    <Link
                      to={
                        `/settings/fAQs/edit?id=${encodeURIComponent(faq.id)}
                        &question=${encodeURIComponent(faq.question)}
                        &answer=${encodeURIComponent(faq.answer)}`
                      }
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      data-bs-toggle='tooltip'
                      title='Coming soon'
                    >
                      <KTIcon iconName='pencil' className='fs-2' />
                    </Link>
                  </div>
                </div>
                {openIndex === index && (
                  <div className='faq-answer' style={{ marginTop: '10px',  whiteSpace: 'pre-wrap'}}>
                    {faq.answer}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FAQs
