import {Link, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FC, useEffect, useState} from 'react'
import {PaidEvent} from './PaidEvent'
import {ApprovalNeededEvent} from './ApprovalNeededEvent'
import {getAuthorizationToken} from '../../../../authorization-token'
import {formatDateWithTime} from '../../../../datetime.formate'
import profile from '../../../../logo/image.png'
import {useNavigate} from 'react-router-dom'
import {IsArtist, IsCollaborator} from '../UsersWrapper'

const UserDetailView: FC = () => {
  return (
    //   <>
    //     {/* <h1>Hello, event-view</h1> */}
    //     <ViewHeader />
    //   </>
    <Routes>
      <Route
        element={
          <>
            <UserDetails />
            <Outlet />
          </>
        }
      >
        <Route
          path='paid-event'
          element={
            <>
              <PaidEvent />
            </>
          }
        />
        <Route
          path='approval-needed-event'
          element={
            <>
              <ApprovalNeededEvent />
            </>
          }
        />
        <Route index element={<Navigate to='/users/user-details/paid-event' />} />
      </Route>
    </Routes>
  )
}

interface UserIdState {
  userId: number // Adjust the type of userId if needed
  vip: number
}

const UserDetails = () => {
  interface UserDetails {
    id: number
    image: string
    date_created: string
    gender: string
    full_name: string
    age: string
    mobile_number: string
    event_categories: string
    status: string
    is_vip: number
    is_artist: IsArtist
    is_collaborator: IsCollaborator
    nationality: string
    reason:string
  }
  const navigate = useNavigate()
  const location = useLocation()
  const {userId} = location.state as UserIdState
  // console.log('user Details userId', userId)
  const [vipStatus, setVipStatus] = useState(0)
  const [loading, setLoading] = useState(false)

  const [isChecked, setIsChecked] = useState(false)

  const [openVipModal, setOpenVipModal] = useState(false)
  const [openNonVipModal, setOpenNonVipModal] = useState(false)

  const openVipModalHandler = () => {
    setOpenVipModal(true)
  }

  const openNonVipModalHandler = () => {
    setOpenNonVipModal(true)
  }

  const closeModals = () => {
    setOpenVipModal(false)
    setOpenNonVipModal(false)
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      openVipModalHandler()
    } else {
      openNonVipModalHandler()
    }
  }

  const [userDetails, setUserDetails] = useState<UserDetails | null>(null)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get ALL User Details by ID
  const fetchUserDetails = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/userDetails/${userId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setUserDetails(resp)
          setVipStatus(resp.is_vip)
          setIsChecked(resp.is_vip === 1 ? true : false)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // For Get ALL Staff List When page is load
  useEffect(() => {
    fetchUserDetails()
  }, [])

  const markVip = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/markUserAsVip`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({
        userId: userId,
        isVip: vipStatus === 1 ? 0 : 1,
      }),
    }).then((result) => {
      if (result.status === 200) {
        isChecked ? setIsChecked(false) : setIsChecked(true)
        vipStatus === 1 ? setVipStatus(0) : setVipStatus(1)
        closeModals()
        setLoading(false)
      } else if (result.status === 400) {
        console.log('Error', result)
        setLoading(false)
      } else {
        console.log('Error', result)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <>
     
        {openNonVipModal && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-450px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h2 className='fw-bolder text-center'>Remove this user as a VIP</h2>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-danger'
                    data-kt-users-modal-action='close'
                    onClick={closeModals}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                  {/* end::Close */}
                </div>
                {/* begin::Modal body */}
                <div className='scroll-y mx-1 mx-xl-15 my-4'>
                  <div className='text-center pt-8'>
                    <button
                      type='reset'
                      onClick={() => closeModals()}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      type='submit'
                      className='btn btn-danger'
                      data-kt-users-modal-action='submit'
                      onClick={markVip}
                      disabled={loading}
                    >
                      <span className='indicator-label'>Remove</span>
                    </button>
                  </div>
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
        )}
        {openNonVipModal && <div className='modal-backdrop fade show'></div>}
      </>
      <>
        {openVipModal && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-450px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h2 className='fw-bolder text-center'>'Mark as VIP' to make this user a VIP.</h2>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-danger'
                    data-kt-users-modal-action='close'
                    onClick={closeModals}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                  {/* end::Close */}
                </div>
                {/* begin::Modal body */}
                <div className='scroll-y mx-5 mx-xl-15 my-5'>
                  <div className='text-center pt-8'>
                    <button
                      type='reset'
                      onClick={() => closeModals()}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      onClick={markVip}
                      disabled={loading}
                    >
                      <span className='indicator-label'>Mark as VIP</span>
                    </button>
                  </div>
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
        )}
        {openVipModal && <div className='modal-backdrop fade show'></div>}
      </>
      <h3 className='card-title align-items-start flex-column'>
        <Link
          to=''
          onClick={() => navigate(-1)}
          state={{userId: userId}}
          className='btn btn-sm btn-icon btn-active-color-primary text-end '
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
        </Link>
        <span className='card-label fw-bold fs-2 mb-1'>Users</span>
        {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
      </h3>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              


              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={userDetails?.image ? userDetails.image : profile} alt='User-Profile' />
                {userDetails && userDetails.status === 'Active' ? (
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                ) : (
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-danger rounded-circle border border-4 border-white h-20px w-20px'></div>
                )}
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'></div>

                {userDetails && (
                  <>
                    <div className='col-lg-10'>
                      <div className='card-body p-9'>
                        <div className='row mb-7'>
                          <label className='col-lg-2 fw-bold text-muted '>Registered Date</label>

                          <div className='col-lg-4 '>
                            <span className='fw-bolder fs-6 text-dark'>
                              {formatDateWithTime(userDetails.date_created)}
                            </span>
                          </div>

                          <label className='col-lg-2 fw-bold text-muted '>Gender</label>

                          <div className='col-lg-3 fv-row '>
                            <span className='fw-bold fs-6'>
                              {userDetails.gender === 'M'
                                ? 'Male'
                                : userDetails.gender === 'F'
                                ? 'Female'
                                : '-'}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-7'>
                          <label className='col-lg-2 fw-bold text-muted '>User Name</label>

                          <div className='col-lg-4 '>
                            <span className='fw-bolder fs-6 text-dark'>
                              {userDetails.full_name}
                            </span>
                          </div>

                          <label className='col-lg-2 fw-bold text-muted '>Age</label>

                          <div className='col-lg-3 fv-row '>
                            <span className='fw-bold fs-6'>
                              {userDetails.age ? userDetails.age : '-'}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-7'>
                          <label className='col-lg-2 fw-bold text-muted '>Mobile Number</label>

                          <div className='col-lg-4 '>
                            <span className='fw-bolder fs-6 text-dark'>
                              {userDetails.mobile_number}
                            </span>
                          </div>

                          <label className='col-lg-2 fw-bold text-muted '>Nationality</label>

                          <div className='col-lg-3 fv-row '>
                            <span className='fw-bold fs-6'>
                              {userDetails.nationality ? userDetails.nationality : '-'}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-2 fw-bold text-muted '>Interest</label>

                          <div className='col-lg-4 '>
                            <span className='fw-bolder fs-6 text-dark'>
                              {userDetails.event_categories ? userDetails.event_categories : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-2'>
                      <div className='d-flex '>
                        <label className='fw-bolder fs-6 text-dark'>Mark user as VIP</label>
                        <input
                          type='checkbox'
                          id='statusCheckbox'
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className='me-3 mx-3'
                        />
                      </div>
                      <div className='my-2'>
                        <span
                          className={
                            userDetails.status === 'Active' ? 'text-success' : 'text-danger'
                          }
                        >
                          {userDetails.status}
                        </span>
                      </div>
                      <div className='d-flex '>
                        <label className='fw-bolder fs-6 text-dark'>
                          {(userDetails.is_artist?.status === 'Approved' &&
                            userDetails.is_collaborator?.status === 'Approved' &&
                            'Artist & Collaborator') ||
                            (userDetails.is_artist?.status === 'Approved' && 'Artist') ||
                            (userDetails.is_collaborator?.status === 'Approved' && 'Collaborator')}
                        </label>
                      </div>
                    </div>
                  </>
                )}
                            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  {/* Display rejection reason if user is not active */}
                  {userDetails?.status === 'Inactive' ? 
                    <div className='text-danger fw-bold mx-9'>
                      Rejected Reason: {userDetails.reason}
                    </div> :''
                  }
                </div>
              </div>
            </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/users/user-details/paid-event' && 'active')
                  }
                  to='/users/user-details/paid-event'
                  state={{userId: userId}}
                >
                  Paid Event
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/users/user-details/approval-needed-event' && 'active')
                  }
                  to='/users/user-details/approval-needed-event'
                  state={{userId: userId}}
                >
                  Approval Needed Event
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetailView
