import {FC, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getAuthorizationToken} from '../../../../authorization-token'
import ReactQuill from 'react-quill'

const aboutUsSchema = Yup.object().shape({
  Content: Yup.string().required('Content is required'),
})

const EditAboutus: FC = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [descriptionId, setDescriptionId] = useState('')
  const [descriptionContent, setDescriptionContent] = useState('')
  const [isContentChanged, setIsContentChanged] = useState(false)
  const navigate = useNavigate()

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAboutUs`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.json()
        } else {
          throw new Error('Failed to fetch description')
        }
      })
      .then((resp) => {
        setDescriptionId(resp.id)
        setDescriptionContent(resp.description)
        // Update the formik initial values once the data is fetched
        formik.setValues({
          id: resp.id,
          Content: resp.description.replaceAll('rgb(255, 255, 255)', 'rgb(152, 153, 152)'),
        })
      })
      .catch((error) => {
        console.error('Error fetching description:', error)
      })
  }, [])

  const initialValues = {
    id: descriptionId,
    Content: descriptionContent,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: aboutUsSchema,
    onSubmit: (values) => {
      setLoading(true)
      // console.log("Values", values)

      const content =
        `<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
            <div style="font-family: Poppins;color: #FFFFFF; text-align: left; font-size: 37px; padding: 0 1em;">
                ${values.Content}
            </div>`
          .replaceAll('class="ql-align-center"', "style='text-align: center'")
          .replaceAll('class="ql-align-right"', "style='text-align: right'")
          .replaceAll('style=\"color: rgb(152, 153, 152);\"', 'style=\"color: rgb(255, 255, 255);\"')

      const payload = {
        id: values.id,
        description: content,
      }
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/updateInfo`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify(payload),
      }).then((result) => {
        if (result.status === 200) {
          // Navigate to the /settings/aboutUs page
          navigate('/settings/aboutUs')
        } else {
          console.log('Error', result)
        }
      })
    },
  })

  useEffect(() => {
    const isChanged = formik.dirty && formik.values.Content !== initialValues.Content
    setIsContentChanged(isChanged)
  }, [formik.dirty, formik.values.Content, initialValues.Content])

  return (
    <div>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0 d-flex align-items-center'>
          <Link
            to='/settings/aboutUs'
            className='btn btn-sm btn-icon btn-active-color-primary text-end'
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <h3 className='fw-bolder m-0'>About Us</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          {/* <form noValidate className='form'> */}
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              {/* <label className='col-lg-4 col-form-label required fw-bold fs-6'>Staff Name</label> */}
              <div className='col-lg'>
                <div className='row'>
                  <div className='col-lg fv-row'>
                    <ReactQuill
                      value={formik.values.Content}
                      onChange={(value) => formik.setFieldValue('Content', value)}
                      placeholder='Content'
                      modules={{
                        toolbar: [
                          [{header: [1, 2, 3, 4, 5, 6, false]}],
                          ['bold', 'italic', 'underline'],
                          [{color: ['#989998', '#EF665C']}],
                          [{list: 'ordered'}, {list: 'bullet'}],
                          [{align: []}],
                          ['link'],
                        ],
                      }}
                    />
                    {formik.touched.Content && formik.errors.Content && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Content}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link
              to='/settings/aboutUs'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </Link>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={!isContentChanged || loading}
            >
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditAboutus
