import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import ReviewedUsers from './Free-Events-Users/ReviewedUsers'
import RequestedUsers from './Free-Events-Users/RequestedUsers'
import {getAuthorizationToken} from '../../../authorization-token'
import {KTIcon} from '../../../_metronic/helpers'
import {formatDate, formatDateWithTime} from '../../../datetime.formate'
import PaginationComponent from '../../../pagination'
import PlaceHolderComponent from '../../../skeletol-view'

interface BookingDetailsState {
  eventId: number // Adjust the type of eventId if needed
}

function BookingDetails() {
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  interface BookingDetails {
    ticket_category: string
    total_limit: number
    total_purchased_qty: number
    total_redeemed_qty: number
    available_tickets: number
    price: number
  }

  interface TicketDetail {
    ticket_buy: number
    ticket_category: string
    no_of_tickets_redeemed: number
  }

  interface BookingUser {
    id: number
    user_name: string
    date_created: string
    total_tickets: string
    tickets_detail: TicketDetail[]
    no_of_males: string
    no_of_females: string
    amount_paid: number | null
    table_booked: number
  }

  const location = useLocation()
  const {eventId} = location.state as BookingDetailsState
  // console.log('Event Id :', eventId)
  const [isPopupOpen, setPopupOpen] = useState(false)

  const openPopup = (bookingId: number) => {
    fetchPerBookingUserDetails(bookingId)
    fetchPerBookingTickets(bookingId)
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  const [bookingDetails, setBookingDetails] = useState<BookingDetails[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchBookingDetails = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/bookingTicketDetails/${eventId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setBookingDetails(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching Booking details:', error)
      })
  }

  const [bookingUsers, setBookingUsers] = useState<BookingUser[]>([])
  const [loading, setLoading] = useState(true)

  const fetchDataForNonFreeEvent = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/bookingUsersList/${eventId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('Non-Free Event Data:', data);
        setLoading(false)
        setBookingUsers(data)
      })
      .catch((error) => {
        console.error('Error fetching non-free event details:', error)
      })
  }

  interface PerBookingUser {
    id: number
    user_name: string
    date_created: string
    total_tickets: string
    tickets_detail: string
    amount_paid: number | null
  }

  const [perBookingDetails, setPerBookingDetails] = useState<PerBookingUser | null>(null)

  const fetchPerBookingUserDetails = (booking_id: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/perBookingUserDetails/${booking_id}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setPerBookingDetails(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  interface PerBookingTickets {
    id: number
    ticket_category: string
    user_name: string
    gender: string
    date_complimentary_redeemed: string
    staff_name: string
    redeemed: string
  }

  const [perBookingTickets, setPerBookingTickets] = useState<PerBookingTickets[]>([])

  const fetchPerBookingTickets = (booking_id: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/perBookingTicketDetails/${booking_id}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setPerBookingTickets(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // Extract unique ticket categories from bookingUsers
  // const ticketCategories: string[] = Array.from(
  //   new Set(
  //     bookingUsers.flatMap((bookingUser) =>
  //       bookingUser.tickets_detail.map((ticketDetail) => ticketDetail.ticket_category)
  //     )
  //   )
  // );

  const [currentView, setCurrentView] = useState<'reviewed' | 'requested'>('reviewed')

  useEffect(() => {
    fetchBookingDetails()
  }, [eventId])

  useEffect(() => {
    if (bookingDetails.some((event) => event.ticket_category !== 'Free')) {
      fetchDataForNonFreeEvent()
    }
  }, [bookingDetails])

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10 // Number of items to display per page

  const filteredData = bookingUsers.sort((a, b) => {
    if (selectedSortBY === 'dateNewest') {
      // Sort by newest first based on event date
      return new Date(a.date_created).getTime() - new Date(b.date_created).getTime()
    }
    if (selectedSortBY === 'dateOldest') {
      // Sort by oldest first based on event date
      return new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
    }
    if (selectedSortBY === 'amountHighest') {
      // Sort by highest booked tickets first
      return (b.amount_paid ?? 0) - (a.amount_paid ?? 0)
    }
    if (selectedSortBY === 'amountLowest') {
      // Sort by lowest booked tickets first
      return (a.amount_paid ?? 0) - (b.amount_paid ?? 0)
    }

    // Default sorting (no sorting)
    return 0
  })

  const indexOfLastData = currentPage * usersPerPage
  const indexOfFirstData = indexOfLastData - usersPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / usersPerPage)
  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1)
  return (
    <>
      <div className='row mb-8'>
        <strong className='col-lg-2 fw-bold fs-4' style={{marginTop: '15px'}}>
          Ticket Details
        </strong>

        <div className='col-lg-7'>
          <div className='fw-bold'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-bordered align-middle gs-0'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <td className='min-w-100px text-muted text-center'>Ticket Category</td>
                    <td className='min-w-100px text-muted text-center'>Revealed</td>
                    <td className='min-w-100px text-muted text-center'>Purchased</td>
                    <td className='min-w-100px text-muted text-center'>Redeemed</td>
                    <td className='min-w-100px text-muted text-center'>Available</td>
                    <td className='min-w-100px text-muted text-center'>Price / Ticket</td>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {bookingDetails.map((event) => (
                    <>
                      <tr key={event.ticket_category}>
                        <td className='text-center'>
                          <a className='min-w-100px text-muted text-center'>
                            {event.ticket_category === 'Free' ? 'Guest List' : event.ticket_category}
                          </a>
                        </td>
                        <td className='text-center'>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {event.total_limit}
                          </a>
                        </td>
                        <td className='text-center'>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {event.total_purchased_qty}
                          </a>
                        </td>
                        <td className='text-center'>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {event.total_redeemed_qty}
                          </a>
                        </td>
                        <td className='text-center'>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {event.available_tickets}
                          </a>
                        </td>
                        <td className='text-center'>
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {event.price}
                          </a>
                        </td>
                      </tr>
                    </>
                  ))}

                  {/* ... Repeat for other rows ... */}
                </tbody>
                <tbody>
                  <tr>
                    <td className='text-center'>
                      <a className='min-w-100px text-muted text-center'>Total</a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {bookingDetails.reduce((total, event) => total + event.total_limit, 0)}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {bookingDetails.reduce(
                          (total, event) => total + event.total_purchased_qty,
                          0
                        )}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {bookingDetails.reduce(
                          (total, event) => total + event.total_redeemed_qty,
                          0
                        )}
                      </a>
                    </td>
                    <td className='text-center'>
                      {bookingDetails.reduce((total, event) => total + event.available_tickets, 0)}
                    </td>
                    {/* <td className='text-center'>
                      {bookingDetails.reduce((total, event) => total + event.available_tickets, 0)}
                    </td> */}
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>

              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        </div>
      </div>

      <div className='card-body py-3'>
        {/* If Its paid Event */}
        {bookingDetails.some((event) => event.ticket_category !== 'Free') && (
          <div>
            <div className='card-toolbar'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative w-350px my-1 ms-auto'>
                <select
                  className='form-select form-select-solid form-select-lg-2'
                  value={selectedSortBY || ''}
                  onChange={(e) => {
                    setSelectedSortBY(e.target.value)
                  }}
                >
                  {/*      */}
                  <option value=''>Sort By</option>
                  <option value='amountHighest'>Amount Highest First</option>
                  <option value='amountLowest'>Amount Lowest First</option>
                  <option value='dateNewest'>Purchase Date Newest First</option>
                  <option value='dateOldest'>Purchase Date Oldest First</option>
                </select>
              </div>
              {/* end::Search */}
            </div>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-100px'>User Name</th>
                    <th className='min-w-100px'>Purchased Date</th>
                    <th className='min-w-100px text-center'>Total</th>
                    <th className='min-w-100px text-center'>Gold</th>
                    <th className='min-w-100px'>Redeemed</th>
                    <th className='min-w-100px text-center'>Standard</th>
                    <th className='min-w-100px'>Redeemed</th>
                    <th className='min-w-100px text-center'>Male</th>
                    <th className='min-w-100px'>Female</th>
                    <th className='min-w-100px'>Book a table</th>
                    <th className='min-w-100px'>Amount</th>
                    <th className='min-w-125px text-center rounded-end'>Action</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                {/* begin::Table body */}
                <tbody>
                  {loading && <PlaceHolderComponent tableHeaders={11} />}
                  {currentData.map((bookingUser, index) => (
                    <tr key={index}>
                      <td className='align-middle'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.user_name}
                        </a>
                      </td>
                      <td className='align-middle'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                          {formatDateWithTime(bookingUser.date_created)}
                        </a>
                      </td>
                      <td className='align-middle text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.total_tickets}
                        </a>
                      </td>
                      <td className='text-center align-middle'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.tickets_detail.find(
                            (ticket) => ticket.ticket_category === 'Gold'
                          )?.ticket_buy || 0}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.tickets_detail.find(
                            (ticket) => ticket.ticket_category === 'Gold'
                          )?.no_of_tickets_redeemed || 0}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.tickets_detail.find(
                            (ticket) => ticket.ticket_category === 'Standard'
                          )?.ticket_buy || 0}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.tickets_detail.find(
                            (ticket) => ticket.ticket_category === 'Standard'
                          )?.no_of_tickets_redeemed || 0}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.no_of_males}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.no_of_females}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.table_booked === 1 ? 'Yes' : 'No'}
                        </a>
                      </td>
                      <td>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {bookingUser.amount_paid ? `QR ${bookingUser.amount_paid}` : 'QR 0'}
                        </a>
                      </td>
                      <td className='text-center'>
                        <a
                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                          onClick={() => openPopup(bookingUser.id)}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {totalPages > 1 && (
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                // pageNumbers={pageNumbers}
                currentData={currentData}
                filteredData={filteredData}
                indexOfFirstData={indexOfFirstData}
                indexOfLastData={indexOfLastData}
              />
            )}
          </div>
        )}

        {isPopupOpen && (
          <div className={`modal fade show d-block`} role='dialog' tabIndex={-1} aria-modal='true'>
            <div
              className='modal-dialog modal-dialog-centered'
              style={{maxWidth: '800px', margin: 'auto'}}
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  {perBookingDetails && (
                    <div className='modal-body'>
                      <div style={{display: 'flex', marginBottom: '0px'}}>
                        <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                          Owner Name
                        </p>
                        <p
                          className='fw-bold text-muted'
                          style={{marginLeft: '110px', display: 'inline'}}
                        >
                          {perBookingDetails.user_name}
                        </p>
                      </div>

                      <div style={{display: 'flex', marginBottom: '0px'}}>
                        <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                          Purchase Date
                        </p>
                        <p
                          className='fw-bold text-muted'
                          style={{marginLeft: '98px', display: 'inline'}}
                        >
                          {formatDateWithTime(perBookingDetails.date_created)}
                        </p>
                      </div>

                      <div style={{display: 'flex', marginBottom: '0px'}}>
                        <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                          Amount
                        </p>
                        <p
                          className='fw-bold text-muted'
                          style={{marginLeft: '140px', display: 'inline'}}
                        >
                          QR {perBookingDetails.amount_paid ? perBookingDetails.amount_paid : 0}
                        </p>
                      </div>

                      <div style={{display: 'flex', marginBottom: '-35px'}}>
                        <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                          Tickets
                        </p>
                        <p
                          className='fw-bold text-muted'
                          style={{marginLeft: '144px', display: 'inline'}}
                        >
                          {perBookingDetails.tickets_detail}
                        </p>
                      </div>
                    </div>
                  )}

                  <button
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    onClick={closePopup}
                    style={{cursor: 'pointer', marginTop: '-140px', marginRight: '-5px'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='table-responsive' style={{maxHeight: '240px', overflowY: 'auto'}}>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4 mx-auto'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted bg-light'>
                          <th className='min-w-auto text-center'>Ticket Number</th>
                          <th className='min-w-auto text-center'>User</th>
                          <th className='min-w-auto text-center'>Gender</th>
                          <th className='min-w-auto text-center'>Ticket Category</th>
                          <th className='min-w-auto text-center'>Redeemed</th>
                          <th className='min-w-auto text-center'>Date & Time</th>
                          <th className='min-w-auto text-center'>Redeemed By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {perBookingTickets.length === 0 && (
                          <PlaceHolderComponent tableHeaders={7} />
                        )}
                        {perBookingTickets.map((ticket) => (
                          <tr key={ticket.id}>
                            <td className='align-middle text-center'>{ticket.id}</td>
                            <td className='align-middle text-center'>
                              {ticket.user_name ? ticket.user_name : '-'}
                            </td>
                            <td className='align-middle text-center'>
                              {ticket.gender === 'M'
                                ? 'Male'
                                : ticket.gender === 'F'
                                ? 'Female'
                                : '-'}
                            </td>
                            <td className='align-middle text-center'>
                              {ticket.ticket_category ? ticket.ticket_category : '-'}
                            </td>
                            <td className='align-middle text-center'>{ticket.redeemed}</td>
                            <td className='align-middle text-center'>
                              {ticket.date_complimentary_redeemed
                                ? formatDateWithTime(ticket.date_complimentary_redeemed)
                                : '-'}
                            </td>
                            <td className='align-middle text-center'>
                              {ticket.staff_name ? ticket.staff_name : '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* If it is a Free Event */}
        {bookingDetails.some((event) => event.ticket_category === 'Free') && (
          <>
            {/* Buttons to switch between Reviewed Users and Requested Users */}
            <div className='mb-3'>
              <button
                className={`btn btn-link text-active-primary me-6 btn-lg ${
                  currentView === 'reviewed' ? 'active' : ''
                }`}
                onClick={() => setCurrentView('reviewed')}
                style={{
                  borderBottom: currentView === 'reviewed' ? '2px solid #3699FF' : 'none',
                }}
              >
                Reviewed Users
              </button>
              <button
                className={`btn btn-link text-active-primary me-6 btn-lg ${
                  currentView === 'requested' ? 'active' : ''
                }`}
                onClick={() => setCurrentView('requested')}
                style={{
                  borderBottom: currentView === 'requested' ? '2px solid #3699FF' : 'none',
                }}
              >
                Requested Users
              </button>
            </div>

            {/* Conditional rendering of tables */}
            {currentView === 'reviewed' && <ReviewedUsers eventId={eventId} />}
            {currentView === 'requested' && <RequestedUsers eventId={eventId} />}
          </>
        )}
      </div>
      {/* Pagination */}
    </>
  )
}

export default BookingDetails
