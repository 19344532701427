
const PaginationComponent = ({
    currentPage,
    totalPages,
    setCurrentPage,
    // pageNumbers,
    currentData,
    filteredData,
    indexOfFirstData,
    indexOfLastData,
}) => {

    // Calculate the range of page numbers to display
    const pageRange = 6; // Maximum number of page numbers to display
    const leftPageBound = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const rightPageBound = Math.min(currentPage + Math.floor(pageRange / 2), totalPages);

    // Generate the page numbers within the range
    const pageNumbersInRange = Array.from({ length: rightPageBound - leftPageBound + 1 }, (_, index) => leftPageBound + index);

    // Add ellipsis if needed
    const pageNumbers: any = [];
    if (leftPageBound > 1) {
        pageNumbers.push(1);
        if (leftPageBound > 2) {
            pageNumbers.push('...'); // Add ellipsis
        }
    }

    pageNumbers.push(...pageNumbersInRange);

    if (rightPageBound < totalPages) {
        if (rightPageBound < totalPages - 1) {
            pageNumbers.push('...'); // Add ellipsis
        }
        pageNumbers.push(totalPages);
    }

    return (
        <div className="d-flex flex-stack flex-wrap pt-10">
            <div className='fs-6 fw-bold text-gray-700'>
                Showing {indexOfFirstData + 1} to {Math.min(indexOfLastData, filteredData.length)} of {filteredData.length} entries
            </div>
            <ul className="pagination">
                {/* Previous Page Arrow */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a
                        className="page-link cursor-pointer"
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>

                {/* Page Numbers */}
                {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''} `}>
                        <a
                            className={`page-link cursor-pointer ${number === '...' ? 'disabled' : ''}`}
                            onClick={() => setCurrentPage(number)}
                            
                        >
                            {number}
                        </a>
                    </li>
                ))}

                {/* Next Page Arrow */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a
                        className="page-link cursor-pointer"
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default PaginationComponent;
