// Importing necessary libraries and functions
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { KTIcon } from "../../../../_metronic/helpers";
import { getAuthorizationToken } from "../../../../authorization-token";

// Defining validation schema for the form using Yup
const faqSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
})

// Functional component for adding FAQs
const AddFAQs: FC = () => {

    // Fetching authorization token
    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    // Hook for navigation
    const navigate = useNavigate();

    // State for loading status
    const [loading, setLoading] = useState(false)

    // Formik setup for form handling
    const formik = useFormik({
        initialValues: {
            question: '',
            answer: '',
        },
        validationSchema: faqSchema,
        onSubmit: (values) => {
            // Preparing data for API call
            const formData = {
                question: values.question,
                answer: values.answer
            };

            // API call to add FAQ
            fetch(`${process.env.REACT_APP_API_BASE_URL}/addFAQ`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${REACT_APP_AUTHORIZATION_TOKEN}`
                },
                body: JSON.stringify(formData),
            }).then((result) => {
                if (result.status === 200) {
                    // On successful addition, navigate to FAQs page
                    setLoading(true);
                    navigate('/settings/fAQs');
                } else {
                    // On failure, log the error and set loading to true
                    setLoading(true);
                    console.log("Error", result);
                }
            })
        },
    })

    // Render form for adding FAQ
    return (
        // Use the TextArea component in the form
        <>
            <div className='card-title m-0 d-flex align-items-center justify-content-between'>
                <h3 className='card-title align-items-start flex-column'>
                    <Link
                        to='/settings/fAQs'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end '
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <span className='card-label fw-bold fs-2 mb-1'>Add FAQ</span>
                </h3>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <TextArea label='Question' rows={1} name='question' formik={formik} />
                    </div>

                    <div className='card-body border-top p-9'>
                        <TextArea label='Answer' rows={8} name='answer' formik={formik} />
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/settings/fAQs'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Add'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

// Component for the text area
const TextArea = ({ label, rows, name, formik }) => (
    <div className='row mb-6'>
        <label htmlFor={name} className='col-lg-9 col-form-label required fw-bold fs-6'>{label}</label>
        <div className='col-lg-12'>
            <div className='row'>
                <textarea
                    id={name}
                    rows={rows}
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='   '
                    {...formik.getFieldProps(name)}
                />
                {formik.touched[name] && formik.errors[name] && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors[name]}</div>
                    </div>
                )}
            </div>
        </div>
    </div>
)

export default AddFAQs;
