import {FC, useEffect, useState} from 'react'
import {formatDate, formatDateWithTime} from '../../../../datetime.formate'
import {getAuthorizationToken} from '../../../../authorization-token'
import {KTIcon} from '../../../../_metronic/helpers'
import PaginationComponent from '../../../../pagination'
import PlaceHolderComponent from '../../../../skeletol-view'

const ReviewedUsers: FC<{eventId: number}> = ({eventId}) => {
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  // console.log(eventId)
  interface Users {
    booking_id: number
    user_name: string
    reviewed_date: string
    requested_date: string
    total_tickets: number
    table_booked: number
    status: string
  }

  const [reviewedUsers, setReviewedUsers] = useState<Users[]>([])
  const [loading, setLoading] = useState(true)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchReviewedUsers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getReviewedUsers/${eventId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp);
          setLoading(false)
          setReviewedUsers(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching paid event data:', error)
      })
  }

  useEffect(() => {
    fetchReviewedUsers()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10 // Number of items to display per page

  const filteredData = reviewedUsers.sort((a, b) => {
    if (selectedSortBY === 'dateNewest') {
      // Sort by newest first based on event date
      return new Date(a.requested_date).getTime() - new Date(b.requested_date).getTime()
    }
    if (selectedSortBY === 'dateOldest') {
      // Sort by oldest first based on event date
      return new Date(b.requested_date).getTime() - new Date(a.requested_date).getTime()
    }
    if (selectedSortBY === 'ticketsHighest') {
      // Sort by highest booked tickets first
      return b.total_tickets - a.total_tickets
    }
    if (selectedSortBY === 'ticketsLowest') {
      // Sort by lowest booked tickets first
      return a.total_tickets - b.total_tickets
    }

    // Default sorting (no sorting)
    return 0
  })

  const indexOfLastData = currentPage * usersPerPage
  const indexOfFirstData = indexOfLastData - usersPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / usersPerPage)
  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1)

  const [isPopupOpen, setPopupOpen] = useState(false)

  const openPopup = (bookingId: number) => {
    fetchPerBookingUserDetails(bookingId)
    fetchPerBookingTickets(bookingId)
    setPopupOpen(true)
    setLoading(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  interface PerBookingUser {
    id: number
    user_name: string
    date_created: string
    total_tickets: string
    tickets_detail: string
    amount_paid: string | null
  }

  const [perBookingDetails, setPerBookingDetails] = useState<PerBookingUser | null>(null)

  const fetchPerBookingUserDetails = (booking_id: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/perBookingUserDetails/${booking_id}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setPerBookingDetails(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  interface PerBookingTickets {
    id: number
    ticket_category: string
    user_name: string
    gender: string
    date_complimentary_redeemed: string
    staff_name: string
    redeemed: string
  }

  const [perBookingTickets, setPerBookingTickets] = useState<PerBookingTickets[]>([])

  const fetchPerBookingTickets = (booking_id: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/perBookingTicketDetails/${booking_id}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setLoading(false)
          setPerBookingTickets(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  return (
    <>
      <div className='card-header cursor-pointer text-end'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative w-350px my-1 ms-auto'>
          <select
            className='form-select form-select-solid form-select-lg-2 ms-auto'
            value={selectedSortBY || ''}
            onChange={(e) => {
              setSelectedSortBY(e.target.value)
            }}
          >
            {/*      */}
            <option value=''>Sort By</option>
            <option value='ticketsHighest'>Total Tickets Highest First</option>
            <option value='ticketsLowest'>Total Tickets Lowest First</option>
            <option value='dateNewest'>Requested Date Newest First</option>
            <option value='dateOldest'>Requested Date Oldest First</option>
          </select>
        </div>
        {/* end::Search */}
      </div>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='min-w-100px'>User Name</th>
              <th className='min-w-100px text-center'>Requested Date</th>
              <th className='min-w-100px text-center'>Reviewed Date</th>
              <th className='min-w-100px text-center'>Total Tickets</th>
              <th className='min-w-100px text-center'>Book a table</th>
              <th className='min-w-100px text-center'>Status</th>
              <th className='min-w-100px text-center'>Action</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {loading && <PlaceHolderComponent tableHeaders={6} />}
            {currentData.map((event) => (
              <tr key={event.booking_id}>
                <td className='align-middle'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {event.user_name}
                  </a>
                </td>
                <td className='align-middle text-center'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                    {formatDate(event.requested_date)}
                  </a>
                </td>
                <td className='align-middle text-center'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {formatDate(event.reviewed_date)}
                  </a>
                </td>
                <td className='text-center align-middle'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {event.total_tickets}
                  </a>
                </td>
                <td className='text-center align-middle'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {event.table_booked === 1 ? 'Yes' : 'No'}
                  </a>
                </td>
                <td className='text-center align-middle'>
                  <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {event.status}
                  </a>
                </td>
                <td className='text-center'>
                  <a
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                    onClick={() => openPopup(event.booking_id)}
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {isPopupOpen && (
        <div className={`modal fade show d-block`} role='dialog' tabIndex={-1} aria-modal='true'>
          <div
            className='modal-dialog modal-dialog-centered'
            style={{maxWidth: '800px', margin: 'auto'}}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                {perBookingDetails && (
                  <div className='modal-body'>
                    <div style={{display: 'flex', marginBottom: '0px'}}>
                      <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                        Owner Name
                      </p>
                      <p
                        className='fw-bold text-muted'
                        style={{marginLeft: '110px', display: 'inline'}}
                      >
                        {perBookingDetails.user_name}
                      </p>
                    </div>

                    <div style={{display: 'flex', marginBottom: '0px'}}>
                      <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                        Purchase Date
                      </p>
                      <p
                        className='fw-bold text-muted'
                        style={{marginLeft: '98px', display: 'inline'}}
                      >
                        {formatDateWithTime(perBookingDetails.date_created)}
                      </p>
                    </div>

                    <div style={{display: 'flex', marginBottom: '0px'}}>
                      <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                        Amount
                      </p>
                      <p
                        className='fw-bold text-muted'
                        style={{marginLeft: '140px', display: 'inline'}}
                      >
                        {perBookingDetails.amount_paid ? (perBookingDetails.amount_paid !== '0.00' ? perBookingDetails.amount_paid : 'Guest List') : 'Guest List'}
                      </p>
                    </div>

                    <div style={{display: 'flex', marginBottom: '-35px'}}>
                      <p className='fw-bolder' style={{marginRight: '5px', display: 'inline'}}>
                        Tickets
                      </p>
                      <p
                        className='fw-bold text-muted'
                        style={{marginLeft: '144px', display: 'inline'}}
                      >
                        {perBookingDetails.tickets_detail}
                      </p>
                    </div>
                  </div>
                )}

                <button
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  onClick={closePopup}
                  style={{cursor: 'pointer', marginTop: '-140px', marginRight: '-5px'}}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </button>
              </div>
              <div className='modal-body'>
                <div className='table-responsive' style={{maxHeight: '240px', overflowY: 'auto'}}>
                  {/* begin::Table */}
                  <table className='table align-middle gs-0 gy-4 mx-auto'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-auto text-center'>Ticket Number</th>
                        <th className='min-w-auto text-center'>User</th>
                        <th className='min-w-auto text-center'>Gender</th>
                        <th className='min-w-auto text-center'>Ticket Category</th>
                        <th className='min-w-auto text-center'>Redeemed</th>
                        <th className='min-w-auto text-center'>Date & Time</th>
                        <th className='min-w-auto text-center'>Redeemed By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && <PlaceHolderComponent tableHeaders={7} />}
                      {perBookingTickets.map((ticket) => (
                        <tr key={ticket.id}>
                          <td className='align-middle text-center'>{ticket.id}</td>
                          <td className='align-middle text-center'>
                            {ticket.user_name ? ticket.user_name : '-'}
                          </td>
                          <td className='align-middle text-center'>
                            {ticket.gender === 'M'
                              ? 'Male'
                              : ticket.gender === 'F'
                              ? 'Female'
                              : '-'}
                          </td>
                          <td className='align-middle text-center'>
                            {ticket.ticket_category ? (ticket.ticket_category === "Free" ? "Guest List": ticket.ticket_category) : '-'}
                          </td>
                          <td className='align-middle text-center'>{ticket.redeemed}</td>
                          <td className='align-middle text-center'>
                            {ticket.date_complimentary_redeemed
                              ? formatDateWithTime(ticket.date_complimentary_redeemed)
                              : '-'}
                          </td>
                          <td className='align-middle text-center'>
                            {ticket.staff_name ? ticket.staff_name : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          // pageNumbers={pageNumbers}
          currentData={currentData}
          filteredData={filteredData}
          indexOfFirstData={indexOfFirstData}
          indexOfLastData={indexOfLastData}
        />
      )}
    </>
  )
}

export default ReviewedUsers
