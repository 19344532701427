import {
  KTCard,
  KTCardBody,
  KTIcon,
  initialQueryState,
  useDebounce,
} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {useLocation} from 'react-router-dom'
import {getAuthorizationToken} from '../../../authorization-token'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'

interface RequestDetailType {
  art: string[]
  level: string

  category: string[]
  type: string

  status: string
  request_date: string
  portfolio_link: string
  portfolio_pdf: string
}

interface State {
  id: number
  userName: string
  request: string
}

const rejectSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is Required'),
})

const RequestDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as State
  const userId = state.id
  // console.log('request Details userId', userId)
  const userName = state.userName
  const request = state.request
  const [ApproveModal, setApproveModal] = useState(false)
  const [RejectModal, setRejectModal] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState<RequestDetailType>()

  const openApproveModal = () => {
    setApproveModal(true)
  }
  const closeModal = () => {
    setApproveModal(false)
    setRejectModal(false)
  }
  const openRejectModal = () => {
    setRejectModal(true)
  }

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get ALL User List
  const fetchUserData = () => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/userRequestDetails?userId=${userId}&request_type=${request}`,
      {
        headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
      }
    )
      .then((result) => {
        result.json().then((resp) => {
          setDataLoading(false)
          setUserData(request === 'Artist' ? resp.is_artist : resp.is_collaborator)
        })
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }

  // For Get ALL User List When page is load
  useEffect(() => {
    fetchUserData()
  }, [])

  const approveRequest = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatusUserRequest`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({userId: userId, request_type: request, status: 'Approved'}),
    }).then((result) => {
      if (result.status === 200) {
        setLoading(false)
        navigate('/user-request')
      } else if (result.status === 400) {
        setLoading(false)
      } else {
        setLoading(false)
        console.log('Error', result)
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: rejectSchema,
    onSubmit: (values) => {
      rejectRequest(values.reason)
    },
  })

  function rejectRequest(reason: string) {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatusUserRequest`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({
        userId: userId,
        request_type: request,
        status: 'Rejected',
        reason: reason,
      }),
    }).then((result) => {
      if (result.status === 200) {
        setLoading(false)
        navigate('/user-request')
      } else if (result.status === 400) {
        setLoading(false)
      } else {
        setLoading(false)
        console.log('Error', result)
      }
    })
  }

  return (
    <div className='my-3'>
      <div className='d-grid gap-2'>
        <Navbar
          className=''
          style={{backgroundColor: 'white', outline: '1px solid #E5E5E6', borderRadius: '6px'}}
        >
          <Container>
            <Link to='/user-request' className='btn btn-sm btn-icon btn-active-color-primary '>
              <KTIcon iconName='arrow-left' />
            </Link>
            <Navbar.Brand>
              <h2>
                <b>Request Details</b>
              </h2>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className='justify-content-end mb-2'>
              <Navbar.Text>
                {!dataLoading && (
                  <Button className='mx-2' size='sm' variant='primary' onClick={openApproveModal}>
                    Approve
                  </Button>
                )}
              </Navbar.Text>
              <Navbar.Text>
                {!dataLoading && (
                  <Button size='sm' variant='danger' onClick={openRejectModal}>
                    Reject
                  </Button>
                )}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <>
          {ApproveModal && (
            <div
              className='modal fade show d-block'
              id='kt_modal_add_user'
              role='dialog'
              tabIndex={-1}
              aria-modal='true'
            >
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered mw-450px'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <div className='modal-header'>
                    {/* begin::Modal title */}
                    <h2 className='fw-bolder text-center'>Are You Sure Want to Approve ?</h2>
                    {/* end::Modal title */}

                    {/* begin::Close */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-danger'
                      data-kt-users-modal-action='close'
                      onClick={closeModal}
                      style={{cursor: 'pointer'}}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </div>
                    {/* end::Close */}
                  </div>
                  {/* begin::Modal body */}
                  <div className='scroll-y mx-5 mx-xl-15 my-5'>
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => closeModal()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Cancel
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        onClick={approveRequest}
                        disabled={loading}
                      >
                        <span className='indicator-label'>{loading ? 'Approving' : 'Approve'}</span>
                      </button>
                    </div>
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          )}
          {ApproveModal && <div className='modal-backdrop fade show'></div>}
        </>
        <>
          {RejectModal && (
            <div
              className='modal fade show d-block'
              id='kt_modal_reject'
              role='dialog'
              tabIndex={-1}
              aria-modal='true'
            >
              <div className='modal-dialog modal-dialog-centered mw-450px'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2 className='fw-bolder' style={{textAlign: 'center'}}>
                      Are you sure want to Reject?
                    </h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-danger'
                      onClick={closeModal}
                      style={{cursor: 'pointer'}}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </div>
                  </div>
                  <div className='scroll-y mx-5 mx-xl-15 my-7'>
                    <form onSubmit={formik.handleSubmit}>
                      <input
                        type='textarea'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Reason'
                        {...formik.getFieldProps('reason')}
                        name='reason'
                      />
                      {formik.touched.reason && formik.errors.reason && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.reason}</div>
                        </div>
                      )}
                      <div className='text-center pt-15'>
                        <button type='reset' onClick={closeModal} className='btn btn-light me-3'>
                          Cancel
                        </button>
                        <button type='submit' className='btn btn-danger' disabled={loading}>
                          {loading ? 'Rejecting' : 'Reject'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {RejectModal && <div className='modal-backdrop fade show'></div>}
        </>
      </div>

      <>
        <div className='row mb-8 my-7 mx-7'>
          <label className='col-lg-4 fw-bold text-muted'>User Name</label>

          <div className='col-lg-8 fv-row'>
            <Link
              to='/user-request/request-details/user-details/paid-event'
              state={{userId: userId}}
              className='btn btn-sm btn-active-color-primary'
              style={{display: 'inline', padding: '0'}}
            >
              <span className='fw-bold fs-6'>{userName}</span>
              <KTIcon iconName='social-media' className='ms-2' />
            </Link>
          </div>
        </div>
        <br />
        <br />
        <div className='row mb-8 my-7 mx-7'>
          <label className='col-lg-4 fw-bold text-muted'>Request</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {request === 'Artist' ? userData?.art : userData?.category}
            </span>
          </div>
        </div>
        <br />
        <br />
        <div className='row mb-7 my-5 mx-7'>
          <label className='col-lg-4 fw-bold text-muted'>Proficiency</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {request === 'Artist' ? userData?.level : userData?.type}
            </span>
          </div>
        </div>
        <br />
        <br />
        <div className='row mb-7 my-5 mx-7'>
          <label className='col-lg-4 fw-bold text-muted'>Portfolio</label>

          <div className='col-lg-8 fv-row'>
            <a
              href={userData?.portfolio_link ? userData.portfolio_link : userData?.portfolio_pdf}
              target='_blank'
              rel='noopener noreferrer'
              className='fw-bold fs-6'
            >
              {userData?.portfolio_link ? userData.portfolio_link : userData?.portfolio_pdf}
            </a>
          </div>
        </div>
      </>
    </div>
  )
}
export default RequestDetails
