import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, NavLink } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDate, formatDateWithTime } from '../../../datetime.formate'
import { getAuthorizationToken } from '../../../authorization-token'
import PaginationComponent from '../../../pagination'
import PlaceHolderComponent from '../../../skeletol-view'
// import { set } from 'lodash'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isBefore, parseISO } from 'date-fns'

interface AllCategory {
  id: number
  event_category: string
  status: string
}

const EventsWrapper = () => {
  interface Event {
    id: number
    date_created: string
    event_datetime: string[]
    name: string
    location: string
    gender: string
    event_category: string
    ticketLimits: string[]
    ticketPurchased: string[]
  }

  const [eventData, setEventData] = useState<Event[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  const [allCategory, setAllCategory] = useState<AllCategory[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get ALL Event List
  const fetchEventData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allEvents`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          setLoading(false)
          setEventData(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
      })
  }

  // Get All Category List
  const fetchAllCategory = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          setAllCategory(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching all category data:', error)
      })
  }

  useEffect(() => {
    fetchEventData()
    fetchAllCategory()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const eventsPerPage = 10

  // Get the events to display on the current page
  // For filtration by search
  const filteredData = eventData
    .filter((event) => {
      const currentDate = new Date()

      return (
        (event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!selectedGenderFilter ||
          selectedGenderFilter === 'Any' ||
          event.gender === selectedGenderFilter) &&
        (!selectedCategory ||
          selectedCategory === 'A' ||
          event.event_category === selectedCategory) &&
        (!searchDate ||
          event.event_datetime.some((date) => {
            const eventDate = new Date(date)
            const selectedDate = new Date(searchDate)
            return eventDate.toDateString() === selectedDate.toDateString()
          })) &&
        (!selectedStatus ||
          (selectedStatus === 'completed' &&
            event.event_datetime.some((datetime) => new Date(datetime + ' UTC') <= currentDate)) ||
          (selectedStatus === 'upcoming' &&
            event.event_datetime.some((datetime) => new Date(datetime + ' UTC') > currentDate))) &&
        (!selectedSortBY ||
          (selectedSortBY === 'dateNewest' &&
            event.event_datetime.some((datetime) => new Date(datetime + ' UTC') >= currentDate)) ||
          (selectedSortBY === 'dateOldest' &&
            event.event_datetime.some((datetime) => new Date(datetime + ' UTC') > currentDate)))
      )
    })
    .sort((a, b) => {
      // Compare event dates for sorting
      const dateA: Date = new Date(a.event_datetime[0])
      const dateB: Date = new Date(b.event_datetime[0])

      if (selectedSortBY === 'dateNewest') {
        // Sort by newest first
        return dateA.getTime() - dateB.getTime()
      }
      if (selectedSortBY === 'dateOldest') {
        // Sort by oldest first
        return dateB.getTime() - dateA.getTime()
      }

      // Default sorting (no sorting)
      return 0
    })

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1)
  }, [
    searchTerm,
    selectedGenderFilter,
    selectedCategory,
    searchDate,
    selectedStatus,
    selectedSortBY,
  ])

  const indexOfLastData = currentPage * eventsPerPage
  const indexOfFirstData = indexOfLastData - eventsPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / eventsPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1)

  const [isDeleteEventModalOpen, setDeleteEventModalOpen] = useState(false)
  const [deleteEvent, setDeleteEvent] = useState<Event>({} as Event)

  // Open Delete Modal
  const openEventDeleteModal = (event: Event) => {
    setDeleteEvent(event)
    setDeleteEventModalOpen(true)
  }

  // Close Delete Modal
  const closeEventDeleteModal = () => {
    setDeleteEventModalOpen(false)
  }
  const [deleteLoading, setDeleteLoading] = useState(false)

  // Delete Event
  const eventDelete = (eventId: number) => {
    setDeleteLoading(true)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteEvent/${eventId}`, {
      method: 'DELETE',
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log(resp)
          setDeleteLoading(false)
          setDeleteEventModalOpen(false)
          fetchEventData()
          if (resp.success) {
            toast.success('Event has been successfully deleted')
          } else {
            toast.error(resp.errors[0].error)
          }
        })
      })
      .catch((error) => {
        setDeleteLoading(false)
        console.error('Error deleting event:', error)
        toast.error('Error deleting event')
      })
  }

  return (
    <div className='mb-5 mb-xl-8'>
      <ToastContainer />
      {/* begin::Header */}
      <div
        className='card-title m-0 d-flex align-items-center'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1'>Events</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
        </h3>
        <div className='card-toolbar '>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-300px ps-14'
              placeholder='Search by event name, location'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              type='date'
              className='form-control form-control-solid w-180px ps-14'
              placeholder='Date'
              value={searchDate}
              onChange={(e) => setSearchDate(e.target.value)}
            />

            <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedGenderFilter || ''}
              onChange={(e) => {
                setSelectedGenderFilter(e.target.value)
              }}
            >
              <option value='Any'>Gender</option>
              <option value='M'>Male</option>
              <option value='F'>Female</option>
              <option value='A'>Any</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedCategory || ''}
              onChange={(e) => {
                setSelectedCategory(e.target.value)
              }}
            >
              <option value=''>Category</option>
              {allCategory.map((event) => (
                <option key={event.id} value={event.event_category}>
                  {event.event_category}
                </option>
              ))}
            </select>
          </div>

          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedStatus || ''}
              onChange={(e) => {
                setSelectedStatus(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Status</option>
              <option value='upcoming'>Upcoming</option>
              <option value='completed'>Completed</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedSortBY || ''}
              onChange={(e) => {
                setSelectedSortBY(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Sort By</option>
              <option value='dateNewest'>Event Date Newest First</option>
              <option value='dateOldest'>Event Date Oldest First</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative my-1'>
            <Link
              to='/events/add-event'
              className='btn btn-primary btn-sm'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              Add
            </Link>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Created Date</th>
                <th className='min-w-100px'>Event Date</th>
                <th className='min-w-100px'>Event Name</th>
                <th className='min-w-100px'>Location</th>
                <th className='min-w-100px'>Gender</th>
                <th className='min-w-100px'>Category</th>
                <th className='min-w-100px'>Available Tickets</th>
                <th className='min-w-100px'>Booked Tickets</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-150px text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && <PlaceHolderComponent tableHeaders={10} />}
              {currentData.map((event) => (
                <tr key={event.id}>
                  <td>
                    <a className='text-dark fw-bold d-block mb-1 fs-6'>
                      {formatDate(event.date_created)}
                    </a>
                  </td>
                  <td>
                    {event.event_datetime.length === 1 ? (
                      <a className='text-dark fw-bold d-block mb-1 fs-6'>
                        {formatDateWithTime(event.event_datetime[0])}
                      </a>
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            {event.event_datetime.map((datetime) => (
                              <div key={datetime}>{formatDateWithTime(datetime)}</div>
                            ))}
                          </Tooltip>
                        }
                      >
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          Dates
                        </a>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    <a className='text-dark fw-bold d-block mb-1 fs-6'>{event.name}</a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold d-block mb-1 fs-6'>{event.location}</a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold d-block mb-1 fs-6'>
                      {event.gender === 'M' ? 'Male' : event.gender === 'F' ? 'Female' : 'Any'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold d-block mb-1 fs-6'>{event.event_category}</a>
                  </td>
                  <td>
                    {event.ticketLimits.length === 1 ? (
                      <a className='text-dark fw-bold d-block mb-1 fs-6'>{event.ticketLimits[0]}</a>
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            {event.ticketLimits.map((available) => (
                              <div key={available}>{available}</div>
                            ))}
                          </Tooltip>
                        }
                      >
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          Available
                        </a>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    {event.ticketPurchased.length === 1 ? (
                      <a className='text-dark fw-bold d-block mb-1 fs-6'>
                        {event.ticketPurchased[0] ?? 0}
                      </a>
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            {event.ticketPurchased.map((booked) => (
                              <div key={booked}>{booked ?? 0}</div>
                            ))}
                          </Tooltip>
                        }
                      >
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          Booked
                        </a>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    <td>
                      {event.event_datetime.length === 1 ? (
                        isBefore(parseISO(event.event_datetime[0]), new Date()) ? (
                          <a className='text-dark fw-bold d-block mb-1 fs-6'>Completed</a>
                        ) : (
                          <a className='text-dark fw-bold d-block mb-1 fs-6'>Upcoming</a>
                        )
                      ) : (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip>
                              {event.event_datetime.map((datetime) => (
                                <div key={datetime}>{formatDate(datetime)}</div>
                              ))}
                            </Tooltip>
                          }
                        >
                          <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {
                              event.event_datetime.filter((datetime) =>
                                isBefore(parseISO(datetime), new Date())
                              ).length
                            }
                            /{event.event_datetime.length}
                          </a>
                        </OverlayTrigger>
                      )}
                    </td>
                  </td>
                  <td className='text-center'>
                    <Link
                      to={'/events/event-view/event-details'}
                      state={{ eventId: event.id }}
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-2 me-2'
                    >
                      {/* <KTIcon iconName='eye' className='fs-2' /> */}
                      View
                    </Link>
                    <Link
                      to={'/events/edit-event'}
                      state={{ eventId: event.id }}
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-2 me-2'
                    >
                      {/* <KTIcon iconName='pencil' className='fs-2' /> */}
                      Edit
                    </Link>
                    <button
                      type='button'
                      className='btn btn-bg-light btn-color-muted btn-active-color-danger btn-sm px-2'
                      onClick={() => openEventDeleteModal(event)}
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <>
          {isDeleteEventModalOpen && (
            <div
              className='modal fade show d-block'
              id='kt_modal_add_user'
              role='dialog'
              tabIndex={-1}
              aria-modal='true'
            >
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered mw-450px'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <div className='modal-header'>
                    {/* begin::Modal title */}
                    <h2 className='fw-bolder'>Delete Event</h2>
                    {/* end::Modal title */}

                    {/* begin::Close */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-danger'
                      data-kt-users-modal-action='close'
                      onClick={closeEventDeleteModal}
                      style={{ cursor: 'pointer' }}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </div>
                    {/* end::Close */}
                  </div>
                  {/* begin::Modal body */}
                  <div className='scroll-y mx-5 mx-xl-15 my-7'>
                    <p className='fs-4 fw-bold'>
                      <span className='text-muted'>Event Name :</span> {deleteEvent.name}
                    </p>
                    <p className='fs-4 fw-bold'>
                      <span className='text-muted'>Created Date :</span>{' '}
                      {formatDateWithTime(deleteEvent.date_created)}
                    </p>
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => closeEventDeleteModal()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Cancel
                      </button>

                      <button
                        type='submit'
                        className='btn btn-danger'
                        data-kt-users-modal-action='submit'
                        onClick={() => eventDelete(deleteEvent.id)}
                        disabled={deleteLoading}
                      >
                        <span className='indicator-label'>Delete</span>
                      </button>
                    </div>
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          )}
          {/* begin::Modal Backdrop */}
          {isDeleteEventModalOpen && <div className='modal-backdrop fade show'></div>}
          {/* end::Modal Backdrop */}
        </>
        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default EventsWrapper
