import {Link, useLocation} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {getAuthorizationToken} from '../../../authorization-token'

interface LocationState {
  support: Support
}

export interface Support {
  id: number
  subject: string
  name: string
  date_created: string
  email: string
  message: string
  mobile_number: string
  status: string
  action: string
}

const SupportDetails: React.FC = () => {
  const location = useLocation()
  const support = location.state as Support
  const [selectedStatus, setSelectedStatus] = useState(support.status)
  const [selectedAction, setSelectedAction] = useState(support.action)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false)
  }

  async function handleUpdateStatus() {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateSupportStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({
        id: support.id,
        status: selectedStatus,
        action: selectedAction,
      }),
    }).then((result) => {
      if (result.status === 200) {
        console.log('Success', result)
        setIsConfirmModalOpen(false)
        setIsConfirmModalOpen(false)
      } else {
        console.log('Error', result)
      }
    })
  }

  // console.log(support)
  useEffect(() => {
    if (selectedStatus === 'Pending') {
      setSelectedAction('')
      handleUpdateStatus()
    }
    if (selectedStatus === 'In Process' && selectedAction !== '') {
      handleUpdateStatus()
    }
  }, [selectedStatus, selectedAction])
  useEffect(() => {
    if (
      selectedStatus === 'Completed' &&
      (selectedAction === 'Refunded' || selectedAction === 'Complained')
    ) {
      setIsConfirmModalOpen(true)
    }
  }, [selectedAction, selectedStatus])

  return (
    <>
      <div className='mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div
          className='card-title m-0 d-flex align-items-center mb-10'
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <h3 className='card-title align-items-start flex-column'>
            <Link
              to='/settings/support'
              className='btn btn-sm btn-icon btn-active-color-primary text-end'
            >
              <KTIcon iconName='arrow-left' className='fs-2' />
            </Link>
            <span className='card-label fw-bold fs-2 mb-1'>Support Details</span>
          </h3>
          <div className='card-toolbar '>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-300px ps-14'
                placeholder='Complaint ID'
                value={`compliant id: ${support.id}`}
                disabled
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Status</option>
                <option value='Pending'>Pending</option>
                <option value='In Process'>In Process</option>
                <option value='Completed'>Completed</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex flex-end align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedAction || ''}
                onChange={(e) => {
                  setSelectedAction(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Action</option>
                <option value='Refunded'>Refunded</option>
                <option value='Complained'>Complained</option>
              </select>
              <span>{warningMessage}</span>
            </div>
            {/* end::Search */}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Subject</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Subject'
              value={support.subject}
              disabled
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>User</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='User'
              value={support.name}
              disabled
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Complain Creation Date</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Complain Creation Date'
              value={new Date(support.date_created).toLocaleDateString()}
              disabled
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Email'
              value={support.email}
              disabled
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Message</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <textarea
              rows={4}
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              value={support.message}
              disabled
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Phone Number</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Phone Number'
              value={support.mobile_number}
              disabled
            />
          </div>
        </div>
      </div>
      <>
        {isConfirmModalOpen && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-450px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h2 className='fw-bolder'>Completed</h2>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-danger'
                    data-kt-users-modal-action='close'
                    onClick={closeConfirmModal}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                  {/* end::Close */}
                </div>
                {/* begin::Modal body */}
                <div className='scroll-y mx-5 mx-xl-15 my-7'>
                  <p className='fs-4 fw-bold'>
                    <span className='text-muted'>
                      Once confirmed, marking a query as completed cannot be undone.
                    </span>
                  </p>
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => closeConfirmModal()}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Cancel
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      onClick={() => handleUpdateStatus()}
                      // disabled={editFormik.isSubmitting || !editFormik.isValid || !editFormik.touched}
                    >
                      <span className='indicator-label'>Confirm</span>
                    </button>
                  </div>
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
        )}
        {/* begin::Modal Backdrop */}
        {isConfirmModalOpen && <div className='modal-backdrop fade show'></div>}
        {/* end::Modal Backdrop */}
      </>
    </>
  )
}

export default SupportDetails
