import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { getAuthorizationToken } from '../../../authorization-token'
import PaginationComponent from '../../../pagination'

const categorySchema = Yup.object().shape({
  event_category: Yup.string().required('Name is required'),
})

const ManageCategory: React.FC = () => {
  interface Category {
    id: number
    event_category: string
    status: string
  }

  const [categoryData, setCategoryData] = useState<Category[]>([])
  const [categoryError, setCategoryError] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get all Event-Category List
  const fetchCategoryData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setCategoryData(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // For Get ALL User List When page is load
  useEffect(() => {
    fetchCategoryData()
  }, [])

  // Add Category
  const formik = useFormik({
    initialValues: {
      event_category: '',
    },
    validationSchema: categorySchema,
    onSubmit: (values) => {
      // console.log("Values ", values)
      const formData = {
        event_category: values.event_category,
      }
      // console.log('FormData', formData);
      fetch(`${process.env.REACT_APP_API_BASE_URL}/addCategory`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify(formData),
      }).then((result) => {
        if (result.status === 200) {
          closeAddCategoryModal()
          fetchCategoryData()
        } else if (result.status === 422) {
          result
            .json()
            .then((data) => {
              // console.log(data.error);
              setCategoryError(false)
              if (data.error === 'event_category already exists') return setCategoryError(true)
            })
            .catch((error) => {
              console.error('Error parsing response body:', error)
            })
        } else {
          console.log('Error', result)
        }
      })
    },
  })

  // Update Category Status
  const updateCategorystatus = (categoryId: number, newStatus: string) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateCategorystatus`, {
      method: 'PUT',
      headers: {
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: categoryId, status: newStatus }),
    })
      .then((result) => {
        if (result.status === 200) {
          fetchCategoryData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }

  // For Delete a Category
  const deleteCategory = (categoryId: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteCategory/${categoryId}`, {
      method: 'DELETE',
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        if (result.status === 200) {
          fetchCategoryData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }

  // For Add Category Model
  const [isAddModalOpen, setAddModalOpen] = useState(false)

  const openAddCategoryModal = () => {
    setAddModalOpen(true)
  }

  const closeAddCategoryModal = () => {
    setAddModalOpen(false)
    formik.resetForm()
  }

  // For Edit Category Model
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [categoryId, setCategoryId] = useState<number>()
  const [categoryEvent, setCategoryEvent] = useState('')
  const [isContentChanged, setIsCategoryChanged] = useState(false)

  const openEditCategoryModal = (category: Category) => {
    setCategoryId(category.id)
    setCategoryEvent(category.event_category)
    editFormik.setValues({
      id: category.id,
      event_category: category.event_category,
    })
    setEditModalOpen(true)
  }
  const initialValues = {
    id: categoryId,
    event_category: categoryEvent,
  }

  // Edit Category
  const editFormik = useFormik({
    initialValues: {
      id: categoryId,
      event_category: '',
    },
    validationSchema: categorySchema,
    onSubmit: (values) => {
      // console.log('Values', values)
      const payload = {
        id: values.id,
        event_category: values.event_category,
      }
      // console.log('Values', payload)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/editCategory`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify(payload),
      }).then((result) => {
        if (result.status === 200) {
          closeEditCategoryModal()
          fetchCategoryData()
        } else if (result.status === 422) {
          result
            .json()
            .then((data) => {
              // console.log(data.error);
              setCategoryError(false)
              if (data.error === 'event_category already exists') return setCategoryError(true)
            })
            .catch((error) => {
              console.error('Error parsing response body:', error)
            })
        } else {
          console.log('Error', result)
        }
      })
    },
  })

  useEffect(() => {
    const isChanged =
      editFormik.dirty && editFormik.values.event_category !== initialValues.event_category
    setIsCategoryChanged(isChanged)
  }, [editFormik.dirty, editFormik.values.event_category, initialValues.event_category])

  const closeEditCategoryModal = () => {
    editFormik.resetForm()
    setCategoryError(false)
    setEditModalOpen(false)
  }

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of items to display per page

  const filteredData = categoryData.filter((event) =>
    event.event_category.toLowerCase().includes(searchTerm.trim().toLowerCase())
  )

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const indexOfLastData = currentPage * itemsPerPage
  const indexOfFirstData = indexOfLastData - itemsPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage)
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)

  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div
        className='card-title m-0 d-flex align-items-center'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 className='card-title align-items-start flex-column'>
          <Link to='/settings' className='btn btn-sm btn-icon btn-active-color-primary text-end '>
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <span className='card-label fw-bold fs-2 mb-1'>Manage Category</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
        </h3>
        <div className='card-toolbar '>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-362px ps-14'
              placeholder='Search by category Name '
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative my-1'>
            <button type='button' className='btn btn-primary' onClick={openAddCategoryModal}>
              <KTIcon iconName='plus' className='fs-2' />
              Add Category
            </button>
          </div>
          {/*Add category model Start */}
          <>
            {isAddModalOpen && (
              <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
              >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/* begin::Modal content */}
                  <div className='modal-content'>
                    <div className='modal-header'>
                      {/* begin::Modal title */}
                      <h2 className='fw-bolder'>Add Category</h2>
                      {/* end::Modal title */}

                      {/* begin::Close */}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                        onClick={closeAddCategoryModal}
                        style={{ cursor: 'pointer' }}
                      >
                        <KTIcon iconName='cross' className='fs-1' />
                      </div>
                      {/* end::Close */}
                    </div>
                    {/* begin::Modal body */}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      <form
                        onSubmit={formik.handleSubmit}
                        id='kt_modal_add_user_form'
                        className='form'
                        noValidate
                      >
                        {/* begin::Scroll */}
                        <div
                          className='d-flex flex-column scroll-y me-n7 pe-7'
                          id='kt_modal_add_user_scroll'
                          data-kt-scroll='true'
                          data-kt-scroll-activate='{default: false, lg: true}'
                          data-kt-scroll-max-height='auto'
                          data-kt-scroll-dependencies='#kt_modal_add_user_header'
                          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                          data-kt-scroll-offset='300px'
                        >
                          {/* begin::Input group */}
                          <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Category Name</label>
                            {/*Error*/}
                            {categoryError && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>Category already exists.</div>
                              </div>
                            )}
                            {/* begin::Input */}
                            <input
                              placeholder='category-name'
                              {...formik.getFieldProps('event_category')}
                              type='text'
                              {...formik.getFieldProps('event_category')}
                              className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {
                                  'is-invalid':
                                    formik.touched.event_category && formik.errors.event_category,
                                },
                                {
                                  'is-valid':
                                    formik.touched.event_category && !formik.errors.event_category,
                                }
                              )}
                            // autoComplete='off'
                            />
                            {formik.touched.event_category && formik.errors.event_category && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.event_category}</span>
                                </div>
                              </div>
                            )}
                            {/* end::Input */}
                          </div>
                          {/* end::Input group */}
                        </div>
                        {/* end::Scroll */}

                        {/* begin::Actions */}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            onClick={() => closeAddCategoryModal()}
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                          >
                            Cancel
                          </button>

                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                          // disabled={editFormik.isSubmitting || !editFormik.isValid || !editFormik.touched}
                          >
                            <span className='indicator-label'>Save</span>
                          </button>
                        </div>
                        {/* end::Actions */}
                      </form>
                    </div>
                    {/* end::Modal body */}
                  </div>
                  {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
              </div>
            )}
            {/* begin::Modal Backdrop */}
            {isAddModalOpen && <div className='modal-backdrop fade show'></div>}
            {/* end::Modal Backdrop */}
          </>
          {/*Add category model end */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Category Name</th>
                <th className='min-w-150px text-center'>Status</th>
                <th className='min-w-100px text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {currentData.map((category) => (
                <tr key={category.id}>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {category.event_category}
                    </a>
                  </td>
                  <td className='text-center'>
                    <div
                      className='dropdown'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <a
                        className='btn btn-light btn-active-light-primary btn-sm'
                        role='button'
                        id={`dropdownMenu${category.id}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {category.status}
                        <KTIcon iconName='down' className='fs-5 m-0' />
                      </a>
                      <ul
                        className='dropdown-menu'
                        aria-labelledby={`dropdownMenu${category.id}`}
                      >
                        <li>
                          <a
                            className='dropdown-item'
                            onClick={() => {
                              updateCategorystatus(
                                category.id,
                                category.status === 'Active' ? 'Inactive' : 'Active'
                              );
                            }}
                          >
                            {category.status === 'Active' ? 'Inactive' : 'Active'}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td className='text-center'>
                    <a
                      onClick={() => openEditCategoryModal(category)}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                    >
                      Edit
                    </a>
                    <a
                      onClick={() => deleteCategory(category.id)}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* begin::Body */}
      {/*Add category model Start */}
      <>
        {isEditModalOpen && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h2 className='fw-bolder'>Edit Category</h2>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={closeEditCategoryModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                  {/* end::Close */}
                </div>
                {/* begin::Modal body */}
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form
                    onSubmit={editFormik.handleSubmit}
                    id='kt_modal_add_user_form'
                    className='form'
                    noValidate
                  >
                    {/* begin::Scroll */}
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      {/* begin::Input group */}
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Category Name</label>
                        {/*Error*/}
                        {categoryError && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Category already exists.</div>
                          </div>
                        )}
                        {/* begin::Input */}
                        <input
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                editFormik.touched.event_category &&
                                editFormik.errors.event_category,
                            },
                            {
                              'is-valid':
                                editFormik.touched.event_category &&
                                !editFormik.errors.event_category,
                            }
                          )}
                          type='text'
                          placeholder='category-name'
                          {...editFormik.getFieldProps('event_category')}
                        // autoComplete='off'
                        />
                        {editFormik.touched.event_category && editFormik.errors.event_category && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{editFormik.errors.event_category}</span>
                            </div>
                          </div>
                        )}
                        {/* end::Input */}
                      </div>
                      {/* end::Input group */}
                    </div>
                    {/* end::Scroll */}

                    {/* begin::Actions */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => closeEditCategoryModal()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Cancel
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={!isContentChanged}
                      >
                        <span className='indicator-label'>Save Changes</span>
                      </button>
                    </div>
                    {/* end::Actions */}
                  </form>
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
        )}
        {/* begin::Modal Backdrop */}
        {isEditModalOpen && <div className='modal-backdrop fade show'></div>}
        {/* end::Modal Backdrop */}
      </>
      {/*Add category model end */}
    </div>
  )
}

export default ManageCategory;