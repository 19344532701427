import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, NavLink } from 'react-router-dom'
import { updatePassword } from '../../modules/accounts/components/settings/SettingsModel';
import { formatDate } from '../../../datetime.formate';
import { getAuthorizationToken } from '../../../authorization-token';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import clsx from 'clsx';
import PaginationComponent from '../../../pagination';
import PlaceHolderComponent from '../../../skeletol-view';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
})

const StaffWrapper = () => {
  interface Staff {
    id: number;
    dateCreated: string;
    email: string;
    gender: string;
    genderId: number;
    mobile: string;
    name: string;
    staffId: string;
    status: string;
    password: string;
  }
  const [staffData, setStaffData] = useState<Staff[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [passwordError, setPasswordError] = useState(false)


  // State to control the reset password modal
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [staffId, setStaffId] = useState<number>()
  const [currentPassword, setCurrentPassword] = useState('');
  //  const [newPassword, setNewPassword] = useState('');
  //  const [confirmPassword, setConfirmPassword] = useState('');

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()


  // Get ALL Staff List
  const fetchStaffData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allStaff`,
      {
        headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
      }).then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setLoading(false)
          setStaffData(resp);
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error);
      });
  }

  // For Get ALL Staff List When page is load
  useEffect(() => {
    fetchStaffData();
  }, [])

  const openResetPasswordModal = (staff: Staff) => {
    setStaffId(staff.id);
    setCurrentPassword(staff.password);
    editFormik.setValues({
      id: staff.id,
      password: staff.password,
    })
    setResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setStaffId(undefined);
    setCurrentPassword('');
    //  setNewPassword('');
    //  setConfirmPassword('');
    setResetPasswordModalOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const staffsPerPage = 10;

  // Get the staffs to display on the current page
  // For filteration by search
  const filteredData = staffData.filter((staff) =>
    (staff.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      staff.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      staff.mobile.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (!searchDate ||
      (new Date(staff.dateCreated).toISOString().substr(0, 10) === new Date(searchDate).toISOString().substr(0, 10))) &&
    (!selectedStatus ||
      (staff.status === 'Active' && selectedStatus === 'Active') ||
      (staff.status === 'Inactive' && selectedStatus === 'Inactive'))
  )

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, searchDate, selectedStatus]);

  const indexOfLastData = currentPage * staffsPerPage;
  const indexOfFirstData = indexOfLastData - staffsPerPage;
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / staffsPerPage);

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Update Staff Status
  const updateStaffstatus = (staffId: number, newStatus: string) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStaffstatus`, {
      method: 'PUT',
      headers: {
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: staffId, status: newStatus }),
    })
      .then((result) => {
        if (result.status === 200) {
          fetchStaffData();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }



  // For Delete a Staff
  const deleteStaff = (staffId: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteStaff/${staffId}`, {
      method: 'DELETE',
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        if (result.status === 200) {
          fetchStaffData();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const initialValues = {
    id: staffId,
    password: currentPassword,
  }

  // Edit password
  const editFormik = useFormik({
    initialValues: {
      id: staffId,
      password: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      console.log('Values', values)
      const payload = {
        id: values.id,
        password: values.password,
      }
      console.log('Values', payload)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/resetPassword`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify(payload),
      }).then((result) => {
        if (result.status === 200) {
          closeResetPasswordModal()
          fetchStaffData()
        } else {
          console.log('Error', result)
        }
      })
    },
  })

  const [isContentChanged, setIsPasswordChanged] = useState(false)

  useEffect(() => {
    const isChanged =
      editFormik.dirty && editFormik.values.password !== initialValues.password
    setIsPasswordChanged(isChanged)
  }, [editFormik.dirty, editFormik.values.password, initialValues.password])


  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1'>Staff</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
        </h3>
        <div className='card-toolbar '>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-350px ps-14'
              placeholder='Search by Staff Name, Email, Mobile '
              value={searchTerm}
              onChange={(e) => {
                console.log(e.target.value)
                setSearchTerm(e.target.value)
              }}
              autoComplete="off"
              inputMode='search'
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              type='date'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-180px ps-14'
              placeholder='Date'
              value={searchDate}
              onChange={(e) => {
                setSearchDate(e.target.value)
              }}
            />
            <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedStatus || ''}
              onChange={(e) => {
                setSelectedStatus(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Status</option>
              <option value='Active'>Active</option>
              <option value='Inactive'>Inactive</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative my-1'>
            <Link to='/staff/add-staff' className='btn btn-primary btn-sm' data-bs-toggle='tooltip' title='Coming soon'>
              Add
            </Link>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Created Date</th>
                <th className='min-w-100px'>Staff Id</th>
                <th className='min-w-100px'>Staff Name</th>
                <th className='min-w-100px'>Email Address</th>
                <th className='min-w-100px'>Mobile Number</th>
                <th className='min-w-100px text-center'>Status</th>
                <th className='min-w-125px text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && (
                <PlaceHolderComponent
                  tableHeaders={7}
                />
              )}
              {currentData.map((staff) => (
                <tr key={staff.id}>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatDate(staff.dateCreated)}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {staff.staffId}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {staff.name}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {staff.email}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {staff.mobile}
                    </a>
                  </td>
                  <td className='text-center'>
                    <div
                      className='dropdown'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <a
                        className='btn btn-light btn-active-light-primary btn-sm'
                        href='#'
                        role='button'
                        id={`dropdownMenu${staff.id}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {staff.status}
                        <KTIcon iconName='down' className='fs-5 m-0' />
                      </a>
                      <ul
                        className='dropdown-menu'
                        aria-labelledby={`dropdownMenu${staff.id}`}
                      >
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => {
                              updateStaffstatus(
                                staff.id,
                                staff.status === 'Active' ? 'Inactive' : 'Active'
                              );
                            }}
                          >
                            {staff.status === 'Active' ? 'Inactive' : 'Active'}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td className='text-center'>
                    <Link
                      to={
                        `/staff/staff-edit?id=${encodeURIComponent(staff.id)}
                        &name=${encodeURIComponent(staff.name)}
                        &email=${encodeURIComponent(staff.email)}
                        &staffId=${encodeURIComponent(staff.staffId)}
                        &genderId=${staff.genderId}
                        &mobileNumber=${encodeURIComponent(staff.mobile)}`}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                    // Call handleEditStaff with staff data when Edit button is clicked
                    >
                      Edit
                    </Link>
                    <a
                      onClick={() => deleteStaff(staff.id)}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                    >
                      Delete
                    </a>
                    <a
                      onClick={() => openResetPasswordModal(staff)}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                    >
                      Reset Password
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          {/* Reset Password Modal */}
          {resetPasswordModalOpen && (
            <div className='modal fade show d-block'>
              {/* Modal content */}
              <div className='modal-dialog modal-dialog-centered mw-400px'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2 className='fw-bolder'>Reset Password</h2>
                    <button
                      type='button'
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      onClick={closeResetPasswordModal}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                  <div className='modal-body scroll-y mx-5 my-7'>
                    <form
                      onSubmit={editFormik.handleSubmit}
                      id='kt_modal_add_user_form'
                      className='form'
                      noValidate
                    >
                      {/* begin::Scroll */}
                      <div
                        className='d-flex flex-column scroll-y me-n7 pe-7'
                        id='kt_modal_add_user_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                        data-kt-scroll-offset='300px'
                      >
                        {/* begin::Input group */}
                        <div className='fv-row mb-7'>
                          <label className='required fs-6 mb-2'>Password</label>
                          {/*Error*/}
                          {passwordError && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>Password in not valid.</div>
                            </div>
                          )}
                          {/* begin::Input */}
                          <input
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              {
                                'is-invalid':
                                  editFormik.touched.password &&
                                  editFormik.errors.password,
                              },
                              {
                                'is-valid':
                                  editFormik.touched.password &&
                                  !editFormik.errors.password,
                              }
                            )}
                            type='text'
                            placeholder='category-name'
                            {...editFormik.getFieldProps('password')}
                          // autoComplete='off'
                          />
                          {editFormik.touched.password && editFormik.errors.password && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{editFormik.errors.password}</span>
                              </div>
                            </div>
                          )}
                          {/* end::Input */}
                        </div>
                        {/* end::Input group */}
                      </div>
                      {/* end::Scroll */}

                      {/* begin::Actions */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          onClick={() => closeResetPasswordModal()}
                          className='btn btn-light me-3'
                          data-kt-users-modal-action='cancel'
                        >
                          Cancel
                        </button>

                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                          disabled={!isContentChanged}
                        >
                          <span className='indicator-label'>Save Changes</span>
                        </button>
                      </div>
                      {/* end::Actions */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* end::Table container */}
        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default StaffWrapper
