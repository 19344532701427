import { FC, useEffect, useState } from "react"
import * as Yup from 'yup'
import { IProfileDetails, profileDetailsInitValues as initialValues } from "../../modules/accounts/components/settings/SettingsModel"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { KTIcon } from "../../../_metronic/helpers"
import { getAuthorizationToken } from "../../../authorization-token"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Select from "react-select"



const notificationSchema = Yup.object().shape({
    heading: Yup.string().required('Heading is required'),
    content: Yup.string().required('Content is required'),
})

const SendNewNotification: FC = () => {

    interface Category {
        id: number
        event_category: string
        status: string
    }

    interface Event {
        id: number;
        name: string;
        category_id: number;
    }

    interface Staff {
        id: number;
        name: string;
        status: string;
    }

    interface User {
        id: number
        full_name: string
        status: string
    }

    const [categoryData, setCategoryData] = useState<Category[]>([])
    const [events, setEvents] = useState<Event[]>([])
    const [staffData, setStaffData] = useState<Staff[]>([])
    const [userData, setUserData] = useState<User[]>([])

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    const fetchCategoryData = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`, {
            headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
        })
            .then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setCategoryData(resp)
                })
            })
            .catch((error) => {
                console.error('Error fetching staff data:', error)
            })
    }
    const fetchEvents = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getEvents`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setEvents(resp);
                })
            })
            .catch((error) => {
                console.error('Error fetching event data:', error);
            });
    }
    const fetchStaffData = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/allStaff`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    // setStaffData(resp);
                    setStaffData(resp.map((staff: Staff) => ({ label: staff.name, value: staff.id.toString() })));
                })
            })
            .catch((error) => {
                console.error('Error fetching staff data:', error);
            });
    }
    const fetchUserData = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/allUsers`, {
            headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
        })
            .then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setUserData(resp.map((user: User) => ({ label: user.full_name, value: user.id.toString() })))
                })
            })
            .catch((error) => {
                console.error('Error fetching user data:', error)
            })
    }

    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);

    const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);


    useEffect(() => {
        fetchCategoryData()
        fetchEvents()
        fetchStaffData()
        fetchUserData()
    }, [])

    // Filter Events  by Category
    useEffect(() => {
        if (selectedCategory === null) {
            setAllOptionHide(false)
            setSelectedCategory(null)
            setFilteredEvents(events);
            setSelectedEvent(0)
        } else if (selectedCategory === 0) {
            setAllOptionHide(false)
            setSelectedCategory(0)
            setFilteredEvents(events)
            setSelectedEvent(0)
        } else {
            const filtered = events.filter((event) => event.category_id === selectedCategory);
            setAllOptionHide(true)
            setFilteredEvents(filtered);
            setSelectedEvent(0)
        }
    }, [selectedCategory, events]);

    // Filter Users By Event
    useEffect(() => {
        if (selectedEvent === null || selectedEvent === 0) {
            setFilteredUsers(userData)
            setAllOptionHide(false)
        } else {
            // setSelectedEvent(0)
            const filter = fetchUsersByEvent(selectedEvent)
        }
    }, [selectedEvent, userData]);

    const [allOptionHide, setAllOptionHide] = useState(false);

    const fetchUsersByEvent = (event_id: number) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/usersByEvent/${event_id}`, {
            headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
        })
            .then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setAllOptionHide(true)
                    if (resp === null || resp.length === 0) { setAllOptionHide(true) }
                    setFilteredUsers(resp.map((user: User) => ({ label: user.full_name, value: user.id.toString() })))
                    formik.setFieldValue('user_id', '');
                })
            })
            .catch((error) => {
                console.error('Error fetching user data:', error)
            })
    }

    // Set Staff Values
    const [selectedStaffValues, setSelectedStaffValues] = useState([]);

    const handleSelectStaff = (selectedOptions) => {
        const selectedOption = (selectedOptions.map((option) => option.value));
        const values = selectedOption.map(Number)
        setSelectedStaffValues(values);
    };

    useEffect(() => {
        //   console.log('Updated selected values:', selectedStaffValues);
        formik.setFieldValue('staff_id', selectedStaffValues);
    }, [selectedStaffValues]);

    // Set User Values
    const [selectedUserValues, setSelectedUserValues] = useState([]);

    const handleSelectUser = (selectedOptions) => {
        const selectedOption = (selectedOptions.map((option) => option.value));
        const values = selectedOption.map(Number)
        setSelectedUserValues(values);
    };

    useEffect(() => {
        //   console.log('Updated selected values:', selectedUserValues);
        formik.setFieldValue('user_id', selectedUserValues);
    }, [selectedUserValues]);


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            heading: '',
            category_id: '',
            event_id: '',
            staff_id: [],
            user_id: [],
            content: '',
        },
        validationSchema: notificationSchema,
        onSubmit: (values) => {
          // console.log(selectedEvent)
          setLoading(true)

          if (selectedCategory !== 0 && selectedCategory !== null) {
            values.category_id = selectedCategory!.toString()
          }
          if (selectedEvent !== 0 && selectedEvent !== null) {
            values.event_id = selectedEvent!.toString()
          }
          // console.log(values)

          const payload: any = {}

          if (values.heading && values.content) {
            payload.heading = values.heading
            payload.content = values.content
          }

          //If ONly category_id is present and event_id , user_id is not than only category_id will inclued in the payload
          if (selectedEvent === null || selectedEvent === 0) {
            if (
              (selectedCategory !== 0 && values.category_id !== '0' && values.user_id === null) ||
              values.user_id.length === 0
            ) {
              // values.category_id = selectedCategory!.toString()
              // console.log('Category Id')
              if (values.category_id !== '') {
                payload.category_id = values.category_id
              }
            }
          }

          // If event_id is present and user_id is not than only event_id will include in the payload not the category_id
          if (
            (selectedEvent !== null && selectedEvent !== 0 && values.user_id === null) ||
            values.user_id.length === 0
          ) {
            // console.log('Event Id')
            if (values.event_id !== '') {
              payload.event_id = values.event_id
            }
          }

          // If user_id is present than have to send user_id only not event_id and category_id
          if (values.user_id.length !== 0) {
            payload.user_id = values.user_id
          }
          if (values.staff_id.length !== 0) {
            payload.staff_id = values.staff_id
          }

          // Check if none of the fields are selected
          if (!payload.category_id && !payload.event_id && !payload.user_id && !payload.staff_id) {
            alert('Please select at least one field from Category, Event, Staff or Users')
            setLoading(false)
            return
          }

          fetch(`${process.env.REACT_APP_API_BASE_URL}/sendNotification`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
            },
            body: JSON.stringify(payload),
          }).then((result) => {
            if (result.status === 200) {
              // Navigate to the /settings/discount-code/ page
              setLoading(false)
              navigate('/notification/sent')
            } else {
              setLoading(false)
              console.log('Error', result)
            }
          })
        },
    })

    return (
        <div>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0 d-flex align-items-center'>
                    <Link
                        to='/notification'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end'
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <h3 className='fw-bolder m-0'>Send New Notification</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Heading</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Heading'
                                    {...formik.getFieldProps('heading')}
                                />
                                {formik.touched.heading && formik.errors.heading && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.heading}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className=''>Category</span>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip>
                                            <span>
                                                All users that have booked tickets for any of the upcoming events that fall under selected <b>Category</b> will be sent a notification.
                                                Also, users that have selected this <b>Category</b> in their interest list will also receive the notification.
                                                <br />
                                                <span style={{ color: 'blue' }}>
                                                    To Search press first letter
                                                </span>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <circle fill="#0000ff" opacity="0.3" cx="12" cy="12" r="7" />
                                            <rect fill="#0000ff" x="11" y="10" width="2" height="7" rx="1" />
                                            <rect fill="#0000ff" x="11" y="7" width="2" height="2" rx="1" />
                                        </g>
                                    </svg></span>
                                </OverlayTrigger>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('category_id')}
                                    onChange={(e) => {
                                        // console.log(e.target.value)
                                        const selectedCategoryId = parseInt(e.target.value);
                                        // console.log(selectedCategoryId)
                                        setSelectedCategory(selectedCategoryId === 0 ? 0 : selectedCategoryId);
                                    }}
                                    value={selectedCategory || '0'}
                                >
                                    <option value='0'>Select</option>
                                    {/* <option value='0'>All</option> */}
                                    {categoryData.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.event_category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className=''>Event</span>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip>
                                            <span>
                                                All users that have booked tickets for selected <b>Event</b> will be sent the notification.
                                                <br />
                                                <span style={{ color: 'blue' }}>
                                                    Note: If <b>Category</b> selection has been made before selecting <b>Event</b> the dropdown will only show events that belong to the selected <b>Category</b>.
                                                    <br />
                                                    To Search press first letter
                                                </span>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <circle fill="#0000ff" opacity="0.3" cx="12" cy="12" r="7" />
                                            <rect fill="#0000ff" x="11" y="10" width="2" height="7" rx="1" />
                                            <rect fill="#0000ff" x="11" y="7" width="2" height="2" rx="1" />
                                        </g>
                                    </svg></span>
                                </OverlayTrigger>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('event_id')}
                                    onChange={(e) => {
                                        const selectedEventId = parseInt(e.target.value);
                                        setSelectedEvent(selectedEventId === 0 ? 0 : selectedEventId);
                                    }}
                                    value={selectedEvent || '0'}
                                >
                                    <option value='0'>Select</option>
                                    {/* <option value='0'>All</option> */}
                                    {filteredEvents.map((event) => (
                                        <option key={event.id} value={event.id}>
                                            {event.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>


                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className=''>Staff</span>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip>
                                            <span>
                                                The selected <b>Staff</b> will be sent a notification.
                                                <br />
                                                <span style={{ color: 'blue' }}>
                                                    Note: if any category/event selections have been made, the <b>users</b> of those selection will also be sent the notification.
                                                    <br />
                                                    To Search press first letter
                                                </span>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <circle fill="#0000ff" opacity="0.3" cx="12" cy="12" r="7" />
                                            <rect fill="#0000ff" x="11" y="10" width="2" height="7" rx="1" />
                                            <rect fill="#0000ff" x="11" y="7" width="2" height="2" rx="1" />
                                        </g>
                                    </svg></span>
                                </OverlayTrigger>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <Select
                                    isMulti
                                    name="staff_id"
                                    options={staffData}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleSelectStaff}
                                />
                                {/* <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('staff_id')}
                                >
                                    <option value=''>Select</option>
                                    <option value='0'>All</option>
                                    {staffData.map(staff => (
                                        <option key={staff.id} value={staff.id}>
                                            {staff.name}
                                        </option>
                                    ))}
                                </select> */}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className=''>Users</span>
                                <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    {/* <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <circle fill="#0000ff" opacity="0.3" cx="12" cy="12" r="7" />
                                        <rect fill="#0000ff" x="11" y="10" width="2" height="7" rx="1" />
                                        <rect fill="#0000ff" x="11" y="7" width="2" height="2" rx="1" />
                                    </g> */}
                                </svg></span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <Select
                                    isMulti
                                    name="user_id"
                                    options={filteredUsers}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleSelectUser}
                                />
                                {/* <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('user_id')}
                                >
                                    <option value=''>Select</option>
                                    {allOptionHide ? (
                                        <></> // Render nothing when allOptionHide is true
                                    ) : (
                                        <option value='0'>All</option> // Render the "All" option when allOptionHide is false
                                    )}
                                    {filteredUsers.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.full_name}
                                        </option>
                                    ))}
                                </select> */}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Notification</label>

                            <div className='col-lg-8 fv-row'>
                                <textarea
                                    rows={4}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder=''
                                    {...formik.getFieldProps('content')}
                                />
                                {formik.touched.content && formik.errors.content && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.content}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/notification/sent'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Send'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SendNewNotification