import React, { FC, useEffect, useState } from 'react'
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import Received from './Received'
import Sent from './Sent'
import { getAuthorizationToken } from '../../../authorization-token'
import { formatDateWithTime } from '../../../datetime.formate'
import PaginationComponent from '../../../pagination'
import PlaceHolderComponent from '../../../skeletol-view'

// const NotificationWrapper: FC = () => {
//   return (
//     <Routes>
//       <Route
//         element={
//           <>
//             <NotificationHeader />
//             <Outlet />
//           </>
//         }
//       >
//         <Route
//           path='received'
//           element={
//             <>
//               <Received />
//             </>
//           }
//         />
//         <Route
//           path='sent'
//           element={
//             <>
//               <Sent />
//             </>
//           }
//         />
//         <Route index element={<Navigate to='/notification/sent' />} />
//       </Route>
//     </Routes>
//   )
// }


// const NotificationHeader: FC = () => {
//   const location = useLocation()
//   return (
//     <>
//       <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <h3 className='card-title align-items-start flex-column'>
//           <span className='card-label fw-bold fs-2 mb-1'>Notification</span>
//           {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
//         </h3>
//         <div className='card-toolbar'>
//           <div className='d-flex align-items-center position-relative my-1'>
//             <Link to='/notification/send-new-notification' className='btn btn-primary btn-sm' data-bs-toggle='tooltip' title='Coming soon'>
//               Send New Notification
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className='d-flex align-items-center'>
//         <div className='d-flex overflow-auto h-55px'>

//           <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
//             {/* <li className='nav-item'>
//               <Link
//                 className={
//                   `nav-link text-active-primary me-6 ` +
//                   (location.pathname === '/notification/received' && 'active')
//                 }
//                 to='/notification/received'
//               >
//                 Received
//               </Link>
//             </li> */}
//             <li className='nav-item'>
//               <Link
//                 className={
//                   `nav-link text-active-primary me-6 ` +
//                   (location.pathname === '/notification/sent' && 'active')
//                 }
//                 to='/notification/sent'
//               >
//                 Sent Notifications
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   )
// }

const NotificationWrapper: FC = () => {
  const location = useLocation()
  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  interface Notification {
    id: number
    heading: string
    content: string
    count: number
    date_created: string
  }

  const [notificationData, setNotificationData] = useState<Notification[]>([])
  const [loading, setLoading] = useState(true)

  const fetchNotificationData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllNotification`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setLoading(false)
          setNotificationData(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  useEffect(() => {
    fetchNotificationData()
  }, [])

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;


  const indexOfLastData = currentPage * perPage;
  const indexOfFirstData = indexOfLastData - perPage;
  const currentData = notificationData.slice(indexOfFirstData, indexOfLastData);

  // Total number of pages
  const totalPages = Math.ceil(notificationData.length / perPage);

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <>
      <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1'>Notification</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
        </h3>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative my-1'>
            <Link to='/notification/send-new-notification' className='btn btn-primary btn-sm' data-bs-toggle='tooltip' title='Coming soon'>
              Send New Notification
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className='card-xxl-stretch mb-5 mb-xl-10'>
          {/* begin::Body */}
          <div className='card-body py-10'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-100px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-100px'></th>
                    <th className='p-0 min-w-100px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loading && (
                    <PlaceHolderComponent
                      tableHeaders={4}
                    />
                  )}
                  {currentData.map((notification) => (
                    <tr className='bg-hover-white' key={notification.id}>
                      <td>
                        <span className='text-dark  text-hover-primary mb-1 fs-6'>
                          {notification.heading}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  text-hover-primary mb-1 fs-6'>
                          {notification.content}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  text-hover-primary mb-1 fs-6'>
                          {notification.count} users
                        </span>
                      </td>
                      <td className='text-end'>
                        <span
                          className='text-dark  text-hover-primary mb-1 fs-6'
                        >
                          {formatDateWithTime(notification.date_created)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {totalPages > 1 && (
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                // pageNumbers={pageNumbers}
                currentData={currentData}
                filteredData={notificationData}
                indexOfFirstData={indexOfFirstData}
                indexOfLastData={indexOfLastData}
              />
            )}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  )
}


export default NotificationWrapper
