import {FC} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {checkIsActive, KTIcon} from '../../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}

const MenuItem: FC<Props> = ({to, title, icon, fontIcon, hasArrow = false, hasBullet = false}) => {
  const {pathname} = useLocation()

  return (
    <div className='menu-item me-lg-1'>
      <h3 className='menu-link py-3' >
        Hi,<strong>Admin</strong>
      </h3>
    </div>
  )
}

export {MenuItem}
