import clsx from 'clsx'
import { KTIcon, checkIsActive, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { SidebarMenuItem } from '../sidebar/sidebar-menu/SidebarMenuItem'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getAuthorizationToken } from '../../../../authorization-token'
import profile from '../../../../logo/image.png'

const itemClass = 'app-navbar-item ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { pathname } = useLocation()
  const isActive = pathname.startsWith('/notification');
  const { config } = useLayout()
  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const [supportCount, setSupportCount] = useState(0)

  const fetchSupportCount = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserSupportCount`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp.count)
          setSupportCount(resp.count)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  useEffect(() => {
    fetchSupportCount()
  }, [])
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}
      <div className={itemClass}>
        <div
        // className={btnClass}
        >
          <div className='menu-item'>
            <Link className={clsx('menu-link without-sub', btnClass)} to={'/settings/support'}>
              <KTIcon iconName='message-notif' className={btnIconClass} />
            </Link>
          </div>
        </div>
      </div>
      <div className={itemClass}>
        <div
        // className={btnClass}
        >
          <div className='menu-item'>
            <Link className={clsx('menu-link without-sub', btnClass, { active: isActive })} to={'/notification'}>
              <KTIcon iconName='notification-on' className={btnIconClass} />
            </Link>
          </div>
        </div>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl(profile)} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
