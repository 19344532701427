import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {FormikErrors, useFormik, validateYupSchema} from 'formik'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {getAuthorizationToken} from '../../../authorization-token'
import {ImageHandler} from '../../../image-handler'
import Select from 'react-select'
import _ from 'lodash'

const eventSchema = Yup.object().shape({
  // event_datetime: Yup.array().of(
  //   Yup.string()
  //     .required('Date Time is required')
  //     .test('is-valid-datetime', 'Date Time is required', (value) => !!value)
  // ),
  name: Yup.string().required('Name is required'),
  location: Yup.string().required('Location is required'),
  approvalRequired: Yup.string().required('Select Yes or NO'),
  gender: Yup.string().required('Select Gender'),
  event_category: Yup.string().required('Select Category'),
  ticketType: Yup.string().required('Select Ticket Type'),
  complimentaryDrink: Yup.string().required('Select Yes or NO'),
  description: Yup.string().required('Description is required'),
  general_info: Yup.string().required('General Information is required'),
  rulesAndRegulations: Yup.string().required('Please enter Rules And Regulations'),
  city: Yup.string().required('Select City'),
  no_of_tables: Yup.number().required('No of Tables is required'),
  max_ticket: Yup.number().required('Max ticket is required'),
  // .test('Is positive?', 'The number must be greater than 0!', (value) => value > 0),
  isFeatured: Yup.number()
    .oneOf([1, 0], 'Please select whether it is featured or not')
    .required('Please select whether it is featured or not'),
    site_map_enable: Yup.number()
    .oneOf([1, 0], 'Please select whether it is enabled or not')
    .required('Please select whether it is enabled or not'),
  gallery: Yup.array()
    .min(1, 'At least one gallery image is required')
    .test('is-required', 'At least one gallery image is required', (value) => {
      return Array.isArray(value) && value.length > 0
    }),
    sitemap: Yup.string().test(
      'sitemap-test',
      'Sitemap image is required',
      function (value) {
        const { site_map_enable } = this.parent; 
        if (site_map_enable === 1) {
          return value !== undefined && value !== null && value.trim() !== ''; 
        }
        return true; 
      }
    ),
     pastEventGallery: Yup.array()
    .min(1, 'At least one past event image is required')
    .test('is-required', 'At least one past event image is required', (value) => {
      return Array.isArray(value) && value.length > 0
    }),
  ticketDetails: Yup.array().of(
    Yup.object().shape({
      ticketCategory: Yup.string().required('ticketCategory is required'),
      noOfTickets: Yup.string().required('No of Tickets is required'),
      // price: Yup.string().required('Price is required'),
      entry: Yup.string().required('Entry is required'),
    })
  ),
})

interface EventDetailsState {
  eventId: number // Adjust the type of eventId if needed
}

interface TicketDetails {
  ticketCategory: string
  noOfTickets: string
  price: string
  entry: string
}

interface Staff {
  id: number
  name: string
  email: string
  country_code: string
  mobile: string
  staffId: string
}
interface EventDetails {
  id: number
  name: string
  // subtitle: string
  event_datetime: string[]
  gender: string
  event_category: string
  location: string
  ticketType: string
  approvalRequired: string
  complimentaryDrink: number
  description: string
  general_info: string
  rulesAndRegulations: string
  gallery: []
  sitemap: string
  pastEventGallery: []
  ticketDetails: TicketDetails[]
  event_staff: Staff[]
  isFeatured: number
  city: string
  no_of_tables: number
  max_ticket: number
  site_map_enable: number
}

const EditEvent: FC = () => {
  const location = useLocation()
  const {eventId} = location.state as EventDetailsState

  const [eventDetails, setEventDetails] = useState<EventDetails>()

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const [staffDefaultData, setStaffDefaultData] = useState<Staff[]>([])

  // Add a new state variable to store the initial data
  const [initialData, setInitialData] = useState({
    name: '',
    event_datetime: [] as EventDatetime[],
    gender: '',
    event_category: '',
    location: '',
    ticketType: '',
    approvalRequired: '',
    complimentaryDrink: '',
    description: '',
    general_info: '',
    rulesAndRegulations: '',
    assign_staff: [],
    gallery: [],
    sitemap: '' as any,
    pastEventGallery: [],
    ticketDetails: [] as TicketCategory[],
    isFeatured: 0,
    city: '',
    max_ticket: 0,
    site_map_enable: 0,

  })

  // Get Event Details
  const fetchEventDetails = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/eventDetails/${eventId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((response) => response.json())
      .then((data) => {
        setEventDetails(data)

        const eventStaffIds = data.event_staff
        const preFillData = eventStaffIds.map((staff: Staff) => ({
          label: staff.name,
          value: staff.id.toString(),
        }))
        setStaffDefaultData(preFillData)

        // Set the selectedStaff as the default values
        const selectedOption = eventStaffIds.map((staff: Staff) => staff.id)
        const values = selectedOption.map(Number)
        setSelectedValues(values)
        // console.log('Event Details selected values:', selectedValues);

        // Assuming data.event_datetime is an array of strings in the format "event_start_datetime | event_end_datetime"
        const eventDatetimeArray = data.event_datetime || []
        const eventDatetimeValues = eventDatetimeArray.map((datetimeString) => {
          const [event_start_datetime, event_end_datetime] = datetimeString.split(' | ')
          const localEventStartDatetime = convertToLocaleDatetime(event_start_datetime)
          const localEventEndDatetime = convertToLocaleDatetime(event_end_datetime)
          return {
            event_start_datetime: localEventStartDatetime,
            event_end_datetime: localEventEndDatetime,
          }
        })

        setDatetimeInputs(eventDatetimeValues)
        setSelectedGalleryImages(
          data.gallery.map((url) => ({
            data: url,
            isVideo: ['.mp4', '.webm', '.ogg'].some((extension) => url.endsWith(extension)),
          })) || []
        )
        if (data.site_map_enable === 1) {
          setSelectedSitemap(
            data.sitemap
              ? [
                  {
                    data: data.sitemap,
                    isVideo: ['.mp4', '.webm', '.ogg'].some((extension) =>
                      data.sitemap.endsWith(extension)
                    ),
                  },
                ]
              : []
          );
        }
        setSelectedPastEventGallery(
          data.pastEventGallery.map((url) => ({
            data: url,
            isVideo: ['.mp4', '.webm', '.ogg'].some((extension) => url.endsWith(extension)),
          })) || []
        )
        if (!data.no_of_tables) {
          data.no_of_tables = 0
        }

        formik.setValues({
          ...data,
          event_datetime: eventDatetimeValues, // Set the event_datetime field with the parsed values
        })

        setInitialData(formik.values) // Store the initial form values
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
      })
  }

  // Converting Local Date Time into UTC
  function convertToLocaleDatetime(utcDatetimeString) {
    const utcDatetime = new Date(utcDatetimeString + ' UTC')
    const year = utcDatetime.getFullYear()
    const month = String(utcDatetime.getMonth() + 1).padStart(2, '0')
    const day = String(utcDatetime.getDate()).padStart(2, '0')
    const hours = String(utcDatetime.getHours()).padStart(2, '0')
    const minutes = String(utcDatetime.getMinutes()).padStart(2, '0')
    const seconds = String(utcDatetime.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  interface Category {
    id: number
    event_category: string
    status: string
  }
  const [eventCategory, setEventCategory] = useState<Category[]>([])

  interface Ticketcategory {
    id: number
    name: string
  }
  const [categoryData, setCategoryData] = useState<Ticketcategory[]>([])

  // Get ALL Event-Category List
  const fetchEventCategory = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp)
          setEventCategory(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
      })
  }

  const [staffData, setStaffData] = useState<Staff[]>([])

  //Get All Staff List
  const fetchStaffData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allStaff`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setStaffData(
            resp.map((staff: Staff) => ({label: staff.name, value: staff.id.toString()}))
          )
          // console.log("Result", resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // Get all Event-Category List
  const fetchTicketCategoryData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allTicketCategory`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setCategoryData(resp.data)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // Fetch Event Details when the page loads
  useEffect(() => {
    fetchEventDetails()
    fetchEventCategory()
    fetchStaffData()
    fetchTicketCategoryData()
  }, [eventId])

  const [datetimeInputs, setDatetimeInputs] = useState(['']) // Initial input

  const [selectedGalleryImages, setSelectedGalleryImages] = useState<
    {data: string; isVideo: boolean}[]
  >([])
  const GalleryInputRef = React.useRef<HTMLInputElement>(null)

  const handleGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    // console.log(file)

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(
        (extension) => file.type === extension
      )
      formik.setFieldValue('gallery', [...formik.values.gallery, file])
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedGalleryImages((prevImages) => [
          ...prevImages,
          {data: reader.result as string, isVideo},
        ])
      }
      reader.readAsDataURL(file)
    }
  }

  const removeGalleryImage = (index: number) => {
    setSelectedGalleryImages((prevImages) => {
      const newImages = [...prevImages]
      newImages.splice(index, 1)
      formik.setFieldValue('gallery', newImages)

      // Clear the file input field's value
      if (GalleryInputRef.current) {
        GalleryInputRef.current.value = ''
      }

      return newImages
    })
  }

  const [selectedSitemap, setSelectedSitemap] = useState<{data: string; isVideo: boolean}[]>([])
  const SitemapInputRef = React.useRef<HTMLInputElement>(null)

  const handleSitemap = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(
        (extension) => file.type === extension
      )
      formik.setFieldValue('sitemap', file) // Update formik values
      const reader = new FileReader()
      reader.onload = () => {
        const dataURL = reader.result as string
        setSelectedSitemap((prevImages) => [
          ...prevImages,
          {data: reader.result as string, isVideo},
        ])
      }
      reader.readAsDataURL(file)
    }
  }
  const removeSitemap = (index: number) => {
    setSelectedSitemap((prevImages) => {
      const newImages = [...prevImages]
      newImages.splice(index, 1)
      formik.setFieldValue('sitemap', '') // Update formik values

      // Clear the file input field's value
      if (SitemapInputRef.current) {
        SitemapInputRef.current.value = ''
      }

      return newImages
    })
  }

  const [selectedPastEventGallery, setSelectedPastEventGallery] = useState<
    {data: string; isVideo: boolean}[]
  >([]) //<string[]>([])
  const PastEventGalleryInputRef = React.useRef<HTMLInputElement>(null)

  const handlePastEventGallery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].some(
        (extension) => file.type === extension
      )
      formik.setFieldValue('pastEventGallery', [...formik.values.pastEventGallery, file])
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedPastEventGallery((prevImages) => [
          ...prevImages,
          {data: reader.result as string, isVideo},
        ])
      }
      reader.readAsDataURL(file)
    }
  }

  const removePastEventGallery = (index: number) => {
    setSelectedPastEventGallery((prevImages) => {
      const newImages = [...prevImages]
      newImages.splice(index, 1)
      formik.setFieldValue('pastEventGallery', newImages)

      // Clear the file input field's value
      if (PastEventGalleryInputRef.current) {
        PastEventGalleryInputRef.current.value = ''
      }

      return newImages
    })
  }

  const [ticketDetails, setTicketDetails] = useState<any[]>([
    {
      ticketCategory: '',
      noOfTickets: '',
      price: '',
      entry: '',
    },
  ])

  interface TicketCategory {
    ticketId: number
    ticketCategory: string
    noOfTickets: string
    price: string
    entry: string
  }

  interface EventDatetime {
    event_start_datetime: string
    event_end_datetime: string
  }

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  // For warning message about video upload time
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isChangesMade, setIsChangesMade] = useState(true)

  const formik = useFormik({
    initialValues: {
      name: '',
      // subtitle: '',
      event_datetime: [] as EventDatetime[],
      gender: '',
      event_category: '',
      location: '',
      ticketType: '',
      approvalRequired: '',
      complimentaryDrink: '',
      description: '',
      general_info: '',
      rulesAndRegulations: '',
      assign_staff: [],
      gallery: [],
      sitemap: '' as any,
      pastEventGallery: [],
      ticketDetails: [] as TicketCategory[],
      isFeatured: 0,
      city: '',
      no_of_tables: '',
      max_ticket: 0,
      site_map_enable: 0,
    },
    validationSchema: eventSchema,
    onSubmit: async (values) => {
      setIsChangesMade(true)
      setLoading(true)
      // console.log(values.gallery)

      const s3Image = new ImageHandler()
      const gallery: string[] = []

      const isAnyFileAVideo = [...values.gallery, values.sitemap, ...values.pastEventGallery].some(
        (file) =>
          file instanceof File &&
          ['.mp4', '.webm', '.ogg'].some((extension) => file.name.endsWith(extension))
      )

      if (isAnyFileAVideo) {
        setIsVideoUploading(true)
        // Show a warning message about video upload time
      }

      for (const image of values.gallery) {
        if (typeof image === 'string') {
          // The image is already a URL, so just push it to the array.
          gallery.push(image)
        } else {
          // The image is a file, so upload it to S3 and get the URL.
          const gallery_image = await s3Image.upload(image, 'event/gallery/')
          if (gallery_image instanceof Error) {
            console.error(gallery_image)
            return
          }
          gallery.push(gallery_image)
        }
      }
      // console.log(gallery)

      // if (typeof values.sitemap !== 'string') {
      //   values.sitemap = await s3Image.upload(values.sitemap, 'event/sitemap/')
      //   if (values.sitemap instanceof Error) {
      //     console.error(values.sitemap)
      //     return
      //   }
      // }
      if (values.site_map_enable === 1) {
        if (typeof values.sitemap !== 'string') {
          values.sitemap = await s3Image.upload(values.sitemap, 'event/sitemap/');
          
          if (values.sitemap instanceof Error) {
            console.error(values.sitemap);
            return;
          }
        }
      } else {
        values.sitemap = null;
      }

      const pastEventGallery: string[] = []
      for (const image of values.pastEventGallery) {
        if (typeof image === 'string') {
          // The image is already a URL, so just push it to the array.
          pastEventGallery.push(image)
        } else {
          // The image is a file, so upload it to S3 and get the URL.
          const pastEventGallery_image = await s3Image.upload(image, 'event/pastEventGallery/')
          if (pastEventGallery_image instanceof Error) {
            console.error(pastEventGallery_image)
            return
          }
          pastEventGallery.push(pastEventGallery_image)
        }
      }

      const utcDatetimeArray: {event_start_datetime: string; event_end_datetime: string}[] = []

      for (const date of values.event_datetime) {
        const startDatetime = date.event_start_datetime // Assuming 'date' is in local time
        const endDatetime = date.event_end_datetime // Assuming 'date' is in local time

        // Create new objects with ISO formatted strings
        const isoDate = {
          event_start_datetime: startDatetime,
          event_end_datetime: endDatetime,
        }

        utcDatetimeArray.push(isoDate)
      }

      const formData = {
        id: eventId,
        name: values.name,
        // subtitle: values.subtitle,
        event_datetime: utcDatetimeArray,
        gender: values.gender,
        event_category: values.event_category,
        location: values.location,
        ticketType: values.ticketType,
        approvalRequired: values.approvalRequired,
        complimentaryDrink: values.complimentaryDrink,
        description: values.description,
        general_info: values.general_info,
        rulesAndRegulations: values.rulesAndRegulations,
        event_staff: values.assign_staff,
        gallery: gallery,
        sitemap: values.sitemap,
        pastEventGallery: pastEventGallery,
        ticketDetails: values.ticketDetails,
        isFeatured: values.isFeatured === 1 ? true : false,
        site_map_enable: values.site_map_enable === 1 ? true : false,
        city: values.city,
        no_of_tables: values.no_of_tables,
        max_ticket: values.max_ticket,
        
      }
      console.log('Form Data:', formData)


      // // Before submitting the form, compare the form data with the initial data
      // const changedValues = Object.keys(formik.values).reduce((acc, key) => {
      //   // Use lodash's isEqual function to deep compare the values
      //   if (!_.isEqual(formik.values[key], initialData[key])) {
      //     acc[key] = formik.values[key];
      //   }
      //   return acc;
      // }, {});

      // // If no fields have changed, navigate to '/events'
      // if (Object.keys(changedValues).length === 0) {
      //   setIsChangesMade(false);
      //   setLoading(false);
      //   return
      // }
      // // Otherwise, use changedValues as your form data
      // const formData = {
      //   ...changedValues,
      //   id: eventId, // Always include the event ID
      // };

      // setLoading(false);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/editEvent`, {
          method: 'PUT',
          headers: {
            Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
            'Content-Type': 'application/json', // You might need this line depending on your API
          },
          body: JSON.stringify(formData), // Send the form values as JSON data
        })

        if (response.ok) {
          // console.log('Event details updated successfully')
          setLoading(false)
          setIsVideoUploading(false)
          navigate('/events')
          // Handle success actions, such as showing a success message or navigating
        } else {
          console.error('Failed to update event details')
          setLoading(false)
          // Handle error scenarios
        }
      } catch (error) {
        console.error('Error updating event:', error)
        // Handle fetch error
      }
    },
  })
  const [maxTicketDisabled, setMaxTicketDisabled] = useState(false);
  useEffect(() => {
    if (formik.values.ticketType === '') {
      formik.setFieldValue('max_ticket', 0);
      setMaxTicketDisabled(true);
    } else if (formik.values.ticketType === 'Single') {
      formik.setFieldValue('max_ticket', 1);
      setMaxTicketDisabled(true);
    } else if (formik.values.ticketType === 'Multiple') {
      setMaxTicketDisabled(false);
    }
  }, [formik.values.ticketType]);

  const [selectedValues, setSelectedValues] = useState([])

  const handleSelectChange = (selectedOptions) => {
    const selectedOption = selectedOptions.map((option) => option.value)
    const values = selectedOption.map(Number)
    setSelectedValues(values)
  }

  useEffect(() => {
    // console.log('Updated selected values:', selectedValues);
    formik.setFieldValue('assign_staff', selectedValues)
  }, [selectedValues])
  
  const handleSiteMapEnableChange = (value: number) => {
    formik.setFieldValue('site_map_enable', value);
  
    if (value === 0) {
      formik.setFieldValue('sitemap', null); // Remove sitemap when disabled
      setSelectedSitemap([]);
    }
  };
  

  return (
    <div>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0 d-flex align-items-center'>
          <Link
            to=''
            onClick={() => navigate(-1)}
            state={{eventId: eventId}}
            className='btn btn-sm btn-icon btn-active-color-primary text-end'
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <h3 className='fw-bolder m-0'>Edit Event</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Event Date</label>
              <div className='col-lg-8'>
                {datetimeInputs.map((input, index) => (
                  <div className='row mb-3' key={index}>
                    <div className='col-lg-5 fv-row'>
                      <label className='mb-3 mb-lg-0'>Start Date Time</label>
                      <input
                        type='datetime-local'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        {...formik.getFieldProps(`event_datetime[${index}].event_start_datetime`)}
                        readOnly
                        // min={currentDateTimeISOString}
                      />
                      {/* {formik.errors.event_datetime &&
                        (formik.errors.event_datetime[index] as FormikErrors<EventDatetime>)?.event_start_datetime && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {(formik.errors.event_datetime[index] as FormikErrors<EventDatetime>).event_start_datetime}
                            </div>
                          </div>
                        )} */}
                    </div>
                    <div className='col-lg-5 fv-row'>
                      <label className='mb-3 mb-lg-0'>End Date Time</label>
                      <input
                        type='datetime-local'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        {...formik.getFieldProps(`event_datetime[${index}].event_end_datetime`)}
                        readOnly
                        // min={currentDateTimeISOString}
                      />
                      {/* {formik.errors.event_datetime &&
                        (formik.errors.event_datetime[index] as FormikErrors<EventDatetime>)?.event_end_datetime && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {(formik.errors.event_datetime[index] as FormikErrors<EventDatetime>).event_end_datetime}
                            </div>
                          </div>
                        )} */}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Event Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Event-name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Is Featured</label>
              <div className='col-lg-8 fv-row'>
                <div className='row'>
                  <div className='col-lg-3 fv-row'>
                    <label
                      className='col-lg-12 col-form-label fw-bold fs-6'
                      htmlFor='flexRadioDefault1'
                      style={{color: 'black'}}
                    >
                      <input
                        className='form-check-input me-2'
                        type='radio'
                        id='flexRadioDefault1'
                        {...formik.getFieldProps('isFeatured')}
                        checked={formik.values.isFeatured === 1}
                        onChange={() => formik.setFieldValue('isFeatured', 1)}
                        onClick={(event) => {
                          if (formik.values.isFeatured === 1) {
                            formik.setFieldValue('isFeatured', undefined)
                          }
                        }}
                      />
                      Yes
                    </label>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <label
                      className='col-lg-12 col-form-label fw-bold fs-6'
                      htmlFor='flexRadioDefault2'
                    >
                      <input
                        className='form-check-input me-2'
                        type='radio'
                        id='flexRadioDefault2'
                        {...formik.getFieldProps('isFeatured')}
                        checked={formik.values.isFeatured === 0}
                        onChange={() => formik.setFieldValue('isFeatured', 0)}
                        onClick={(event) => {
                          if (formik.values.isFeatured === 0) {
                            formik.setFieldValue('isFeatured', undefined)
                          }
                        }}
                      />
                      No
                    </label>
                  </div>
                  {formik.errors.isFeatured && formik.touched.isFeatured && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.isFeatured}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Sub Title</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Sub-title'
                  {...formik.getFieldProps('subtitle')}
                />
                {formik.touched.subtitle && formik.errors.subtitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.subtitle}</div>
                  </div>
                )}
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Location</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='location'
                  {...formik.getFieldProps('location')}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.location}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('city')}
                >
                  <option value=''>Select</option>
                  <option value='Doha'>Doha</option>
                  <option value='Lusail'>Lusail</option>
                  <option value='Rayyan'>Rayyan</option>
                </select>
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Admin Approval</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('approvalRequired')}
                  disabled
                >
                  <option value=''>Select</option>
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                {formik.touched.approvalRequired && formik.errors.approvalRequired && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.approvalRequired}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Gender</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('gender')}
                >
                  <option value=''>Select</option>
                  <option value='M'>Male</option>
                  <option value='F'>Female</option>
                  <option value='A'>Any</option>
                </select>
                {formik.touched.gender && formik.errors.gender && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gender}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Category</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('event_category')}
                >
                  <option value=''>Select</option>
                  {eventCategory.map(
                    (category) =>
                      category.status === 'Active' && (
                        <option key={category.id} value={category.id}>
                          {category.event_category}
                        </option>
                      )
                  )}
                </select>
                {formik.touched.event_category && formik.errors.event_category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.event_category}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Ticket Buy Type</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('ticketType')}
                >
                  <option value=''>Select</option>
                  <option value='Single'>Single</option>
                  <option value='Multiple'>Multiple</option>
                </select>
                {formik.touched.ticketType && formik.errors.ticketType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ticketType}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Max Ticket</label>
        <div className='col-lg-8 fv-row'>
          <input
            type='number'
            min={0}
            className='form-control form-control-lg form-control-solid'
            placeholder='max_ticket'
            {...formik.getFieldProps('max_ticket')}
            disabled={maxTicketDisabled} 
          />
          {formik.touched.max_ticket && formik.errors.max_ticket && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.max_ticket}</div>
            </div>
          )}
        </div>
      </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Complimentary Drink</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('complimentaryDrink')}
                >
                  <option value=''>Select</option>
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                {formik.touched.complimentaryDrink && formik.errors.complimentaryDrink && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.complimentaryDrink}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>No of Tables</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  min={0}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Event-name'
                  {...formik.getFieldProps('no_of_tables')}
                />
                {formik.touched.no_of_tables && formik.errors.no_of_tables && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.no_of_tables}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>General Information</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('general_info')}
                />
                {formik.touched.general_info && formik.errors.general_info && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.general_info}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Rules and Regulations</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={4}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  {...formik.getFieldProps('rulesAndRegulations')}
                />
                {formik.touched.rulesAndRegulations && formik.errors.rulesAndRegulations && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.rulesAndRegulations}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Assign Staff</span>
              </label>

              <div className='col-lg-8'>
                {staffDefaultData.length > 0 && (
                  <Select
                    defaultValue={staffDefaultData}
                    isMulti
                    name='colors'
                    options={staffData}
                    className='basic-multi-select form-control form-control-lg form-control-solid'
                    classNamePrefix='select'
                    onChange={handleSelectChange}
                  />
                )}
                {formik.touched.assign_staff && formik.errors.assign_staff && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.assign_staff}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Gallery</label>
              <div className='col-lg-8'>
                <div className='image-input image-input-outline d-flex' data-kt-image-input='true'>
                  {/* {selectedGalleryImages.map((image, index) => (
                    <div
                      key={index}
                      className='image-input-wrapper w-125px h-125px mx-2'
                      style={{
                        backgroundImage: `url("${image || toAbsoluteUrl('/media/avatars/blank.png')
                          }")`,
                      }}
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-danger position-absolute top-0 end-0'
                        onClick={() => removeGalleryImage(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))} */}
                  {selectedGalleryImages.map((image, index) => {
                    return (
                      <div>
                        {image.isVideo ? (
                          <video width='125' height='125' controls>
                            <source src={image.data} type='video/mp4' />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            key={index}
                            className='image-input-wrapper w-125px h-125px mx-2'
                            style={{
                              backgroundImage: `url("${
                                image.data || toAbsoluteUrl('/media/avatars/blank.png')
                              }")`,
                            }}
                          ></div>
                        )}
                        <button
                          type='button'
                          className='btn btn-sm btn-danger position-absolute top-0 end-0'
                          onClick={() => removeGalleryImage(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )
                  })}
                  {selectedGalleryImages.length < 5 && (
                    <div>
                      <div className='image-input-overlay'>
                        <i className='bi bi-plus fs-3'></i>
                        <input type='file' ref={GalleryInputRef} onChange={handleGallery} />
                      </div>
                    </div>
                  )}
                </div>
                {formik.touched.gallery && formik.errors.gallery && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gallery}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Floor Plan Enable</label>
              <div className='col-lg-8 fv-row'>
                <div className='row'>
                  <div className='col-lg-3 fv-row'>
                    <label
                      className='col-lg-12 col-form-label fw-bold fs-6'
                      htmlFor='flexRadioDefault1'
                      style={{color: 'black'}}
                    >
                      <input
                        className='form-check-input me-2'
                        type='radio'
                        id='flexRadioDefault1'
                        {...formik.getFieldProps('site_map_enable')}
                        checked={formik.values.site_map_enable === 1}
                        onChange={() => handleSiteMapEnableChange(1)}
                        onClick={(event) => {
                          if (formik.values.site_map_enable === 1) {
                            formik.setFieldValue('site_map_enable', undefined)
                          }
                        }}
                      />
                      Yes
                    </label>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <label
                      className='col-lg-12 col-form-label fw-bold fs-6'
                      htmlFor='flexRadioDefault2'
                    >
                      <input
                        className='form-check-input me-2'
                        type='radio'
                        id='flexRadioDefault2'
                        {...formik.getFieldProps('site_map_enable')}
                        checked={formik.values.site_map_enable === 0}
                        onChange={() => handleSiteMapEnableChange(0)}
                        onClick={(event) => {
                          if (formik.values.site_map_enable === 0) {
                            formik.setFieldValue('site_map_enable', undefined)
                          }
                        }}
                      />
                      No
                    </label>
                  </div>
                  {formik.errors.site_map_enable && formik.touched.site_map_enable && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.site_map_enable}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {formik.values.site_map_enable === 1 && (
  <div className='row mb-6'>
    <label className='col-lg-4 col-form-label fw-bold fs-6'>Floor Plan</label>
    <div className='col-lg-8'>
      <div className='image-input image-input-outline d-flex' data-kt-image-input='true'>
        {selectedSitemap.map((image, index) => (
          <div key={index}>
            {image.isVideo ? (
              <video width='125' height='125' controls>
                <source src={image.data} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div
                className='image-input-wrapper w-125px h-125px mx-2'
                style={{ backgroundImage: `url("${image.data}")` }}
              />
            )}
            <button
              type='button'
              className='btn btn-sm btn-danger position-absolute top-0 end-0'
              onClick={() => removeSitemap(index)}
            >
              Remove
            </button>
          </div>
        ))}

        {selectedSitemap.length < 1 && (
          <div>
            <div className='image-input-overlay'>
              <i className='bi bi-plus fs-3'></i>
              <input type='file' ref={SitemapInputRef} onChange={handleSitemap} />
            </div>
          </div>
        )}
      </div>
      {formik.touched.sitemap && formik.errors.sitemap && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors.sitemap as React.ReactNode}</div>
        </div>
      )}
    </div>
  </div>
)}

         
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Past Event Gallery</label>
              <div className='col-lg-8'>
                <div className='image-input image-input-outline d-flex' data-kt-image-input='true'>
                  {/* {selectedPastEventGallery.map((image, index) => (
                    <div
                      key={index}
                      className='image-input-wrapper w-125px h-125px mx-2'
                      style={{
                        backgroundImage: `url("${
                          image || toAbsoluteUrl('/media/avatars/blank.png')
                        }")`,
                      }}
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-danger position-absolute top-0 end-0'
                        onClick={() => removePastEventGallery(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))} */}
                  {selectedPastEventGallery.map((image, index) => {
                    return (
                      <div>
                        {image.isVideo ? (
                          <video width='125' height='125' controls>
                            <source src={image.data} type='video/mp4' />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            key={index}
                            className='image-input-wrapper w-125px h-125px mx-2'
                            style={{
                              backgroundImage: `url("${
                                image.data || toAbsoluteUrl('/media/avatars/blank.png')
                              }")`,
                            }}
                          ></div>
                        )}
                        <button
                          type='button'
                          className='btn btn-sm btn-danger position-absolute top-0 end-0'
                          onClick={() => removePastEventGallery(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )
                  })}

                  {selectedPastEventGallery.length < 5 && (
                    // <div className='image-input-wrapper w-125px h-125px mx-2'>
                    <div>
                      <div className='image-input-overlay'>
                        <i className='bi bi-plus fs-3'></i>
                        <input
                          type='file'
                          ref={PastEventGalleryInputRef}
                          onChange={handlePastEventGallery}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {formik.touched.pastEventGallery && formik.errors.pastEventGallery && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.pastEventGallery}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Ticket Details
              </label>

              <div className='col-lg-8'>
                {eventDetails &&
                  eventDetails.ticketDetails.map((category, index) => (
                    <div className='row' key={index}>
                      <div className='col-lg-3 fv-row'>
                        <label className='mb-3 mb-lg-0'>Ticket Category</label>
                        {formik.values.approvalRequired === '1' ? (
                          <select
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            {...formik.getFieldProps(`ticketDetails[${index}].ticketCategory`)}
                            disabled
                          >
                            <option value='Free'>Guest List</option>
                          </select>
                        ) : (
                          <select
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            {...formik.getFieldProps(`ticketDetails[${index}].ticketCategory`)}
                          >
                            <option value='ticketDetails[${index}].ticketCategory'>
                              {category.ticketCategory}
                            </option>
                            {/* <option value=''>Select</option> */}
                            {/* {categoryData.map(
                              (category) =>
                                <option key={category.id} value={category.name}>
                                  {category.name}
                                </option>
                            )} */}
                            {/* <option value='Standard'>Standard</option>
                            <option value='Gold'>Gold</option> */}
                          </select>
                        )}
                        {formik.touched.ticketDetails &&
                          formik.errors.ticketDetails &&
                          (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                            ?.ticketCategory && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {
                                  (
                                    formik.errors.ticketDetails[
                                      index
                                    ] as FormikErrors<TicketCategory>
                                  ).ticketCategory
                                }
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='col-lg-3 fv-row'>
                        <label className='mb-3 mb-lg-0'>No of Tickets</label>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder=''
                          {...formik.getFieldProps(`ticketDetails[${index}].noOfTickets`)}
                          // readOnly
                        />
                        {formik.touched.ticketDetails &&
                          formik.errors.ticketDetails &&
                          (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                            ?.noOfTickets && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {
                                  (
                                    formik.errors.ticketDetails[
                                      index
                                    ] as FormikErrors<TicketCategory>
                                  ).noOfTickets
                                }
                              </div>
                            </div>
                          )}
                      </div>

                      {eventDetails.approvalRequired === '1' ? (
                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Price</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            // {...formik.getFieldProps(`ticketDetails[${index}].price`)}
                            readOnly
                            value='0'
                          />
                        </div>
                      ) : (
                        <div className='col-lg-3 fv-row'>
                          <label className='mb-3 mb-lg-0'>Price</label>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps(`ticketDetails[${index}].price`)}
                            // readOnly
                          />
                        </div>
                      )}

                      <div className='col-lg-2 fv-row'>
                        <label className='mb-3 mb-lg-0'>Entry</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder=''
                          {...formik.getFieldProps(`ticketDetails[${index}].entry`)}
                          // readOnly
                        />
                        {formik.touched.ticketDetails &&
                          formik.errors.ticketDetails &&
                          (formik.errors.ticketDetails[index] as FormikErrors<TicketCategory>)
                            ?.entry && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {
                                  (
                                    formik.errors.ticketDetails[
                                      index
                                    ] as FormikErrors<TicketCategory>
                                  ).entry
                                }
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {isVideoUploading && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  Uploading video files may take some time due to their large size. Please be
                  patient and do not refresh or close the page.
                </div>
              </div>
            )}
            {!isChangesMade && (
              <div className='fv-plugins-message-container text-end'>
                <div className='fv-help-block'>No changes to save.</div>
              </div>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/events' className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
              Cancel
            </Link>
            <button type='submit' className='btn btn-primary' disabled={!formik.dirty || loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default EditEvent
