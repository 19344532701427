import {Link, useLocation} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {useEffect, useState} from 'react'

interface LocationState {
  support: Support
}

export interface Support {
  id: number
  subject: string
  name: string
  date_created: string
  email: string
  message: string
  mobile_number: string
  status: string
  action: string
}

const CompleteSupportDetails: React.FC = () => {
  const location = useLocation()
  const support = location.state as Support
  const [selectedStatus, setSelectedStatus] = useState(support.status)
  const [selectedAction, setSelectedAction] = useState(support.action)

  console.log(support)
  useEffect(() => {
    if (selectedStatus === 'pending') {
      setSelectedAction('')
    }
  }, [selectedStatus, selectedAction])

  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div
        className='card-title m-0 d-flex align-items-center mb-10'
        style={{display: 'flex', justifyContent: 'space-between'}}
      >
        <h3 className='card-title align-items-start flex-column'>
          <Link
            to='/settings/support/completed-logs'
            className='btn btn-sm btn-icon btn-active-color-primary text-end'
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <span className='card-label fw-bold fs-2 mb-1'>Completed Logs Support Details</span>
        </h3>
        <div className='card-toolbar '>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-300px ps-14'
              placeholder='Complaint ID'
              value={`compliant id: ${support.id}`}
              disabled
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              className='form-select form-select-solid form-select-lg-2'
              value={selectedStatus}
              disabled
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex flex-end align-items-center position-relative my-1'>
            <input
              className='form-select form-select-solid form-select-lg-2'
              value={selectedAction}
              disabled
            />
          </div>
          {/* end::Search */}
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>Subject</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Subject'
            value={support.subject}
            disabled
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>User</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='User'
            value={support.name}
            disabled
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>Complain Creation Date</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Complain Creation Date'
            value={new Date(support.date_created).toLocaleDateString()}
            disabled
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>Email</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Email'
            value={support.email}
            disabled
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>Message</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <textarea
            rows={4}
            className='form-control form-control-lg form-control-solid'
            placeholder=''
            value={support.message}
            disabled
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>
          <span className='required'>Phone Number</span>
        </label>

        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Phone Number'
            value={support.mobile_number}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default CompleteSupportDetails
