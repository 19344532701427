import React, { FC, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getAuthorizationToken } from '../../../authorization-token'
import PhoneInput from 'react-phone-input-2'

const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    // staffId: Yup.string().required('Staff ID is required'),
    genderId: Yup.string().required('Gender is required'),
    mobileNumber: Yup.string().required('Mobile Number is required').matches(/^\d{8}$/, 'Mobile Number must be of 8 digits'),
})

const EditStaff: FC = () => {

    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [staffIdError, setStaffIdError] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mobile = queryParams.get('mobileNumber')?.trim() || ''
    const [country, number] = mobile.split(' ');
    const initialValues = {
        id: queryParams.get('id')?.trim() || '',
        name: queryParams.get('name')?.trim() || '',
        email: queryParams.get('email')?.trim() || '',
        staffId: queryParams.get('staffId')?.trim() || '',
        genderId: queryParams.get('genderId')?.trim() || '', // Will be '1' for male and '2' for female
        country_code: country || '',
        mobileNumber: number || '',
    };

    const handleChange = (value: string, dialCode: any) => {
        const countryCode = '+' + dialCode.dialCode;
        const charsToRemove = countryCode.length - 1;
        const newValue = value.slice(charsToRemove);
        // console.log('Value mobile number:', newValue);
        // console.log('Country code:', countryCode);
        formik.setFieldValue('mobileNumber', newValue)
        formik.setFieldValue('country_code', countryCode)
    };

    // Function to generate the payload with only the changed fields
    const generatePayload = (values: typeof initialValues) => {
        const payload: any = {}; // Change 'any' to the actual type of the payload

        // Add fields to the payload if they are changed from the initial values
        if (values.name !== initialValues.name) {
            payload.id = values.id;
            payload.name = values.name;
        }
        if (values.email !== initialValues.email) {
            payload.id = values.id;
            payload.email = values.email;
        }
        if (values.staffId !== initialValues.staffId) {
            payload.id = values.id;
            payload.staffId = values.staffId;
        }
        if (values.genderId !== initialValues.genderId) {
            payload.id = values.id;
            payload.genderId = values.genderId;
        }
        if (values.country_code !== initialValues.country_code) {
            payload.id = values.id;
            payload.mobile = values.country_code;
        }
        if (values.mobileNumber !== initialValues.mobileNumber) {
            payload.id = values.id;
            payload.mobile = values.mobileNumber;
        }


        return payload;
    };

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [modifiedFields, setModifiedFields] = useState<Record<string, string>>({});
    const formik = useFormik({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            const payload = generatePayload(values);
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/editStaff`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
                },
                body: JSON.stringify(payload),
            }).then((result) => {
                if (result.status === 200) {
                    // Navigate to the /staff page
                    setLoading(false);
                    navigate('/staff');
                } else if (result.status === 422) {
                    result.json().then((data) => {
                        // console.log(data.error); 
                        setEmailError(false)
                        setMobileError(false)
                        setStaffIdError(false)
                        setLoading(false);
                        if (data.error === 'email already exists') return setEmailError(true);

                        if (data.error === 'mobile already exists') return setMobileError(true);

                        if (data.error === 'staffId already exists') return setStaffIdError(true);

                    }).catch((error) => {
                        console.error('Error parsing response body:', error);
                    });
                } else {
                    console.log("Error", result);
                }
            })
        },
    })

    return (
        <div>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0 d-flex align-items-center'>
                    <Link
                        to='/staff'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end'
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <h3 className='fw-bolder m-0'>Edit Staff</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Staff Name</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>Email Address</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                {emailError && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Email is already registered.</div>
                                    </div>
                                )}
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='email-address'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>Staff ID</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                {staffIdError && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Staff ID is already registered.</div>
                                    </div>
                                )}
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='staff-ID'
                                    readOnly
                                    {...formik.getFieldProps('staffId')}
                                />
                                {formik.touched.staffId && formik.errors.staffId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.staffId}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>Gender</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('genderId')}
                                >
                                    <option value=''>Select</option>
                                    <option value='1'>Male</option>
                                    <option value='2'>Female</option>
                                </select>
                                {formik.touched.genderId && formik.errors.genderId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.genderId}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>Mobile Number</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                {mobileError && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Mobile Number is already registered.</div>
                                    </div>
                                )}
                                 <div className='form-control form-control-lg form-control-solid'>
                                    <PhoneInput
                                        country={'qa'}
                                        countryCodeEditable={false}
                                        value={`${formik.values.country_code}${formik.values.mobileNumber}`}
                                        placeholder='Mobile Number'
                                        onChange={handleChange}
                                        // readOnly  // Add the readOnly attribute
                                    />
                                </div>
                                {/* <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='mobile-number'
                                    {...formik.getFieldProps('mobileNumber')}
                                /> */}
                                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.mobileNumber}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/staff'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={!formik.dirty || loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default EditStaff
