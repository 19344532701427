import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { KTIcon } from "../../../../_metronic/helpers";
import * as Yup from 'yup'
import { useFormik } from "formik";
import { getAuthorizationToken } from "../../../../authorization-token";

const codeSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    expiry_date: Yup.string().required('Expiry Date is required'),
    category: Yup.string().required('Category is required'),
    user_limit: Yup.string().required('User limit is required'),
    description: Yup.string().required('Description is required'),
    available_event_id: Yup.string().required('Event is required'),
    discount: Yup.number()
        .required('Discount is required!')
        .test(
            'Is positive?',
            'The number must be greater than 0!',
            (value) => value > 0
        ),
    discount_type: Yup.string().required('Discount Type required'),
})


const AddCode: FC = () => {

    interface Event {
        id: number;
        name: string;
    }

    const [events, setEvents] = useState<Event[]>([])

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    // Get ALL Event-Category List
    const fetchEvents = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getEvents`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setEvents(resp);
                })
            })
            .catch((error) => {
                console.error('Error fetching event data:', error);
            });
    }

    // For Get ALL Event-Category List When page is load
    useEffect(() => {
        fetchEvents();
    }, [])

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            name: '',
            expiry_date: '',
            category: '',
            user_limit: '',
            description: '',
            available_event_id: '',
            discount: '',
            discount_type: '',

        },
        validationSchema: codeSchema,
        onSubmit: (values) => {
            const expiryDatetime = new Date(values.expiry_date)
            const formData = {
                name: values.name,
                expiry_date: expiryDatetime.toISOString(),
                category: values.category,
                user_limit: Number(values.user_limit),
                description: values.description,
                available_event_id: Number(values.available_event_id),
                discount: Number(values.discount),
                discount_type: values.discount_type
            };
            // console.log("FormData", formData);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/addCode`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${REACT_APP_AUTHORIZATION_TOKEN}`
                },
                body: JSON.stringify(formData),
            }).then((result) => {
                if (result.status === 200) {
                    // Navigate to the /settings/discount-code/ page
                    setLoading(true);
                    navigate('/settings/discount-code');
                } else {
                    console.log("Error", result);
                }
            })
        },
    })

    return (
        <>
            <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='card-title align-items-start flex-column'>
                    <Link
                        to='/settings/discount-code/'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end '
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <span className='card-label fw-bold fs-2 mb-1'>Add Code</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                </h3>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {/* <form noValidate className='form'> */}
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Code Name</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='name'
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.name}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6 text-center'>Expire On</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <input
                                        type='datetime-local'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='First name'
                                        {...formik.getFieldProps('expiry_date')}
                                    />
                                    {formik.touched.expiry_date && formik.errors.expiry_date && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.expiry_date}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Category</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        {...formik.getFieldProps('category')}
                                    >
                                        <option value=''>Select</option>
                                        <option value='promo'>Promo Code</option>
                                        <option value='voucher'>Voucher Code</option>

                                    </select>
                                    {formik.touched.category && formik.errors.category && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.category}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6 text-center'>Event</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        {...formik.getFieldProps('available_event_id')}
                                    >
                                        <option value=''>Select</option>
                                        <option value='0'>All</option>
                                        {events.map(event => (
                                            <option key={event.id} value={event.id}>
                                                {event.name}
                                            </option>
                                        ))}

                                    </select>
                                    {formik.touched.available_event_id && formik.errors.available_event_id && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.available_event_id}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Discount Type</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        {...formik.getFieldProps('discount_type')}
                                    >
                                        <option value=''>Select</option>
                                        <option value='Fixed'>Fixed</option>
                                        <option value='Percentage'>Percentage</option>

                                    </select>
                                    {formik.touched.discount_type && formik.errors.discount_type && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.discount_type}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6 text-center'>Used</label>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        {...formik.getFieldProps('user_limit')}
                                    >
                                        <option value=''>Select</option>
                                        <option value='1'>Once</option>
                                        <option value='2'>Twice</option>
                                        <option value='0'>No Limit</option>

                                    </select>
                                    {formik.touched.user_limit && formik.errors.user_limit && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.user_limit}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Discount</label>
                            <div className='col-lg-2'>
                                <div className='row'>
                                    <input
                                        type='number'
                                        min={0}

                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder=''
                                        {...formik.getFieldProps('discount')}
                                    />
                                    {formik.touched.discount && formik.errors.discount && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.discount}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Description</label>
                            <div className='col-lg-9'>
                                <div className='row'>
                                    <textarea
                                        rows={2}
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='   '
                                        {...formik.getFieldProps('description')}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.description}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/settings/discount-code/'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddCode;