import { FC, useEffect, useState } from "react";
import { formatDate } from "../../../../datetime.formate";
import { formatDateWithTime } from "../../../../datetime.formate";
import { getAuthorizationToken } from "../../../../authorization-token";
import PaginationComponent from "../../../../pagination";
import PlaceHolderComponent from "../../../../skeletol-view";



const RequestedUsers: FC<{ eventId: number }> = ({ eventId }) => {
    // console.log(eventId)
    interface Users {
        booking_id: number
        user_name: string
        requested_date: string
        total_tickets: number
    }

    const [requestedUsers, setRequestedUsers] = useState<Users[]>([]);
    const [loading, setLoading] = useState(true)

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    const fetchRequestedUsers = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getRequestedUsers/${eventId}`, {
            headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
        })
            .then((result) => {
                result.json().then((resp) => {
                    // console.log('Result', resp);
                    setLoading(false)
                    setRequestedUsers(resp);
                });
            })
            .catch((error) => {
                console.error('Error fetching paid event data:', error);
            });
    };

    useEffect(() => {
        fetchRequestedUsers();
    }, []);



    const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]); // Specify the type as number[] for selectedCheckboxes
    const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState<boolean>(false); // Specify the type as boolean for isHeaderCheckboxChecked

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkboxValue = parseInt(event.target.value, 10); // Parse the checkbox value as an integer
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((value) => value !== checkboxValue));
        }
    };

    const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setIsHeaderCheckboxChecked(isChecked);

        if (isChecked) {
            const allCheckboxValues = requestedUsers.map((event) => event.booking_id);
            setSelectedCheckboxes(allCheckboxValues);
        } else {
            setSelectedCheckboxes([]);
        }
    };

    const handleApproveClick = (action: string) => {
        // Perform your action with the selected checkbox values
        console.log('Selected checkboxes:', selectedCheckboxes);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/requestUpdate`, {
            method: 'PUT',
            headers: {
                Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ booking_ids: selectedCheckboxes, status: action }),
        })
            .then((result) => {
                if (result.status === 200) {
                    fetchRequestedUsers();
                    setSelectedCheckboxes([]);
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    const [currentPage, setCurrentPage] = useState(1)
    const usersPerPage = 10 // Number of items to display per page


    const indexOfLastData = currentPage * usersPerPage;
    const indexOfFirstData = indexOfLastData - usersPerPage;
    const currentData = requestedUsers.slice(indexOfFirstData, indexOfLastData);

    // Total number of pages
    const totalPages = Math.ceil(requestedUsers.length / usersPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <>
            <div className='card-header cursor-pointer text-end'>
                <button className='btn btn-primary align-self-center me-2' onClick={() => handleApproveClick('Approved')}>
                    Approve
                </button>

                <button className='btn btn-danger align-self-center' onClick={() => handleApproveClick('Rejected')}>
                    Reject
                </button>
            </div>
            <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='fw-bold text-muted bg-light'>
                            <th className=' text-center'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='1'
                                        data-kt-check='true'
                                        data-kt-check-target='.widget-9-check'
                                        checked={isHeaderCheckboxChecked}
                                        onChange={handleHeaderCheckboxChange}
                                    />
                                </div>
                            </th>
                            <th className='min-w-100px'>User Name</th>
                            <th className='min-w-100px'>Purchased Date</th>
                            <th className='min-w-100px'>Total Tickets</th>
                            <th className='min-w-100px text-center rounded-end'>Action</th>
                        </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {loading && (
                            <PlaceHolderComponent
                                tableHeaders={5}
                            />
                        )}
                        {currentData.map((event) => (
                            <tr key={event.booking_id}>
                                <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input widget-9-check'
                                            type='checkbox'
                                            value={event.booking_id}
                                            checked={selectedCheckboxes.includes(event.booking_id)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                </td>
                                <td className='align-middle'>
                                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {event.user_name}
                                    </a>
                                </td>
                                <td className='align-middle'>
                                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                                        {formatDate(event.requested_date)}
                                    </a>
                                </td>
                                <td className='align-middle'>
                                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                                        {event.total_tickets}
                                    </a>
                                </td>
                                <td className='text-center'>
                                    <a className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'>
                                        Approve/Reject
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}


            </div>
            {/* Pagination */}
            {totalPages > 1 && (
                <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    // pageNumbers={pageNumbers}
                    currentData={currentData}
                    filteredData={requestedUsers}
                    indexOfFirstData={indexOfFirstData}
                    indexOfLastData={indexOfLastData}
                />
            )}
        </>
    )
}

export default RequestedUsers