import { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { KTIcon } from "../../../../_metronic/helpers";
import { getAuthorizationToken } from "../../../../authorization-token";

const faqSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
})

const EditFAQs: FC = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const initialValues = {
        id: queryParams.get('id')?.trim() || '',
        question: queryParams.get('question')?.trim() || '',
        answer: queryParams.get('answer')?.trim() || '',
    };

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: faqSchema,
        onSubmit: (values) => {
            // console.log("values", values);
            const formData = {
                id: values.id,
                question: values.question,
                answer: values.answer
            };
            // console.log("FormData", formData);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/editFAQ`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${REACT_APP_AUTHORIZATION_TOKEN}`
                },
                body: JSON.stringify(formData),
            }).then((result) => {
                if (result.status === 200) {
                    // Navigate to the /settings/discount-code/ page
                    setLoading(true);
                    navigate('/settings/fAQs');
                } else {
                    setLoading(true);
                    console.log("Error", result);
                }
            })

        },
    })

    return (
        <>
            <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='card-title align-items-start flex-column'>
                    <Link
                        to='/settings/fAQs'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end '
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <span className='card-label fw-bold fs-2 mb-1'>Edit FAQ</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                </h3>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {/* <form noValidate className='form'> */}
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <label className='col-lg-9 col-form-label required fw-bold fs-6'>Question</label>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <textarea
                                        rows={1}
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='   '
                                        {...formik.getFieldProps('question')}
                                    />
                                    {formik.touched.question && formik.errors.question && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.question}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-9 col-form-label required fw-bold fs-6'>Answer</label>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <textarea
                                        rows={8}
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='   '
                                        {...formik.getFieldProps('answer')}
                                    />
                                    {formik.touched.answer && formik.errors.answer && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.answer}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/settings/fAQs'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditFAQs;
