import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDate, formatDateWithTime } from '../../../../datetime.formate'
import { getAuthorizationToken } from '../../../../authorization-token'
import * as XLSX from 'xlsx';
import PaginationComponent from '../../../../pagination'
import PlaceHolderComponent from '../../../../skeletol-view'


interface Earnings {
  id: number
  event_name: string
  location: string
  gender: string
  event_datetime: string[]
  booked_tickets: number
  total_earnings: number
}

const Earnings: FC = () => {
  const [earningsReport, setEarningsReport] = useState<Earnings[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get EarningsReport
  const fetchEarningsReport = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllEventEarnings`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("earningsReport :", data);
        setLoading(false)
        setEarningsReport(data)
      })
      .catch((error) => {
        console.error('Error fetching earningsReport :', error)
      })
  }

  // Fetch EarningsReport when the page loads
  useEffect(() => {
    fetchEarningsReport()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const earningsReportPerPage = 10

  // Get the earningsReport to display on the current page
  // For filteration by search
  const filteredData = earningsReport
    .filter(
      (earning) =>
        (earning.event_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          earning.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!selectedGenderFilter ||
          selectedGenderFilter === 'Any' || // Show any gender when "Any" is selected
          earning.gender === selectedGenderFilter) &&
        (!searchDate ||
          earning.event_datetime.some((date) => {
            const eventDate = new Date(date)
            const selectedDate = new Date(searchDate)
            return eventDate.toDateString() === selectedDate.toDateString()
          }))
    )
    .sort((a, b) => {
      if (selectedSortBY === 'dateNewest') {
        // Sort by newest first based on event date
        return new Date(a.event_datetime[0]).getTime() - new Date(b.event_datetime[0]).getTime();
      }
      if (selectedSortBY === 'dateOldest') {
        // Sort by oldest first based on event date
        return new Date(b.event_datetime[0]).getTime() - new Date(a.event_datetime[0]).getTime();
      }
      if (selectedSortBY === 'earningHighest') {
        // Sort by highest booked tickets first
        return b.total_earnings - a.total_earnings;
      }
      if (selectedSortBY === 'earningLowest') {
        // Sort by lowest booked tickets first
        return a.total_earnings - b.total_earnings;
      }
      if (selectedSortBY === 'bookingHighest') {
        // Sort by highest booked tickets first
        return b.booked_tickets - a.booked_tickets;
      }
      if (selectedSortBY === 'bookingLowest') {
        // Sort by lowest booked tickets first
        return a.booked_tickets - b.booked_tickets;
      }

      // Default sorting (no sorting)
      return 0;
    });

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedGenderFilter, searchDate]);

  const indexOfLastData = currentPage * earningsReportPerPage
  const indexOfFirstData = indexOfLastData - earningsReportPerPage
  const currentData = filteredData.slice(
    indexOfFirstData,
    indexOfLastData
  )

  const exportToExcel = () => {
    // Define your data as an array of objects
    const data = currentData.map((earnings) => ({
      'Event Date': earnings.event_datetime.map(formatDateWithTime).join(', '), // Customize column names as needed
      'Event Name': earnings.event_name,
      Location: earnings.location,
      'Booked Tickets': earnings.booked_tickets,
      Gender: earnings.gender === 'M' ? 'Male' : earnings.gender === 'F' ? 'Female' : 'Any',
      'Total Earnings': `QR ${earnings.total_earnings}`,
    }));

    // Create a new workbook and add a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Earnings Report');

    // Define the file name and save the Excel file
    const fileName = 'earnings_report.xlsx';
    XLSX.writeFile(wb, fileName);
  };


  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / earningsReportPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1)

  return (
    <>
      <div
        className='card-title m-0 d-flex align-items-center'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className='card-toolbar' style={{ flex: 1, marginRight: '10px' }}>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-362px ps-14'
              placeholder='Search by event name, location'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar' style={{ flex: 1, margin: '0 10px' }}>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <input
              type='date'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-180px ps-14'
              placeholder='Date'
              value={searchDate}
              onChange={(e) => setSearchDate(e.target.value)}
            />
            <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar' style={{ flex: 1, marginLeft: '10px' }}>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedGenderFilter || ''}
              onChange={(e) => {
                setSelectedGenderFilter(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Gender</option>
              <option value='M'>Male</option>
              <option value='F'>Female</option>
              <option value='A'>Any</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <select
              className='form-select form-select-solid form-select-lg-2'
              value={selectedSortBY || ''}
              onChange={(e) => {
                setSelectedSortBY(e.target.value)
              }}
            >
              {/*      */}
              <option value=''>Sort By</option>
              <option value='earningHighest'>Earning Highest First</option>
              <option value='earningLowest'>Earning Lowest First</option>
              <option value='bookingHighest'>Booking Highest First</option>
              <option value='bookingLowest'>Booking Lowest First</option>
              <option value='dateNewest'>Event Date Newest First</option>
              <option value='dateOldest'>Event Date Oldest First</option>
            </select>
          </div>
          {/* end::Search */}
        </div>
        <a
          href='#'
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
          onClick={exportToExcel}
        >
          <KTIcon iconName='arrow-up' className='fs-2' />
        </a>
      </div>

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Event Date</th>
                <th className='min-w-100px'>Event Name</th>
                <th className='min-w-100px'>Location</th>
                <th className='min-w-100px'>Booked Tickets</th>
                <th className='min-w-100px'>Gender</th>
                <th className='min-w-125px rounded-end text-center'>Total Earnings</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && (
                <PlaceHolderComponent
                  tableHeaders={6}
                />
              )}
              {currentData.map((earnings) => (
                <tr key={earnings.id}>
                  <td>
                    {earnings.event_datetime.length === 1 ? (
                      <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(earnings.event_datetime[0])}
                      </a>
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            {earnings.event_datetime.map((datetime) => (
                              <div key={datetime}>{formatDateWithTime(datetime)}</div>
                            ))}
                          </Tooltip>
                        }
                      >
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          Dates
                        </a>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {earnings.event_name}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {earnings.location}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {earnings.booked_tickets}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {earnings.gender === 'M'
                        ? 'Male'
                        : earnings.gender === 'F'
                          ? 'Female'
                          : 'Any'}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      QR {earnings.total_earnings}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
    </>
  )
}

export default Earnings
