import React, { FC, useState } from 'react'
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import BookingDetails from '../Events/BookingDetails'
import EventDetails from '../Events/EventDetails'
import Earnings from './Earnings/Earnings'
import Users from './Users/Users'
import Events from './Events/Events'

const ReportWrapper: FC = () => {
  return (
    <>
      <Outlet />
      <Routes>
        <Route
          element={
            <>
              <ReportHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='earnings'
            element={
              <>
                <Earnings />
              </>
            }
          />
          <Route
            path='user'
            element={
              <>
                <Users />
              </>
            }
          />
          <Route
            path='event'
            element={
              <>
                <Events />
              </>
            }
          />
          <Route index element={<Navigate to='/report/earnings' />} />
        </Route>
      </Routes>
    </>
  )
}

const ReportHeader: FC = () => {
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState<string>('')
  return (
    <>
     <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Report</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
          </h3>
      {/* <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Report</span> */}
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
          {/* </h3>
          <div className='card-toolbar'>
            {/* begin::Search */}
            {/* <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-362px ps-14'
                placeholder='Search by event name, location'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>  */}
            {/* end::Search */}
          {/* </div>
          <div className='card-toolbar'> */}
            {/* begin::Search */}
            {/* <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-180px ps-14'
                placeholder='Date'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
            </div> */}
            {/* end::Search */}
          {/* </div>
          <div className='card-toolbar'> */}
            {/* begin::Search */}
            {/* <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
              > */}
                {/*      */}
                {/* <option value='AF'>Gender</option>
                <option value='AF'>Male</option>
                <option value='AX'>Female</option>
              </select>
            </div> */}
            {/* end::Search */}
          {/* </div>
          <div className='card-toolbar'> */}
            {/* begin::Search */}
            {/* <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
              > */}
                {/*      */}
                {/* <option value='AF'>Category</option>
                <option value='AF'>House</option>
                <option value='AX'>Techno</option>
              </select>
            </div> */}
            {/* end::Search */}
          {/* </div>
          <a
            href='#'
            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end'
          >
            <KTIcon iconName='arrow-up' className='fs-2' />
          </a>
        </div>
      </div> */}
      <div className='d-flex overflow-auto h-55px my-6'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/report/earnings' && 'active')
              }
              to='/report/earnings'
            >
              Earnings
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/report/user' && 'active')
              }
              to='/report/user'
            >
              Users
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/report/event' && 'active')
              }
              to='/report/event'
            >
              Events
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}


export default ReportWrapper
