import { Placeholder } from "react-bootstrap"

const PlaceHolderComponent = ({ tableHeaders }) => {
    const placeholder = [...Array(10)].map((v, i) => {
        return (
            <tr key={i}>
                {[...Array(tableHeaders)].map((v, i) => {
                    return (
                        <td key={i}>
                            <Placeholder animation="glow">
                                <Placeholder bg="secondary" xs={10} />
                            </Placeholder>
                        </td>
                    )
                })}
            </tr>
        )
    })

    return ( 
        <>
            {placeholder}
        </>
    )
}

export default PlaceHolderComponent;
