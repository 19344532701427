import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { getAuthorizationToken } from '../../../authorization-token'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email Address is required'),
    // staffId: Yup.string().required('Staff ID is required'),
    genderId: Yup.string().required('Select Gender'),
    // mobileNumber: Yup.string().required('Mobile Number is required').matches(/^\d{8}$/, 'Mobile Number must be of 8 digits'),
    mobileNumber: Yup.string()
        .required('Mobile Number is required')
        .test('is-valid-number', 'Invalid Mobile Number', (value, { parent }) => {
            const countryCode = parent.country_code;
            if (countryCode === '+91') { // India
                return /^\d{10}$/.test(value);
            } else if (countryCode === '+974') { // Qatar
                return /^\d{8}$/.test(value);
            }
            // Add more country-specific checks here
            return true; // Fallback for other countries
        }),
    password: Yup.string().required('Password is required').matches(/^.{4,}$/, 'Password must be at least 4 characters'),
})

interface FormValues {
    name: string;
    email: string;
    staffId: string;
    genderId: string;
    country_code: string;
    mobileNumber: string;
    password: string;
}

const AddStaff: FC = () => {

    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [staffIdError, setStaffIdError] = useState(false);

    const storedFormValues = localStorage.getItem('staffValues');
    const formValues = storedFormValues
        ? JSON.parse(storedFormValues)
        : {
            name: '',
            email: '',
            staffId: '',
            genderId: '',
            country_code: '',
            mobileNumber: '',
            password: '',
        };
    // console.log('initialValue',formValues)   
    const initialValues: FormValues = {
        name: formValues.name,
        email: formValues.email,
        staffId: formValues.staffId,
        genderId: formValues.genderId,
        country_code: formValues.country_code,
        mobileNumber: formValues.mobileNumber,
        password: formValues.password,
    }

    const generateUniqueStaffId = () => {
        const timestamp = Date.now().toString(36);
        const randomNumber = Math.floor(Math.random() * 100000);
        const paddedRandomNumber = randomNumber.toString().padStart(5, '0');

        return `${paddedRandomNumber}`;
    };


    const handleChange = (value: string, dialCode: any) => {
        const countryCode = '+' + dialCode.dialCode;
        const charsToRemove = countryCode.length - 1;
        const newValue = value.slice(charsToRemove);
        // console.log('Value mobile number:', newValue);
        // console.log('Country code:', countryCode);
        formik.setFieldValue('mobileNumber', newValue)
        formik.setFieldValue('country_code', countryCode)
    };


    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            setLoading(true);
            const genderId = values.genderId === 'male' ? 1 : 2;
            const generatedStaffId = generateUniqueStaffId();
            const formData = {
                name: values.name,
                email: values.email,
                staffId: generatedStaffId,
                genderId: genderId,
                country_code: values.country_code,
                mobile: values.mobileNumber,
                password: values.password
            };
            // console.log('FormData', formData);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/addStaff`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${REACT_APP_AUTHORIZATION_TOKEN}`
                },
                body: JSON.stringify(formData),
            }).then((result) => {
                if (result.status === 200) {
                    // Navigate to the /staff page
                    setLoading(false);
                    delete localStorage['staffValues']
                    navigate('/staff');
                } else if (result.status === 422) {
                    result.json().then((data) => {
                        // console.log(data.error); 
                        setLoading(false);
                        setEmailError(false)
                        setMobileError(false)
                        setStaffIdError(false)
                        if (data.error === 'email already exists') return setEmailError(true);

                        if (data.error === 'mobile already exists') return setMobileError(true);

                        if (data.error === 'staffId already exists') return setStaffIdError(true);

                    }).catch((error) => {
                        setLoading(false);
                        console.error('Error parsing response body:', error);
                    });
                } else {
                    setLoading(false);
                    console.log("Error", result);
                }
            })

        },
    })

    // Save form values to local storage on change
    useEffect(() => {
        localStorage.setItem('staffValues', JSON.stringify(formik.values));
    }, [formik.values]);


    return (
        <div>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0 d-flex align-items-center'>
                    <Link
                        to='/staff'
                        className='btn btn-sm btn-icon btn-active-color-primary text-end'
                    >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                    </Link>
                    <h3 className='fw-bolder m-0'>Add Staff</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Staff Name</label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='First name'
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email Address</label>

                            <div className='col-lg-8 fv-row'>
                                {emailError && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Email is already registered.</div>
                                    </div>
                                )}
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='email address'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Gender</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('genderId')}
                                >
                                    <option value=''>Select Gender...</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>

                                </select>
                                {formik.touched.genderId && formik.errors.genderId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.genderId}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Mobile Number</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                {mobileError && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Mobile Number is already registered.</div>
                                    </div>
                                )}
                                <div className='form-control form-control-lg form-control-solid'>
                                    <PhoneInput
                                        country={'qa'}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        value={`${formik.values.country_code}${formik.values.mobileNumber}`}
                                        placeholder='Mobile Number'
                                        onChange={handleChange}
                                    // readOnly  // Add the readOnly attribute
                                    />
                                </div>
                                {/* <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Mobile Number'
                                    {...formik.getFieldProps('mobileNumber')}
                                /> */}
                                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.mobileNumber}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Password</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='password'
                                    {...formik.getFieldProps('password')}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.password}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <Link
                            to='/staff'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                            onClick={() => delete localStorage['staffValues']}
                        >
                            Cancel
                        </Link>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Add'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default AddStaff
