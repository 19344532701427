/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import EarningsChart from './EarningsChart'
// import BookingsChart from './BookingsChart'
import { useThemeMode } from '../../../_metronic/partials'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import ApexCharts, { ApexOptions } from 'apexcharts'
import BookingsChart from './BookingsChart'
import { getAuthorizationToken } from '../../../authorization-token'
import { Link } from 'react-router-dom'
import { set } from 'lodash'

interface Graph {
  month: string
  value: number
}

const DashboardWrapper: FC = () => {
  const [activeYears, setActiveYears] = useState<number[]>([])
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [eventBookings, setEventBookings] = useState<Graph[]>([])
  const [earnings, setEarnings] = useState<Graph[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get Active Years
  const fetchActiveYears = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getActiveYears`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort years in descending order
        const sortedYears = data.sort((a: number, b: number) => b - a);
        // console.log("Active Years :", sortedYears);
        setActiveYears(sortedYears);
        fetchDashboardData(sortedYears[0]);
        fetchEventBookings(sortedYears[0]);
        fetchEarnings(sortedYears[0]);
      })
      .catch((error) => {
        console.error('Error fetching Active Years :', error);
      });
  }

  // Get DashboardData 
  const fetchDashboardData = (year: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getDashboardData/${year}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("DashboardData :", data);
        setDashboardData(data);
      })
      .catch((error) => {
        console.error('Error fetching DashboardData :', error);
      });
  }

  // Get Earnings Data for Graph
  const fetchEarnings = (year: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getEarningsGraph/${year}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Earnings :", data);
        setEarnings(data);
      })
      .catch((error) => {
        console.error('Error fetching Earnings :', error);
      });
  }

  // Get Event Bookings Data for Graph
  const fetchEventBookings = (year: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getBookingGraph/${year}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Event Bookings :", data);
        setEventBookings(data);
      })
      .catch((error) => {
        console.error('Error fetching Event Bookings :', error);
      });
  }

  // Fetch DashboardData and Event Bookings when the page loads
  useEffect(() => {
    fetchActiveYears();
  }, []);

  const [bookingsData, setBookingsData] = useState<{ month: string; value: number }[]>([]);
  const [earningsData, setEarningsData] = useState<{ month: string; value: number }[]>([]);

  // Set Events Booking chart data
  useEffect(() => {
    setBookingsData(eventBookings.map(item => ({
      month: item.month,
      value: item.value
    })));
  }, [eventBookings]);

  // Set Earnings chart data
  useEffect(() => {
    setEarningsData(earnings.map(item => ({
      month: item.month,
      value: item.value
    })));
  }, [earnings]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBookingsData([]);
    setEarningsData([]);
    const selectedYear = Number(event.target.value);
    fetchDashboardData(selectedYear);
    fetchEventBookings(selectedYear);
    fetchEarnings(selectedYear);
  }

  return (
    <>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end align-items-center position-relative my-1'>
          <select className='form-select btn-sm w-100px' onChange={handleYearChange}>
            {activeYears.map((year) => (
              <option key={year} value={year} selected={new Date().getFullYear() === year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='chart-simple'
              color='white'
              iconColor='primary'
              title={dashboardData.noOf_events.toString()}
              description='Total Number of Events'
            />
          )}
        </div>

        <div className='col-xl-3'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='cheque'
              color='dark'
              iconColor='white'
              title={dashboardData.upcoming_events}
              titleColor='white'
              description='Upcoming Events'
              descriptionColor='white'
            />
          )}
        </div>

        <div className='col-xl-3'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='briefcase'
              color='warning'
              iconColor='white'
              title={dashboardData.booking_request}
              titleColor='white'
              description='Booking Request'
              descriptionColor='white'
            />
          )}
        </div>

        <div className='col-xl-3'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='chart-pie-simple'
              color='info'
              iconColor='white'
              title={`QR ${dashboardData.earnings}`}
              titleColor='white'
              description='Earnings'
              descriptionColor='white'
            />
          )}
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='cheque'
              color='primary'
              iconColor='white'
              title={dashboardData.noOf_user}
              titleColor='white'
              description='Total Number of Users'
              descriptionColor='white'
            />
          )}
        </div>

        <div className='col-xl-6'>
          {dashboardData && (
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='chart-simple-3'
              color='success'
              iconColor='white'
              title={dashboardData.noOf_staff}
              titleColor='white'
              description='Total Number of Staffs'
              descriptionColor='white'
            />
          )}
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-6'>
          {earningsData.length > 0 && (
            <EarningsChart
              className='card-xl-stretch mb-xl-8'
              chartColor='info'
              chartHeight='200px'
              data={earningsData}
            />
          )}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-6'>
          {bookingsData.length > 0 && (
            <BookingsChart
              className='card-xl-stretch mb-xl-8'
              chartColor='warning'
              chartHeight='200px'
              data={bookingsData}
            />
          )}
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        {/* <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} /> */}
        <div className={`fs-3 ms-n1 text-${descriptionColor}`}>{description}</div>

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>

        {/* <div className={`fs-3x ms-n1 text-${descriptionColor}`}>{description}</div> */}
      </div>
    </a>
  )
}


export { DashboardWrapper }
