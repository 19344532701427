import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getAuthorizationToken } from "../../../../authorization-token";
import { formatDateWithTime } from "../../../../datetime.formate";
import PaginationComponent from "../../../../pagination";
import PlaceHolderComponent from "../../../../skeletol-view";

interface UserIdState {
  userId: number; // Adjust the type of userId if needed
}

const ApprovalNeededEvent = () => {
  interface ApprovalNeededEventList {
    event_id: number
    event_name: string
    location: string
    event_category: string
    event_datetime: string
    approved: string
    ticket_qty: number
    redeemed: number
  }
  const location = useLocation();
  const { userId } = location.state as UserIdState;

  const [freeEventList, setFreeEventList] = useState<ApprovalNeededEventList[]>([]);
  const [loading, setLoading] = useState(true)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchFreeEventList = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getFreeEventList/${userId}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log('Result', resp);
          setLoading(false)
          setFreeEventList(resp);
        });
      })
      .catch((error) => {
        console.error('Error fetching paid event data:', error);
      });
  };

  useEffect(() => {
    fetchFreeEventList();
  }, []);

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10 // Number of items to display per page


  const indexOfLastData = currentPage * usersPerPage;
  const indexOfFirstData = indexOfLastData - usersPerPage;
  const currentData = freeEventList.slice(indexOfFirstData, indexOfLastData);

  // Total number of pages
  const totalPages = Math.ceil(freeEventList.length / usersPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Event Name</th>
                <th className='min-w-100px'>Address</th>
                <th className='min-w-100px'>Category</th>
                <th className='min-w-100px'>Event Date</th>
                <th className='min-w-100px'>Approved</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-125px text-center rounded-end'>Redeemed</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <PlaceHolderComponent
                  tableHeaders={7}
                />
              )}
              {currentData.map((event) => (
                <tr key={event.event_id}>
                  <td>{event.event_name}</td>
                  <td>{event.location}</td>
                  <td>{event.event_category}</td>
                  <td>{formatDateWithTime(event.event_datetime)}</td>
                  <td>{event.approved}</td>
                  <td>{event.ticket_qty}</td>
                  <td className='text-center'>{event.redeemed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          // pageNumbers={pageNumbers}
          currentData={currentData}
          filteredData={freeEventList}
          indexOfFirstData={indexOfFirstData}
          indexOfLastData={indexOfLastData}
        />
      )}
    </>
  )
}

export { ApprovalNeededEvent }
