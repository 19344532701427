import React, { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { formatDateWithTime } from '../../../../datetime.formate'
import { getAuthorizationToken } from '../../../../authorization-token';
import * as XLSX from 'xlsx';
import PaginationComponent from '../../../../pagination';
import PlaceHolderComponent from '../../../../skeletol-view';

interface EventDetailsState {
    eventId: number; // Adjust the type of eventId if needed
}

interface EventDetails {
    id: number;
    event_name: string;
    event_datetime: string[];
    gender: string;
    event_category: string;
    location: string;
}

interface EventUser {
    id: number
    user_name: string
    mobile_number: string
    gender: string
    age: string
    ticket_category: string
    redeemed_time: string
    redeemed_staff_name: string
}

const EventReports: FC = () => {
    const location = useLocation();
    const { eventId } = location.state as EventDetailsState;
    console.log(eventId)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    // Get Event Details
    const fetchEventDetails = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getEventDetailedReport/${eventId}`, {
            headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Event Details:", data);
                setEventDetails(data);
            })
            .catch((error) => {
                console.error('Error fetching event data:', error);
            });
    }

    const [eventUsers, setEventUsers] = useState<EventUser[]>([])
    const [loading, setLoading] = useState(true)

    const fetchEventUsers = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getEventUsers/${eventId}`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setLoading(false)
                    setEventUsers(resp);
                })
            })
            .catch((error) => {
                console.error('Error fetching event data:', error);
            });
    }

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;

    // For filteration by search
    const filteredData = eventUsers.filter((user) =>
        user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Reset current page to 1 when data is filtered
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    const indexOfLastData = currentPage * usersPerPage;
    const indexOfFirstData = indexOfLastData - usersPerPage;
    const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

    const exportToExcel = () => {
        // Define your data as an array of objects
        const data = currentData.map((user) => ({
            'Event Date': user.user_name,
            'Event Name': user.mobile_number,
            Gender: user.gender === 'M' ? 'Male' : user.gender === 'F' ? 'Female' : 'Any',
            'Age': user.age,
            'Ticket Type': user.ticket_category,
            'Redeem Time': user.redeemed_time ? formatDateWithTime(user.redeemed_time) : 'Not Redeemed',
            'Scanned By': user.redeemed_staff_name ? user.redeemed_staff_name : 'Not Redeemed',
        }));

        // Create a new workbook and add a worksheet
        const ws = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Event Detail Users Report');

        // Define the file name and save the Excel file
        const fileName = 'eventDetail_users_report.xlsx';
        XLSX.writeFile(wb, fileName);
    };

    // Total number of pages
    const totalPages = Math.ceil(eventUsers.length / usersPerPage);

    // Array for page numbers
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    // Fetch Event Details when the page loads
    useEffect(() => {
        fetchEventDetails();
        fetchEventUsers();
    }, [eventId]);

    return (
        <>
            <div>
                <div className='card-title m-0 d-flex align-items-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className='card-title align-items-start flex-column'>
                        <Link
                            to='/report/event'
                            className='btn btn-sm btn-icon btn-active-color-primary text-end '
                        >
                            <KTIcon iconName='arrow-left' className='fs-2' />
                        </Link>
                        <span className='card-label fw-bold fs-2 mb-1'>Event Details</span>
                        {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                    </h3>
                    <div className='card-toolbar'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-362px ps-14'
                                placeholder='Search by user Name '
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    {/* <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <a
                                href='#'
                                className='btn btn-light btn-active-light-primary btn-sm'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                Gender
                                <KTIcon iconName='down' className='fs-5 m-0' />
                            </a>
                            <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                data-kt-menu='true'
                            >
                                <div className='menu-item px-3'>
                                    <a className='menu-link px-3' href='#'>
                                        Male
                                    </a>
                                </div>
                                <div className='menu-item px-3'>
                                    <a
                                        className='menu-link px-3'
                                        data-kt-users-table-filter='delete_row'
                                        href='#'
                                    >
                                        Female
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <a
                                href='#'
                                className='btn btn-light btn-active-light-primary btn-sm'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                Redeemed By
                                <KTIcon iconName='down' className='fs-5 m-0' />
                            </a>
                            <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                data-kt-menu='true'
                            >
                                <div className='menu-item px-3'>
                                    <a className='menu-link px-3' href='#'>
                                        Male
                                    </a>
                                </div>
                                <div className='menu-item px-3'>
                                    <a
                                        className='menu-link px-3'
                                        data-kt-users-table-filter='delete_row'
                                        href='#'
                                    >
                                        Female
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <a
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
                        onClick={exportToExcel}
                    >
                        <KTIcon iconName='arrow-up' className='fs-2' />
                    </a>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-2 fw-bold text-muted'>Event Date</label>

                        <div className='col-lg-4'>
                            <span className='fw-bolder fs-6 text-dark'>
                                {eventDetails?.event_datetime.map((datetime) => (
                                    <div key={datetime}>
                                        {formatDateWithTime(datetime)}
                                    </div>
                                ))}
                            </span>
                        </div>
                        <label className='col-lg-2 fw-bold text-muted'>Location</label>

                        <div className='col-lg-4'>
                            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                                {eventDetails?.location}
                            </a>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-2 fw-bold text-muted'>Event Name</label>

                        <div className='col-lg-4 fv-row'>
                            <span className='fw-bold fs-6'>{eventDetails?.event_name}</span>
                        </div>
                        <label className='col-lg-2 fw-bold text-muted'>
                            Gender
                        </label>

                        <div className='col-lg-4'>
                            <span className='fw-bolder fs-6 text-dark'>
                                {eventDetails?.gender === 'M' ? 'Male' : eventDetails?.gender === 'F' ? 'Female' : 'Any'}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-2 fw-bold text-muted'>
                            Category
                        </label>

                        <div className='col-lg-4 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{eventDetails?.event_category}</span>
                        </div>
                    </div>

                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted bg-light'>
                                        <th className='min-w-100px'>User Name</th>
                                        <th className='min-w-100px'>Mobile Number</th>
                                        <th className='min-w-100px'>Gender</th>
                                        <th className='min-w-100px'>Age</th>
                                        <th className='min-w-100px'>Ticket Type</th>
                                        <th className='min-w-100px'>Redeem Time</th>
                                        <th className='min-w-125px rounded-end'>Scanned By</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {loading && (
                                        <PlaceHolderComponent
                                            tableHeaders={7}
                                        />
                                    )}
                                    {currentData.map((user) => (
                                        <tr key={user.id}>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.user_name}
                                                </a>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.mobile_number}
                                                </a>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.gender === 'M'
                                                        ? 'Male'
                                                        : user.gender === 'F'
                                                            ? 'Female'
                                                            : 'Any'}
                                                </a>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.age}
                                                </a>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.ticket_category === 'Free' ? "Guest List" : user.ticket_category}
                                                </a>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {user.redeemed_time ? formatDateWithTime(user.redeemed_time) : 'Not Redeemed'}
                                                </a>
                                            </td>
                                            <td className='text-center'>
                                                <td>
                                                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {user.redeemed_staff_name ? user.redeemed_staff_name : 'Not Redeemed'}
                                                    </a>
                                                </td>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                        {/* Pagination */}
                        {totalPages > 1 && (
                            <PaginationComponent
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                // pageNumbers={pageNumbers}
                                currentData={currentData}
                                filteredData={filteredData}
                                indexOfFirstData={indexOfFirstData}
                                indexOfLastData={indexOfLastData}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventReports
