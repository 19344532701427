import {useEffect, useMemo, useState} from 'react'
import {
  KTCard,
  KTCardBody,
  KTIcon,
  initialQueryState,
  useDebounce,
} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {formatDate, formatDateWithTime} from '../../../datetime.formate'
import {getAuthorizationToken} from '../../../authorization-token'
import * as XLSX from 'xlsx'
import PaginationComponent from '../../../pagination'
import PlaceHolderComponent from '../../../skeletol-view'

interface AllCategory {
  id: number
  event_category: string
  status: string
}
export interface IsArtist {
  request_date: string
  status: 'Pending' | 'Approved' | 'Rejected'
  art: string[]
  level: 'Pro' | 'Amateur'
  portfolio_link: string
  portfolio_pdf: string
}
export interface IsCollaborator {
  request_date: string
  status: 'Pending' | 'Approved' | 'Rejected'
  category: string[]
  type: 'Individual' | 'Company'
  portfolio_link: string
  portfolio_pdf: string
}

const UsersWrapper = () => {
  interface User {
    id: number
    full_name: string
    email: string
    mobile_number: string
    gender_id: number
    gender: string
    dob: string
    age: number
    event_categories: string
    date_created: string
    status: string
    is_vip: number
    is_artist: IsArtist
    is_collaborator: IsCollaborator
  }
  const [userData, setUserData] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  const [allCategory, setAllCategory] = useState<AllCategory[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get ALL User List
  const fetchUserData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allUsers`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          setLoading(false)
          setUserData(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }

  // For Get ALL User List When page is load
  useEffect(() => {
    fetchUserData()
  }, [])

  const fetchAllCategory = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allCategory`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setAllCategory(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching all category data:', error)
      })
  }

  // For Get ALL Event List When page is load
  useEffect(() => {
    fetchAllCategory()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10

  const filteredData = userData
    .filter(
      (user) =>
        ((user.full_name &&
          user.full_name.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')) ||
          (user.email && user.email.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')) ||
          (user.mobile_number &&
            user.mobile_number.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''))) &&
        (!selectedGenderFilter ||
          selectedGenderFilter === 'Any' || // Show any gender when "Any" is selected
          user.gender === selectedGenderFilter) &&
        (!selectedCategory ||
          selectedCategory === 'A' ||
          (user.event_categories && user.event_categories.split(',').includes(selectedCategory))) &&
        (!searchDate ||
          (user.date_created &&
            new Date(user.date_created)?.toISOString()?.substr(0, 10) ===
              new Date(searchDate)?.toISOString()?.substr(0, 10))) &&
        (!selectedStatus ||
          (user.status === 'Active' && selectedStatus === 'Active') ||
          (user.status === 'Inactive' && selectedStatus === 'Inactive'))
    )
    .sort((a, b) => {
      // Compare event dates for sorting
      const dateA: Date = new Date(a.date_created)
      const dateB: Date = new Date(b.date_created)

      if (selectedSortBY === 'dateNewest') {
        // Sort by newest first
        return dateB.getTime() - dateA.getTime()
      }
      if (selectedSortBY === 'dateOldest') {
        return dateA.getTime() - dateB.getTime()
        // Sort by oldest first
      }

      // Default sorting (no sorting)
      return 0
    })

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm, selectedGenderFilter, selectedCategory, searchDate, selectedStatus])

  // Get the users to display on the current page
  const indexOfLastData = currentPage * usersPerPage
  const indexOfFirstData = indexOfLastData - usersPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)

  const exportToExcel = () => {
    // Define your data as an array of objects
    const data = currentData.map((user) => ({
      'Registered Date': formatDateWithTime(user.date_created),
      'User Name': user.full_name,
      'Mobile Number': user.mobile_number,
      Gender: user.gender === 'M' ? 'Male' : 'Female',
      Age: user.age,
      Interest: user.event_categories,
      status: user.status,
    }))

    // Create a new workbook and add a worksheet
    const ws = XLSX.utils.json_to_sheet(data)

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Users List')

    // Define the file name and save the Excel file
    const fileName = 'users_list.xlsx'
    XLSX.writeFile(wb, fileName)
  }

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / usersPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({length: totalPages}, (_, index) => index + 1)

  // Update User Status
  const updateUserstatus = (userId: number, newStatus: string) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateUserstatus`, {
      method: 'PUT',
      headers: {
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id: userId, status: newStatus}),
    })
      .then((result) => {
        if (result.status === 200) {
          fetchUserData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }

  // For Delete a user
  const deleteUser = (userId: number) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteUser/${userId}`, {
      method: 'POST',
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        if (result.status === 200) {
          fetchUserData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }

  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div
          className='card-title m-0 d-flex align-items-center'
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1'>Users</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
          </h3>
          <div className='card-toolbar '>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-300px ps-14'
                placeholder='Search by user name, mobile '
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-180px ps-14'
                placeholder='Date'
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
              />
              <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedGenderFilter || ''}
                onChange={(e) => {
                  setSelectedGenderFilter(e.target.value)
                }}
              >
                {/*      */}
                <option value='Any'>Gender</option>
                <option value='M'>Male</option>
                <option value='F'>Female</option>
                <option value='A'>Any</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedCategory || ''}
                onChange={(e) => {
                  setSelectedCategory(e.target.value)
                }}
              >
                <option value=''>Interest</option>
                {allCategory.map((user) => (
                  <option key={user.id} value={user.event_category}>
                    {user.event_category}
                  </option>
                ))}
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedStatus || ''}
                onChange={(e) => {
                  setSelectedStatus(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Status</option>
                <option value='Active'>Active</option>
                <option value='Inactive'>Inactive</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedSortBY || ''}
                onChange={(e) => {
                  setSelectedSortBY(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Sort By</option>
                <option value='dateNewest'>Registered Date Newest First</option>
                <option value='dateOldest'>Registered Date Oldest First</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center position-relative my-1'>
              <a
                // href='#'
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-end ml-3'
                onClick={exportToExcel}
              >
                <KTIcon iconName='arrow-up' className='fs-2' />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Registered Date</th>
                <th className='min-w-100px'>User Name</th>
                <th className='min-w-20px'></th>
                <th className='min-w-100px'>Mobile Number</th>
                <th className='min-w-100px'>Gender</th>
                <th className='min-w-100px'>Age</th>
                <th className='min-w-100px'>Interest</th>
                <th className='min-w-100px'>Profile</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-125px text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && <PlaceHolderComponent tableHeaders={10} />}
              {currentData.map((user) => (
                <tr key={user.id}>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatDate(user.date_created)}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.full_name}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.is_vip === 1 ? 'VIP' : ''}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.mobile_number}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.gender === 'M' ? 'Male' : user.gender === 'F' ? 'Female' : '-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.age ? user.age : '-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.event_categories ? (
                        <a
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          title={user.event_categories.length > 20 ? user.event_categories : ''}
                        >
                          {user.event_categories.length > 20
                            ? user.event_categories.slice(0, 20) + '...'
                            : user.event_categories}
                        </a>
                      ) : (
                        '-'
                      )}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {(user.is_artist?.status === 'Approved' &&
                        user.is_collaborator?.status === 'Approved' &&
                        'Artist & Collaborator') ||
                        (user.is_artist?.status === 'Approved' && 'Artist') ||
                        (user.is_collaborator?.status === 'Approved' && 'Collaborator') ||
                        '-'}
                    </a>
                  </td>
                  <td>
                    <div
                      className='dropdown'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <a
                        className='btn btn-light btn-active-light-primary btn-sm'
                        href='#'
                        role='button'
                        id={`dropdownMenu${user.id}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {user.status}
                        <KTIcon iconName='down' className='fs-5 m-0' />
                      </a>
                      <ul className='dropdown-menu' aria-labelledby={`dropdownMenu${user.id}`}>
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => {
                              updateUserstatus(
                                user.id,
                                user.status === 'Active' ? 'Inactive' : 'Active'
                              )
                            }}
                          >
                            {user.status === 'Active' ? 'Inactive' : 'Active'}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td className='text-center'>
                    <Link
                      to='/users/user-details/paid-event'
                      state={{userId: user.id}}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                    >
                      View
                    </Link>
                    <a
                      onClick={() => deleteUser(user.id)}
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default UsersWrapper
