import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { getAuthorizationToken } from "../../../../authorization-token";
import { formatDate } from "../../../../datetime.formate";
import PaginationComponent from "../../../../pagination";

const Promo: React.FC = () => {

    interface Code {
        id: number
        name: string
        category: string
        used_by: string
        date_created: string
        expiry_date: string
    }

    const [promoCodeData, setPromoCodeData] = useState<Code[]>([])

    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

    const category = "promo"

    // Get ALL PromoCodeData List
    const fetchPromoCodeData = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllCode/${category}`,
            {
                headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` }
            }).then((result) => {
                result.json().then((resp) => {
                    // console.log("Result", resp)
                    setPromoCodeData(resp);
                })
            })
            .catch((error) => {
                console.error('Error fetching PromoCodeData data:', error);
            });
    }

    const deleteCode = (promoCodeId: number) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteCode/${promoCodeId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${REACT_APP_AUTHORIZATION_TOKEN}`
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    fetchPromoCodeData();
                } else {
                    console.log("Error", result);
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    };

    // For Get ALL PromoCodeData List When page is load
    useEffect(() => {
        fetchPromoCodeData();
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 10;


    const indexOfLastData = currentPage * perPage;
    const indexOfFirstData = indexOfLastData - perPage;
    const currentData = promoCodeData.slice(indexOfFirstData, indexOfLastData);

    // Total number of pages
    const totalPages = Math.ceil(promoCodeData.length / perPage);

    // Array for page numbers
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
        <>
            {/* begin::Body */}
            <div className='card-body py-3 my-6'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted bg-light'>
                                <th className='min-w-100px'>Created Date</th>
                                <th className='min-w-100px'>Code</th>
                                <th className='min-w-100px'>Used By</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-125px text-center rounded-end'>Action</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {currentData.map((promoCode) => (
                                <tr key={promoCode.id}>
                                    <td>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {formatDate(promoCode.date_created)}
                                        </a>
                                    </td>
                                    <td>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {promoCode.name}
                                        </a>
                                    </td>
                                    <td>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {promoCode.used_by}
                                        </a>
                                    </td>
                                    <td>
                                        {new Date(promoCode.expiry_date) <= new Date() ? (
                                            <a

                                                className='text-dark text-center fw-bold text-hover-primary d-block mb-1 fs-6'
                                            >
                                                Inactive
                                            </a>
                                        ) : (
                                            <a

                                                className='text-dark text-center fw-bold text-hover-primary d-block mb-1 fs-6'
                                            >
                                                Active
                                            </a>
                                        )
                                        }
                                    </td>
                                    <td className='text-center'>
                                        <Link
                                            to={`/settings/discount-code/codeDetails?id=${encodeURIComponent(promoCode.id)}`}
                                            className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                                        >
                                            View
                                        </Link>
                                        <Link
                                            to={`/settings/discount-code/editcode?id=${encodeURIComponent(promoCode.id)}`}
                                            className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                                        >
                                            Edit
                                        </Link>
                                        <a
                                            onClick={() => deleteCode(promoCode.id)}
                                            className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4'
                                        >
                                            Delete
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
                {totalPages > 1 && (
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        // pageNumbers={pageNumbers}
                        currentData={currentData}
                        filteredData={promoCodeData}
                        indexOfFirstData={indexOfFirstData}
                        indexOfLastData={indexOfLastData}
                    />
                )}
            </div>
            {/* begin::Body */}
        </>
    )
}

export default Promo