import { Link, useLocation } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import { getAuthorizationToken } from '../../../../authorization-token'
import { formatDate } from '../../../../datetime.formate'
import PaginationComponent from '../../../../pagination'


const CodeDetails: React.FC = () => {
  interface CodeDetails {
    date_created: string
    name: string
    used_by: number
    expiry_date: string
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get('id')?.trim() || ''

  const [codeDetails, setCodeDetails] = useState<CodeDetails | null>(null)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  const fetchUserDetails = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getCodeDetails/${id}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setCodeDetails(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  interface UsedByUsers {
    booking_id: number
    used_on: string
    user_name: string
    event_name: string
  }

  const [usedByUsers, setUsedByUsers] = useState<UsedByUsers[]>([])

  const fetchUsedByUsers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getUsedByUsers/${id}`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          // console.log("Result", resp)
          setUsedByUsers(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching UsedByUsers data:', error)
      })
  }

  useEffect(() => {
    fetchUserDetails()
    fetchUsedByUsers()
  }, [id])

  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 10

  const indexOfLastData = currentPage * perPage
  const indexOfFirstData = indexOfLastData - perPage
  const currentData = usedByUsers.slice(indexOfFirstData, indexOfLastData)

  // Total number of pages
  const totalPages = Math.ceil(usedByUsers.length / perPage)

  // Array for page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1)

  return (
    <>
      <h3 className='card-title align-items-start flex-column'>
        <Link
          to='/settings/discount-code'
          className='btn btn-sm btn-icon btn-active-color-primary text-end '
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
        </Link>
        <span className='card-label fw-bold fs-2 mb-1'>Code Information</span>
        {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
      </h3>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'></div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'></div>
              <>
                {codeDetails && (
                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-2 fw-bold text-muted '>Created Date</label>

                      <div className='col-lg-4 '>
                        <span className='fw-bolder fs-6 text-dark'>
                          {formatDate(codeDetails.date_created)}
                        </span>
                      </div>

                      <label className='col-lg-2 fw-bold text-muted '>Status</label>

                      <div className='col-lg-3 fv-row '>
                        <span className='fw-bold fs-6'>
                          {new Date(codeDetails.expiry_date) <= new Date() ? (
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              Inactive
                            </a>
                          ) : (
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              Active
                            </a>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-2 fw-bold text-muted '>Code</label>

                      <div className='col-lg-4 '>
                        <span className='fw-bolder fs-6 text-dark'>{codeDetails.name}</span>
                      </div>

                      <label className='col-lg-2 fw-bold text-muted '>Expire On</label>

                      <div className='col-lg-3 fv-row '>
                        <span className='fw-bold fs-6'>{formatDate(codeDetails.expiry_date)}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-2 fw-bold text-muted '>Used By</label>

                      <div className='col-lg-4 '>
                        <span className='fw-bolder fs-6 text-dark'>{codeDetails.used_by}</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted '>
                    <th className='min-w-100px'>Used On</th>
                    <th className='min-w-100px'>User Name</th>
                    <th className='min-w-100px'>Event Name</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {currentData.map((usedBy) => (
                    <tr key={usedBy.booking_id}>
                      <td>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatDate(usedBy.used_on)}
                        </a>
                      </td>
                      <td>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {usedBy.user_name}
                        </a>
                      </td>
                      <td>
                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {usedBy.event_name}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
            {totalPages > 1 && (
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                // pageNumbers={pageNumbers}
                currentData={currentData}
                filteredData={usedByUsers}
                indexOfFirstData={indexOfFirstData}
                indexOfLastData={indexOfLastData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CodeDetails
